import Breakpoint from '../vendors/frontools/src/core/Breakpoint';
import { autobind } from '../vendors/frontools/src/decorators/Autobind';
import { Modules } from '../Modules/index';
import Module from '../Abstract/Module';
import AbstractDispatcher from 'vendors/frontools/src/abstract/AbstractDispatcher';

export class ModuleFactory extends AbstractDispatcher {

    private _moduleList: Array<any> = [];

    constructor() {
        super();
        this._createModules();

        Breakpoint.on('group_mobile', () => this._onBreakpoint('onGroupMobile'));
        Breakpoint.on('group_desktop', () => this._onBreakpoint('onGroupDesktop'));
        Breakpoint.on('mobile', () => this._onBreakpoint('onMobile'));
        Breakpoint.on('tablet', () => this._onBreakpoint('onTablet'));


        Breakpoint.trigger();
    }

    @autobind
    private _onBreakpoint(breakpoint) {
        for (let i = 0, l = this._moduleList.length; i < l; i++) {
            if (typeof this._moduleList[i][breakpoint] === 'function') {
                this._moduleList[i][breakpoint]();
            }
        }
    }

    private _createModules(elementToSearch = document) {
console.log('createModules');

        let elements = elementToSearch.querySelectorAll("[data-module]") as NodeListOf<HTMLElement>;

        for (let i = 0, l = elements.length; i < l; i++) {
            const element = elements[i];
            const modules = element.getAttribute("data-module");
            let modulesIds = modules.split('|');

            for (let i = 0; i < modulesIds.length; i++) {
                const id = modulesIds[i];
                console.log(id);
                
                if (Modules[id]) {
                    const module = new Modules[id](element);
                    module.addListener(Module.DOM_CHANGE, this._onDomChange)
                    this._moduleList.push(module);
                }
            }
        }
    }

    @autobind
    private _onDomChange(e) {
        for (let i = 0, l = e.data.elements.length; i < l; i++) {
            this._createModules(e.data.elements[i])
        }
    }

}
