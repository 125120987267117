var __extends = (this && this.__extends) || (function () {
    var extendStatics = Object.setPrototypeOf ||
        ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
        function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
var __assign = (this && this.__assign) || Object.assign || function(t) {
    for (var s, i = 1, n = arguments.length; i < n; i++) {
        s = arguments[i];
        for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
            t[p] = s[p];
    }
    return t;
};
System.register("src/ts/vendors/frontools/src/abstract/AbstractDispatcher", [], function (exports_1, context_1) {
    "use strict";
    var AbstractDispatcher;
    var __moduleName = context_1 && context_1.id;
    return {
        setters: [],
        execute: function () {
            AbstractDispatcher = (function () {
                function AbstractDispatcher() {
                    this._listeners = {};
                }
                AbstractDispatcher.prototype.addListener = function (type, listener) {
                    if (this._listeners[type] === undefined)
                        this._listeners[type] = [];
                    if (this._listeners[type].indexOf(listener) === -1) {
                        this._listeners[type].push(listener);
                    }
                };
                ;
                AbstractDispatcher.prototype.hasListener = function (type, listener) {
                    if (this._listeners[type] !== undefined) {
                        if (this._listeners[type].indexOf(listener) !== -1) {
                            return true;
                        }
                    }
                    return false;
                };
                ;
                AbstractDispatcher.prototype.removeListener = function (type, listener) {
                    var listenersList = this._listeners[type];
                    if (listenersList !== undefined) {
                        var index = listenersList.indexOf(listener);
                        if (index !== -1)
                            listenersList.splice(index, 1);
                    }
                };
                ;
                AbstractDispatcher.prototype.removeAllListener = function () {
                    this._listeners = {};
                };
                ;
                AbstractDispatcher.prototype.dispatch = function (event) {
                    var listenersList = this._listeners[event.type];
                    if (listenersList !== undefined) {
                        event.target = this;
                        var array = [];
                        var length_1 = listenersList.length;
                        for (var i = 0; i < length_1; i++) {
                            array[i] = listenersList[i];
                        }
                        for (var j = 0; j < length_1; j++) {
                            array[j].call(this, event);
                        }
                    }
                };
                ;
                return AbstractDispatcher;
            }());
            exports_1("default", AbstractDispatcher);
        }
    };
});
System.register("src/ts/vendors/frontools/src/utils/UrlUtils", [], function (exports_2, context_2) {
    "use strict";
    var UrlUtils;
    var __moduleName = context_2 && context_2.id;
    return {
        setters: [],
        execute: function () {
            UrlUtils = (function () {
                function UrlUtils() {
                }
                UrlUtils.removeQuery = function (url, param) {
                    var pattern = new RegExp('([?&]' + param.replace(/([\\\/\[\]{}().*+?|^$])/g, '\\$1') + '=)[^&#]*');
                    return pattern.test(url) ? url.replace(pattern, '').replace(/^([^?]+)&/, '$1?') : url;
                };
                ;
                UrlUtils.updateQuery = function (uri, key, value) {
                    var re = new RegExp("([?&])" + key + "=.*?(&|$)", "i");
                    var separator = uri.indexOf('?') !== -1 ? "&" : "?";
                    if (uri.match(re)) {
                        return uri.replace(re, '$1' + key + "=" + value + '$2');
                    }
                    else {
                        return uri + separator + key + "=" + value;
                    }
                };
                ;
                UrlUtils.query = function (url, params) {
                    var no = 0;
                    if (url.indexOf('?') == -1)
                        url += '?';
                    else
                        no = 1;
                    for (var i in params) {
                        url = UrlUtils.addQuery(url, i, params[i]);
                    }
                    return url;
                };
                UrlUtils.addQuery = function (url, param, value) {
                    var a = document.createElement('a'), regex = /(?:\?|&amp;|&)+([^=]+)(?:=([^&]*))*/g;
                    var match, str = [];
                    a.href = url;
                    param = encodeURIComponent(param);
                    while (match = regex.exec(a.search))
                        if (param != match[1])
                            str.push(match[1] + (match[2] ? "=" + match[2] : ""));
                    str.push(param + (value ? "=" + encodeURIComponent(value) : ""));
                    a.search = str.join("&");
                    return a.href;
                };
                UrlUtils.parse = function (url) {
                    if (url === void 0) { url = document.location.toString(); }
                    var key = ['source', 'protocol', 'authority', 'userInfo', 'user', 'password', 'host', 'port', 'relative', 'path', 'directory', 'file', 'query', 'hash'];
                    var query = { name: 'params', parser: /(?:^|&)([^&=]*)=?([^&]*)/g };
                    var parser = /^(?:(?![^:@]+:[^:@\/]*@)([^:\/?#.]+):)?(?:\/\/)?((?:(([^:@]*)(?::([^:@]*))?)?@)?([^:\/?#]*)(?::(\d*))?)(((\/(?:[^?#](?![^?#\/]*\.[^?#\/.]+(?:[?#]|$)))*\/?)?([^?#\/]*))(?:\?([^#]*))?(?:#(.*))?)/;
                    var match = parser.exec(url);
                    var uri = {};
                    var index = key.length;
                    while (index--)
                        uri[key[index]] = match[index] || '';
                    uri[query.name] = {};
                    uri[key[12]].replace(query.parser, function (params, id, value) { if (id)
                        uri[query.name][id] = value; });
                    uri.url = url;
                    return uri;
                };
                UrlUtils.isExternalURL = function (url) {
                    if (url.substring(0, 4) === 'http' || url.substring(0, 2) === '//') {
                        if (UrlUtils.parse().host != UrlUtils.parse(url).host) {
                            return true;
                        }
                        else {
                            return false;
                        }
                    }
                    else {
                        return false;
                    }
                };
                ;
                UrlUtils.isIPaddress = function (url) {
                    var patt = new RegExp(/^(((1?[1-9]?|10|2[0-4])\d|25[0-5])($|\.(?!$))){4}$/g);
                    return patt.test(UrlUtils.parse(url).host);
                };
                ;
                return UrlUtils;
            }());
            exports_2("default", UrlUtils);
        }
    };
});
System.register("src/ts/vendors/frontools/src/events/EventType", [], function (exports_3, context_3) {
    "use strict";
    var EventType;
    var __moduleName = context_3 && context_3.id;
    return {
        setters: [],
        execute: function () {
            EventType = (function () {
                function EventType(_type, _data) {
                    this._type = _type;
                    this._data = _data;
                    this._target = null;
                }
                Object.defineProperty(EventType.prototype, "type", {
                    get: function () {
                        return this._type;
                    },
                    enumerable: true,
                    configurable: true
                });
                Object.defineProperty(EventType.prototype, "data", {
                    get: function () {
                        return this._data;
                    },
                    enumerable: true,
                    configurable: true
                });
                Object.defineProperty(EventType.prototype, "target", {
                    get: function () {
                        return this._target;
                    },
                    set: function (value) {
                        this._target = value;
                    },
                    enumerable: true,
                    configurable: true
                });
                EventType.CHANGE = 'change';
                EventType.CLEAR = 'clear';
                EventType.CLOSE = 'close';
                EventType.COMPLETE = 'complete';
                EventType.CLICK = 'click';
                EventType.DOM_READY = 'domready';
                EventType.ERROR = 'error';
                EventType.KEY_DOWN = 'click';
                EventType.INTERACTION_CHANGE = 'interaction_change';
                EventType.MOUSE_MOVE = 'mousemove';
                EventType.OPEN = 'open';
                EventType.READY = 'ready';
                EventType.RENDER = 'render';
                EventType.RESIZE = 'resize';
                EventType.START = 'start';
                EventType.STOP = 'stop';
                EventType.TOUCH_START = 'touchstart';
                EventType.TOUCH_END = 'touchend';
                EventType.UPDATE = 'update';
                EventType.WIN_LOAD = 'winload';
                return EventType;
            }());
            exports_3("default", EventType);
        }
    };
});
System.register("src/ts/vendors/frontools/src/features/TouchFeature", ["src/ts/vendors/frontools/src/abstract/AbstractDispatcher", "src/ts/vendors/frontools/src/events/EventType"], function (exports_4, context_4) {
    "use strict";
    var AbstractDispatcher_1, EventType_1, TouchFeature;
    var __moduleName = context_4 && context_4.id;
    return {
        setters: [
            function (AbstractDispatcher_1_1) {
                AbstractDispatcher_1 = AbstractDispatcher_1_1;
            },
            function (EventType_1_1) {
                EventType_1 = EventType_1_1;
            }
        ],
        execute: function () {
            TouchFeature = (function (_super) {
                __extends(TouchFeature, _super);
                function TouchFeature() {
                    var _this = _super.call(this) || this;
                    _this._touchpoints = [];
                    _this._isTouch = null;
                    if (TouchFeature._instance)
                        throw new Error('Error: Use TouchFeature.instance instead of new.');
                    TouchFeature._instance = _this;
                    _this.init();
                    return _this;
                }
                TouchFeature.prototype.init = function () {
                    this.handleTouchInteraction = this.handleTouchInteraction.bind(this);
                    this.handleMouseInteraction = this.handleMouseInteraction.bind(this);
                    this.registerTouch = this.registerTouch.bind(this);
                    window.addEventListener(EventType_1.default.TOUCH_START, this.registerTouch);
                    window.addEventListener(EventType_1.default.TOUCH_END, this.registerTouch);
                    document.addEventListener(EventType_1.default.MOUSE_MOVE, this.handleMouseInteraction);
                    document.addEventListener(EventType_1.default.TOUCH_START, this.handleTouchInteraction);
                };
                TouchFeature.prototype.registerTouch = function (e) {
                    var _this = this;
                    var touch = e.touches[0] || e.changedTouches[0];
                    this._touchpoints.push(touch);
                    setTimeout(function () {
                        _this._touchpoints.splice(_this._touchpoints.indexOf(touch), 1);
                    }, 1000);
                };
                TouchFeature.prototype.handleTouchInteraction = function (e) {
                    document.removeEventListener(EventType_1.default.TOUCH_START, this.handleTouchInteraction);
                    this._isTouch = true;
                    this.dispatch({
                        type: EventType_1.default.INTERACTION_CHANGE,
                        isTouch: this._isTouch
                    });
                    document.addEventListener(EventType_1.default.MOUSE_MOVE, this.handleMouseInteraction);
                };
                TouchFeature.prototype.handleMouseInteraction = function (e) {
                    if (this._touchpoints.length > 0) {
                        e.cancel = true;
                        e.returnValue = false;
                        e.cancelBubble = true;
                        e.preventDefault();
                        e.stopPropagation();
                    }
                    else {
                        document.removeEventListener(EventType_1.default.MOUSE_MOVE, this.handleMouseInteraction);
                        this._isTouch = false;
                        this.dispatch({
                            type: EventType_1.default.INTERACTION_CHANGE,
                            isTouch: false
                        });
                        document.addEventListener(EventType_1.default.TOUCH_START, this.handleTouchInteraction);
                    }
                };
                Object.defineProperty(TouchFeature, "instance", {
                    get: function () {
                        return TouchFeature._instance;
                    },
                    enumerable: true,
                    configurable: true
                });
                return TouchFeature;
            }(AbstractDispatcher_1.default));
            exports_4("default", TouchFeature);
        }
    };
});
System.register("src/ts/vendors/frontools/src/core/Context", ["src/ts/vendors/frontools/src/utils/UrlUtils", "src/ts/vendors/frontools/src/abstract/AbstractDispatcher", "src/ts/vendors/frontools/src/events/EventType", "src/ts/vendors/frontools/src/features/TouchFeature"], function (exports_5, context_5) {
    "use strict";
    var UrlUtils_1, AbstractDispatcher_2, EventType_2, TouchFeature_1, Context;
    var __moduleName = context_5 && context_5.id;
    return {
        setters: [
            function (UrlUtils_1_1) {
                UrlUtils_1 = UrlUtils_1_1;
            },
            function (AbstractDispatcher_2_1) {
                AbstractDispatcher_2 = AbstractDispatcher_2_1;
            },
            function (EventType_2_1) {
                EventType_2 = EventType_2_1;
            },
            function (TouchFeature_1_1) {
                TouchFeature_1 = TouchFeature_1_1;
            }
        ],
        execute: function () {
            Context = (function (_super) {
                __extends(Context, _super);
                function Context(values) {
                    var _this = _super.call(this) || this;
                    _this._environment = 'production';
                    _this._language = 'fr';
                    _this._region = 'ca';
                    _this._culture = 'fr-ca';
                    _this._isTouch = null;
                    if (Context._instance)
                        throw new Error('Error: Use Context.instance instead of new.');
                    Context._instance = _this;
                    for (var i in values) {
                        if (i === 'environment')
                            _this._environment = values[i];
                        else if (i === 'lang')
                            _this.parseLangue(values[i]);
                        else if (i === 'langue')
                            _this.parseLangue(values[i]);
                        else if (i === 'language')
                            _this.parseLangue(values[i]);
                        else
                            _this[i] = values[i];
                    }
                    if (document.documentElement.classList.contains('is-experience-editor')) {
                        _this._isEditorMode = true;
                    }
                    var touchFeature = new TouchFeature_1.default();
                    touchFeature.addListener(EventType_2.default.INTERACTION_CHANGE, function (e) {
                        _this._isTouch = e.isTouch;
                    });
                    return _this;
                }
                Context.prototype.parseLangue = function (lang) {
                    if (lang.length == 2) {
                        this._language = lang;
                    }
                    else {
                        var culture;
                        if (lang.indexOf('-') != -1) {
                            culture = lang.split('-');
                            this._language = culture[0];
                            this._region = culture[1];
                        }
                        else if (lang.indexOf('_') != -1) {
                            culture = lang.split('_');
                            this._language = culture[0];
                            this._region = culture[1];
                        }
                        else {
                            this._language = lang;
                            this._region = lang;
                        }
                        this._language = this._language.toLowerCase();
                        this._region = this._region.toLowerCase();
                        this._culture = this._language + '-' + this._region;
                    }
                };
                Context.prototype.isMobile = function () {
                    if (typeof window.orientation !== 'undefined' && this.isTouch())
                        return true;
                    else
                        return false;
                };
                Context.prototype.isTouch = function () {
                    return this._isTouch;
                };
                Context.prototype.isLocal = function () {
                    return this.environment === 'local' ? true : false;
                };
                ;
                Context.prototype.isStaging = function () {
                    return this.environment === 'staging' ? true : false;
                };
                ;
                Context.prototype.isProduction = function () {
                    return this.environment === 'production' ? true : false;
                };
                ;
                Context.prototype.isIE = function () {
                    return navigator.userAgent.indexOf('MSIE') !== -1 ||
                        navigator.appVersion.indexOf('Trident/') > 0 ||
                        navigator.appVersion.indexOf('Edge/') > 0;
                };
                ;
                Context.prototype.isIE11andLower = function () {
                    return (window.navigator.userAgent.indexOf('MSIE ') > 0) ||
                        (!!window.MSInputMethodContext &&
                            !!document.documentMode);
                };
                ;
                Context.prototype.isChrome = function () {
                    var ua = navigator.userAgent;
                    if (/Chrome/i.test(ua))
                        return true;
                    else
                        return false;
                };
                ;
                Context.prototype.isChromeIOS = function () {
                    var ua = navigator.userAgent;
                    if (/CriOS/i.test(ua))
                        return true;
                    else
                        return false;
                };
                ;
                Context.prototype.isFirefox = function () {
                    var ua = navigator.userAgent;
                    if (/Firefox/i.test(ua))
                        return true;
                    else
                        return false;
                };
                ;
                Context.prototype.isSafariMobile = function () {
                    var ua = navigator.userAgent;
                    if (/Mobile(\/.*)? Safari/i.test(ua))
                        return true;
                    else
                        return false;
                };
                ;
                Context.prototype.isSafari = function () {
                    var ua = navigator.userAgent;
                    if (!this.isSafariMobile()) {
                        if (/Safari/i.test(ua)) {
                            if (/Chrome/i.test(ua))
                                return false;
                            else
                                return true;
                        }
                        else
                            return false;
                    }
                    else
                        return false;
                };
                ;
                Context.prototype.isAndroid = function () {
                    var ua = navigator.userAgent;
                    if (/Android/i.test(ua))
                        return true;
                    else
                        return false;
                };
                ;
                Context.prototype.isIOS = function () {
                    var ua = navigator.userAgent;
                    if (/iP[ao]d|iPhone/i.test(ua))
                        return true;
                    else
                        return false;
                };
                ;
                Context.prototype.isIPad = function () {
                    var ua = navigator.userAgent;
                    if (/iP[ao]d/i.test(ua))
                        return true;
                    else
                        return false;
                };
                ;
                Context.prototype.isIPhone = function () {
                    var ua = navigator.userAgent;
                    if (/iPhone/i.test(ua))
                        return true;
                    else
                        return false;
                };
                ;
                Context.prototype.getURL = function (url) {
                    if (url === void 0) { url = undefined; }
                    var uri = UrlUtils_1.default.parse(url);
                    return {
                        uri: uri,
                        url: uri.protocol + '://' + uri.host + (uri.port ? ':' + uri.port : '') + uri.path,
                        host: uri.protocol + '://' + uri.host + (uri.port ? ':' + uri.port : ''),
                        query: uri.params
                    };
                };
                ;
                Context.prototype.isEditorMode = function () {
                    return this._isEditorMode;
                };
                ;
                Object.defineProperty(Context.prototype, "host", {
                    get: function () {
                        var uri = UrlUtils_1.default.parse();
                        return uri.protocol + '://' + uri.host + (uri.port ? ':' + uri.port : '');
                    },
                    enumerable: true,
                    configurable: true
                });
                ;
                Object.defineProperty(Context.prototype, "viewport", {
                    get: function () {
                        return {
                            width: Math.max(document.documentElement.clientWidth, window.innerWidth || 0),
                            height: Math.max(document.documentElement.clientHeight, window.innerHeight || 0),
                            htmlWidth: Math.max(document.documentElement.clientWidth, document.body.scrollWidth, document.documentElement.scrollWidth, document.body.offsetWidth, document.documentElement.offsetWidth),
                            htmlHeight: Math.max(document.documentElement.clientHeight, document.body.scrollHeight, document.documentElement.scrollHeight, document.body.offsetHeight, document.documentElement.offsetHeight)
                        };
                    },
                    enumerable: true,
                    configurable: true
                });
                ;
                Object.defineProperty(Context.prototype, "language", {
                    get: function () {
                        return this._language;
                    },
                    enumerable: true,
                    configurable: true
                });
                ;
                Object.defineProperty(Context.prototype, "region", {
                    get: function () {
                        return this._region;
                    },
                    enumerable: true,
                    configurable: true
                });
                ;
                Object.defineProperty(Context.prototype, "culture", {
                    get: function () {
                        return this._culture;
                    },
                    enumerable: true,
                    configurable: true
                });
                ;
                Object.defineProperty(Context.prototype, "environment", {
                    get: function () {
                        return this._environment;
                    },
                    enumerable: true,
                    configurable: true
                });
                ;
                Context.prototype.getValue = function (name, defaultValue) {
                    if (this[name])
                        return this[name];
                    else {
                        if (defaultValue !== undefined)
                            return defaultValue;
                        else
                            return false;
                    }
                };
                ;
                Context.prototype.getCookie = function (name, defaultValue) {
                    if (document.cookie.length > 0) {
                        var start = document.cookie.indexOf(name + '=');
                        var end;
                        if (start != -1) {
                            start = start + name.length + 1;
                            end = document.cookie.indexOf(';', start);
                            if (end == -1)
                                end = document.cookie.length;
                            return encodeURI(document.cookie.substring(start, end));
                        }
                    }
                    return defaultValue != undefined ? defaultValue : undefined;
                };
                ;
                Context.prototype.setCookie = function (name, value, days) {
                    var host = window.location.host.replace(/\:[0-9]{1,4}/g, '');
                    var domain = host.split('.');
                    if (!UrlUtils_1.default.isIPaddress(host) && domain.length > 2) {
                        domain = '.' + domain.slice(-(domain.length - 1)).join('.');
                    }
                    else
                        domain = '';
                    var expires = '';
                    if (days) {
                        var date = new Date();
                        date.setTime(date.getTime() + (days * 24 * 60 * 60 * 1000));
                        expires = date.toUTCString();
                    }
                    document.cookie = name + '=' + value + '; expires=' + expires + '; path=/; domain=' + domain + ';';
                };
                ;
                Context.getInstance = function () {
                    return Context._instance;
                };
                return Context;
            }(AbstractDispatcher_2.default));
            exports_5("default", Context);
        }
    };
});
System.register("src/ts/vendors/frontools/src/utils/DocumentUtils", [], function (exports_6, context_6) {
    "use strict";
    var DocumentUtils;
    var __moduleName = context_6 && context_6.id;
    return {
        setters: [],
        execute: function () {
            DocumentUtils = (function () {
                function DocumentUtils() {
                }
                DocumentUtils.openPopup = function (link, width, height) {
                    if (width === void 0) { width = 500; }
                    if (height === void 0) { height = 500; }
                    var left = (screen.width - width) / 2, top = (screen.height - height) / 2, params = {
                        width: width, height: height, top: top, left: left,
                        toolbar: 'no', location: 'no', directories: 'no', status: 'no',
                        menubar: 'no', resizable: 'no', copyhistory: 'no', scrollbars: 'yes'
                    }, arr = [];
                    for (var key in params) {
                        if (params.hasOwnProperty(key)) {
                            arr.push(key + '=' + params[key]);
                        }
                    }
                    var target = window.open(link, '', arr.join());
                    try {
                        target.moveTo(left, top);
                    }
                    catch (e) { }
                    return target;
                };
                ;
                DocumentUtils.debounce = function (callback, delay, callOnInit) {
                    if (callOnInit === void 0) { callOnInit = false; }
                    var timeout;
                    if (callOnInit)
                        callback.apply(null, arguments);
                    return function () {
                        var context = this, args = arguments, later = function () {
                            timeout = null;
                            callback.apply(context, args);
                        };
                        clearTimeout(timeout);
                        timeout = setTimeout(later, delay);
                    };
                };
                ;
                return DocumentUtils;
            }());
            exports_6("default", DocumentUtils);
        }
    };
});
System.register("src/ts/vendors/frontools/src/core/Breakpoint", ["src/ts/vendors/frontools/src/abstract/AbstractDispatcher", "src/ts/vendors/frontools/src/utils/DocumentUtils"], function (exports_7, context_7) {
    "use strict";
    var AbstractDispatcher_3, DocumentUtils_1, Breakpoint;
    var __moduleName = context_7 && context_7.id;
    return {
        setters: [
            function (AbstractDispatcher_3_1) {
                AbstractDispatcher_3 = AbstractDispatcher_3_1;
            },
            function (DocumentUtils_1_1) {
                DocumentUtils_1 = DocumentUtils_1_1;
            }
        ],
        execute: function () {
            Breakpoint = (function (_super) {
                __extends(Breakpoint, _super);
                function Breakpoint(values) {
                    if (values === void 0) { values = {}; }
                    var _this = _super.call(this) || this;
                    _this._debug = false;
                    _this._value = null;
                    _this._lastValue = null;
                    _this._activeIndex = 0;
                    _this._currentGroups = [];
                    _this._lastGroups = [];
                    _this._groups = {};
                    _this._breakpoints = [Breakpoint.MOBILES, Breakpoint.MOBILE, Breakpoint.TABLETP, Breakpoint.TABLET, Breakpoint.DESKTOP, Breakpoint.LARGE, Breakpoint.HD];
                    _this._debounceDelay = 25;
                    for (var i in values) {
                        if (i === 'breakpoints')
                            _this._breakpoints = values[i];
                        else if (i === 'groups')
                            _this._groups = values[i];
                        else if (i === 'debug')
                            _this._debug = values[i];
                        else
                            _this[i] = values[i];
                    }
                    if (Breakpoint._instance)
                        throw Error('Breakpoint Error: Use Breakpoint.getInstance() instead of new.');
                    Breakpoint._instance = _this;
                    _this.setBreakpoints(_this._breakpoints);
                    var resizeDebounce = DocumentUtils_1.default.debounce(function () {
                        this.onResize();
                    }.bind(_this), _this._debounceDelay, true);
                    window.addEventListener('resize', resizeDebounce);
                    if (_this._value === '') {
                        throw ('Breakpoint error: Warning no breakpoint set with CSS inside body:after');
                    }
                    return _this;
                }
                ;
                Breakpoint.trigger = function (forceGroupTrigger) {
                    if (forceGroupTrigger === void 0) { forceGroupTrigger = false; }
                    var breakpoint = Breakpoint.getInstance();
                    breakpoint.dispatch({ type: breakpoint._value });
                    if (breakpoint._groups !== null) {
                        breakpoint._currentGroups = breakpoint.getMatchingGroups(breakpoint._value);
                        if (breakpoint._currentGroups.length > 0) {
                            for (var i = 0, l = breakpoint._currentGroups.length; i < l; i++) {
                                if (breakpoint._lastGroups.indexOf(breakpoint._currentGroups[i]) === -1 || forceGroupTrigger) {
                                    breakpoint.dispatch({ type: breakpoint._currentGroups[i] });
                                }
                            }
                        }
                        breakpoint._lastGroups = breakpoint._currentGroups;
                    }
                };
                ;
                Breakpoint.prototype.dispatch = function (event) {
                    _super.prototype.dispatch.call(this, event);
                    if (this._debug)
                        console.info('BREAKPOINT: ' + event.type);
                };
                ;
                Breakpoint.on = function (type, listener) {
                    Breakpoint.getInstance().addListener(type, listener);
                };
                ;
                Breakpoint.off = function (type, listener) {
                    Breakpoint.getInstance().removeListener(type, listener);
                };
                ;
                Breakpoint.isLowerThan = function (breakpoint) {
                    return Breakpoint.getInstance()._activeIndex < Breakpoint.getInstance()._breakpoints.indexOf(breakpoint);
                };
                ;
                Breakpoint.isEqualTo = function (breakpoint) {
                    return Breakpoint.getInstance()._activeIndex === Breakpoint.getInstance()._breakpoints.indexOf(breakpoint);
                };
                ;
                Breakpoint.isHigherThan = function (breakpoint) {
                    return Breakpoint.getInstance()._activeIndex > Breakpoint.getInstance()._breakpoints.indexOf(breakpoint);
                };
                ;
                Breakpoint.prototype.isInGroup = function (groupName) {
                    var groups = this.getMatchingGroups(this._value);
                    for (var i = 0, l = groups.length; i < l; i++) {
                        if (groups[i] === groupName)
                            return true;
                    }
                    return false;
                };
                ;
                Breakpoint.getValue = function () {
                    return Breakpoint.getInstance()._value;
                };
                ;
                Breakpoint.getLastValue = function () {
                    return Breakpoint.getInstance()._lastValue;
                };
                ;
                Breakpoint.getBreakpoints = function () {
                    return Breakpoint.getInstance()._breakpoints;
                };
                ;
                Breakpoint.prototype.setBreakpoints = function (opts) {
                    if (opts instanceof Array) {
                        for (var i = 0, len = opts.length; i < len; i++) {
                            this._breakpoints[i] = opts[i];
                        }
                    }
                };
                ;
                Breakpoint.prototype.getMatchingGroups = function (value) {
                    var groups = [];
                    for (var key in this._groups) {
                        if (this._groups.hasOwnProperty(key)) {
                            for (var i = 0, len = this._groups[key].length; i < len; i++) {
                                if (this._groups[key][i] === value) {
                                    groups.push(key);
                                }
                            }
                        }
                    }
                    return groups;
                };
                ;
                Breakpoint.prototype.onResize = function () {
                    this._lastValue = this._value;
                    this._value = window.getComputedStyle(document.querySelector('body'), ':after').getPropertyValue('content').replace(/\"/g, '').toLowerCase();
                    this._activeIndex = this._breakpoints.indexOf(this._value);
                    if (this._lastValue !== this._value && this._lastValue !== null) {
                        Breakpoint.trigger();
                    }
                };
                ;
                Breakpoint.getInstance = function () {
                    if (typeof Breakpoint._instance === 'undefined') {
                        throw Error('Breakpoint error: Breakpoint has to be instantiated first via new Breakpoint();');
                    }
                    return Breakpoint._instance;
                };
                ;
                Breakpoint.MOBILES = 'mobiles';
                Breakpoint.MOBILE = 'mobile';
                Breakpoint.TABLETP = 'tabletp';
                Breakpoint.TABLET = 'tablet';
                Breakpoint.DESKTOP = 'desktop';
                Breakpoint.LARGE = 'large';
                Breakpoint.HD = 'hd';
                Breakpoint.MOBILE_VALUE = 640;
                Breakpoint.TABLET_VALUE = 1100;
                return Breakpoint;
            }(AbstractDispatcher_3.default));
            exports_7("default", Breakpoint);
        }
    };
});
System.register("src/ts/vendors/frontools/src/utils/VanillaUtils", [], function (exports_8, context_8) {
    "use strict";
    var VanillaUtils;
    var __moduleName = context_8 && context_8.id;
    return {
        setters: [],
        execute: function () {
            VanillaUtils = (function () {
                function VanillaUtils() {
                }
                VanillaUtils.addEvent = function (el, type, handler) {
                    if (el.attachEvent) {
                        el.attachEvent('on' + type, handler);
                    }
                    else {
                        el.addEventListener(type, handler);
                    }
                };
                ;
                VanillaUtils.removeEvent = function (el, type, handler) {
                    if (el.detachEvent) {
                        el.detachEvent('on' + type, handler);
                    }
                    else {
                        el.removeEventListener(type, handler);
                    }
                };
                ;
                VanillaUtils.hasClass = function (el, className) {
                    return el.classList ?
                        el.classList.contains(className) :
                        new RegExp('\\b' + className + '\\b').test(el.className);
                };
                ;
                VanillaUtils.addClass = function (el, className) {
                    if (el.classList) {
                        el.classList.add(className);
                    }
                    else if (!VanillaUtils.hasClass(el, className)) {
                        el.className += ' ' + className;
                    }
                };
                ;
                VanillaUtils.removeClass = function (el, className) {
                    if (el.classList) {
                        el.classList.remove(className);
                    }
                    else {
                        el.className = el.className.replace(new RegExp('\\b' + className + '\\b', 'g'), '');
                    }
                };
                ;
                VanillaUtils.getSiblingIndex = function (el) {
                    return Array.prototype.indexOf.call(el.parentNode.childNodes, el);
                };
                ;
                VanillaUtils.getOffsetRect = function (el) {
                    var box = el.getBoundingClientRect(), body = document.body, docEl = document.documentElement, scrollTop = window.pageYOffset || docEl.scrollTop || body.scrollTop, scrollLeft = window.pageXOffset || docEl.scrollLeft || body.scrollLeft, clientTop = docEl.clientTop || body.clientTop || 0, clientLeft = docEl.clientLeft || body.clientLeft || 0, top = box.top + scrollTop - clientTop, left = box.left + scrollLeft - clientLeft;
                    return { top: Math.round(top), left: Math.round(left) };
                };
                return VanillaUtils;
            }());
            exports_8("default", VanillaUtils);
        }
    };
});
System.register("src/ts/vendors/frontools/src/events/KeyCode", [], function (exports_9, context_9) {
    "use strict";
    var KeyCode;
    var __moduleName = context_9 && context_9.id;
    return {
        setters: [],
        execute: function () {
            KeyCode = (function () {
                function KeyCode() {
                }
                KeyCode.ESC = 27;
                KeyCode.TAB = 9;
                KeyCode.ENTER = 13;
                KeyCode.SHIFT = 16;
                KeyCode.CTRL = 17;
                KeyCode.ALT = 18;
                KeyCode.LEFT = 37;
                KeyCode.UP = 38;
                KeyCode.RIGHT = 39;
                KeyCode.DOWN = 40;
                return KeyCode;
            }());
            exports_9("default", KeyCode);
        }
    };
});
System.register("src/ts/vendors/frontools/src/utils/Accessibility", ["src/ts/vendors/frontools/src/events/KeyCode", "src/ts/vendors/frontools/src/utils/VanillaUtils"], function (exports_10, context_10) {
    "use strict";
    var KeyCode_1, VanillaUtils_1, Accessibility;
    var __moduleName = context_10 && context_10.id;
    return {
        setters: [
            function (KeyCode_1_1) {
                KeyCode_1 = KeyCode_1_1;
            },
            function (VanillaUtils_1_1) {
                VanillaUtils_1 = VanillaUtils_1_1;
            }
        ],
        execute: function () {
            Accessibility = (function () {
                function Accessibility() {
                }
                Accessibility.addTabMode = function (className) {
                    if (className === void 0) { className = 'tab_mode'; }
                    var htmlClass = (className) ? className : 'tab_mode';
                    function addTabModeClass(e) {
                        var keyCode = e.keyCode || e.which;
                        if (keyCode == KeyCode_1.default.TAB) {
                            var el = document.querySelector('html');
                            if (!VanillaUtils_1.default.hasClass(el, htmlClass)) {
                                VanillaUtils_1.default.addClass(el, htmlClass);
                                console.log('Add "tab_mode" class on <html>');
                                document.removeEventListener('keydown', addTabModeClass);
                            }
                        }
                    }
                    document.addEventListener('keydown', addTabModeClass);
                };
                ;
                return Accessibility;
            }());
            exports_10("default", Accessibility);
        }
    };
});
System.register("src/ts/vendors/frontools/src/abstract/AbstractMain", ["src/ts/vendors/frontools/src/abstract/AbstractDispatcher", "src/ts/vendors/frontools/src/core/Context", "src/ts/vendors/frontools/src/core/Breakpoint", "src/ts/vendors/frontools/src/utils/Accessibility", "src/ts/vendors/frontools/src/events/EventType", "src/ts/vendors/frontools/src/features/TouchFeature"], function (exports_11, context_11) {
    "use strict";
    var AbstractDispatcher_4, Context_1, Breakpoint_1, Accessibility_1, EventType_3, TouchFeature_2, AbstractMain;
    var __moduleName = context_11 && context_11.id;
    return {
        setters: [
            function (AbstractDispatcher_4_1) {
                AbstractDispatcher_4 = AbstractDispatcher_4_1;
            },
            function (Context_1_1) {
                Context_1 = Context_1_1;
            },
            function (Breakpoint_1_1) {
                Breakpoint_1 = Breakpoint_1_1;
            },
            function (Accessibility_1_1) {
                Accessibility_1 = Accessibility_1_1;
            },
            function (EventType_3_1) {
                EventType_3 = EventType_3_1;
            },
            function (TouchFeature_2_1) {
                TouchFeature_2 = TouchFeature_2_1;
            }
        ],
        execute: function () {
            AbstractMain = (function (_super) {
                __extends(AbstractMain, _super);
                function AbstractMain(options) {
                    if (options === void 0) { options = {}; }
                    var _this = _super.call(this) || this;
                    _this._domready = false;
                    _this._winload = false;
                    if (AbstractMain._instance) {
                        throw new Error('Error: Use AbstractMain.getInstance() or Main.getInstance() instead of new.');
                    }
                    AbstractMain._instance = _this;
                    _this._context = new Context_1.default(options);
                    _this.initFeatureDetection();
                    Accessibility_1.default.addTabMode();
                    _this.initBreakpoint();
                    _this.initGTM();
                    document.addEventListener('DOMContentLoaded', _this.DOMready.bind(_this));
                    window.onload = _this.WINload.bind(_this);
                    return _this;
                }
                ;
                AbstractMain.prototype.DOMready = function (e) {
                    if (e === void 0) { e = null; }
                    this._domready = true;
                    this.dispatch({ type: AbstractMain.DOM_READY });
                };
                AbstractMain.prototype.WINload = function (e) {
                    if (e === void 0) { e = null; }
                    this._winload = true;
                    if (!this._domready) {
                        this.DOMready(null);
                    }
                    this.dispatch({ type: AbstractMain.WIN_LOAD });
                };
                AbstractMain.prototype.initBreakpoint = function () {
                    this._breakpoint = new Breakpoint_1.default({
                        debug: false,
                        breakpoints: [
                            Breakpoint_1.default.MOBILES = 'mobiles',
                            Breakpoint_1.default.MOBILE = 'mobile',
                            Breakpoint_1.default.TABLETP = 'tabletp',
                            Breakpoint_1.default.TABLET = 'tablet',
                            Breakpoint_1.default.DESKTOP = 'desktop',
                            Breakpoint_1.default.LARGE = 'large',
                            Breakpoint_1.default.HD = 'hd'
                        ],
                        groups: {
                            'group_mobile': [
                                Breakpoint_1.default.MOBILES,
                                Breakpoint_1.default.MOBILE,
                                Breakpoint_1.default.TABLETP
                            ],
                            'group_desktop': [
                                Breakpoint_1.default.TABLET,
                                Breakpoint_1.default.DESKTOP,
                                Breakpoint_1.default.LARGE,
                                Breakpoint_1.default.HD
                            ],
                        }
                    });
                };
                AbstractMain.prototype.initFeatureDetection = function () {
                    var html = document.documentElement;
                    TouchFeature_2.default.instance.addListener(EventType_3.default.INTERACTION_CHANGE, function (e) {
                        html.classList.remove(e.isTouch ? 'no-touchevents' : 'touchevents');
                        html.classList.add(e.isTouch ? 'touchevents' : 'no-touchevents');
                    });
                };
                AbstractMain.prototype.initGTM = function () {
                    var trackingCtas = document.querySelectorAll('[data-tracking]');
                    var _loop_1 = function (i, l) {
                        trackingCtas[i].addEventListener('click', function (e) {
                            var data = trackingCtas[i].getAttribute('data-tracking').split('|');
                            dataLayer.push({
                                eventCategory: data[0],
                                eventAction: data[1],
                                eventLabel: data[2],
                                event: 'genericGAEvent'
                            });
                        });
                    };
                    for (var i = 0, l = trackingCtas.length; i < l; i++) {
                        _loop_1(i, l);
                    }
                };
                AbstractMain.prototype.addListener = function (type, listener) {
                    _super.prototype.addListener.call(this, type, listener);
                    if (type == AbstractMain.DOM_READY && this._domready) {
                        this.DOMready();
                    }
                    else if (type == AbstractMain.WIN_LOAD && this._winload) {
                        this.WINload();
                    }
                };
                Object.defineProperty(AbstractMain.prototype, "context", {
                    get: function () {
                        return this._context;
                    },
                    enumerable: true,
                    configurable: true
                });
                AbstractMain.getInstance = function () {
                    return AbstractMain._instance;
                };
                AbstractMain.DOM_READY = 'domready';
                AbstractMain.WIN_LOAD = 'winload';
                AbstractMain.HUMAN_TOUCHED = 'human_touched';
                return AbstractMain;
            }(AbstractDispatcher_4.default));
            exports_11("default", AbstractMain);
        }
    };
});
System.register("src/ts/vendors/frontools/src/decorators/Autobind", [], function (exports_12, context_12) {
    "use strict";
    var __moduleName = context_12 && context_12.id;
    function autobind(target, propertyKey, descriptor) {
        var original = descriptor && descriptor.value;
        var definingProperty = false;
        if (typeof original !== 'function' || original == undefined)
            return;
        return {
            configurable: true,
            set: function (value) {
                original = value;
            },
            get: function () {
                if (definingProperty || this === target.prototype || this.hasOwnProperty(propertyKey)) {
                    return original;
                }
                var binding = original.bind(this);
                definingProperty = true;
                Object.defineProperty(this, propertyKey, {
                    value: binding,
                    configurable: true,
                    writable: true
                });
                definingProperty = false;
                return binding;
            }
        };
    }
    exports_12("autobind", autobind);
    return {
        setters: [],
        execute: function () {
        }
    };
});
System.register("src/ts/vendors/frontools/src/utils/ScrollUtils", ["src/ts/vendors/frontools/src/core/Context", "src/ts/vendors/frontools/src/utils/VanillaUtils"], function (exports_13, context_13) {
    "use strict";
    var Context_2, VanillaUtils_2, ScrollUtils;
    var __moduleName = context_13 && context_13.id;
    return {
        setters: [
            function (Context_2_1) {
                Context_2 = Context_2_1;
            },
            function (VanillaUtils_2_1) {
                VanillaUtils_2 = VanillaUtils_2_1;
            }
        ],
        execute: function () {
            ScrollUtils = (function () {
                function ScrollUtils() {
                }
                ScrollUtils.scroll = function (e) {
                    e.scrollY = e.el.pageYOffset || document.documentElement.scrollTop;
                    e.scrollX = e.el.pageXOffset || document.documentElement.scrollLeft;
                    for (var i in ScrollUtils.registredElements) {
                        var obj = ScrollUtils.registredElements[i];
                        var top = 0;
                        var el = obj.element;
                        if (el) {
                            if (typeof el.position !== 'number') {
                                do {
                                    top += el.offsetTop || 0;
                                    el = el.offsetParent;
                                } while (el);
                                el = obj.element;
                            }
                            else {
                                top = el.position;
                            }
                        }
                        var pos = e.scrollY - top;
                        if (obj.isAfter === null) {
                            obj.isAfter = pos + obj.offset >= 0;
                        }
                        if (pos + obj.offset >= 0 && obj.isAfter === true) {
                            obj.isAfter = !obj.isAfter;
                            if (obj.after)
                                obj.after(obj.element);
                            if (obj.isOnce)
                                ScrollUtils.removeRegistredElement(obj.element);
                        }
                        else if (pos + obj.offset < 0 && obj.isAfter === false) {
                            obj.isAfter = !obj.isAfter;
                            if (obj.before)
                                obj.before(obj.element);
                            if (obj.isOnce)
                                ScrollUtils.removeRegistredElement(obj.element);
                        }
                    }
                    ScrollUtils.isFirst = false;
                };
                ScrollUtils.triggerEventOf = function (el) {
                    if (ScrollUtils.hasEvent(el)) {
                        var event;
                        if (document.createEvent) {
                            event = document.createEvent('HTMLEvents');
                            event.initEvent('scroll', true, true);
                        }
                        else if (document.createEventObject) {
                            event = document.createEventObject();
                            event.eventType = 'scroll';
                        }
                        event.eventName = 'scroll';
                        if (el.dispatchEvent)
                            el.dispatchEvent(event);
                        else if (el.fireEvent)
                            el.fireEvent('onscroll', event);
                        else if (el.scroll)
                            el.scroll(event);
                        else if (el.onscroll)
                            el.onscroll(event);
                    }
                    else {
                        throw new Error('No scroll event is found for: ' + el);
                    }
                };
                ;
                ScrollUtils.hasEvent = function (el) {
                    if (ScrollUtils.registredEvents.indexOf(el) !== -1)
                        return true;
                    else
                        return false;
                };
                ScrollUtils.registerEventTo = function (target, callback, force) {
                    if (force === undefined)
                        force = false;
                    if (ScrollUtils.hasEvent(target) && force === false) {
                        throw new Error('The scroll event for this [' + target + '] is defined. Use "force" parameter to replace it.');
                    }
                    else if (ScrollUtils.hasEvent(target) && force === true) {
                        ScrollUtils.removeRegistredEventOf(target);
                    }
                    target.scroll = (function (el, callback) {
                        return function (e) {
                            e.el = el;
                            this.scroll(e);
                            if (callback)
                                callback(e);
                        };
                    })(target, callback).bind(this);
                    if (target.addEventListener)
                        target.addEventListener('scroll', target.scroll, false);
                    else if (target.attachEvent)
                        target.attachEvent('onscroll', target.scroll);
                    else
                        target.onscroll = target.scroll;
                    ScrollUtils.registredEvents.push(target);
                };
                ScrollUtils.registerOnceElement = function (el, obj) {
                    obj.isOnce = true;
                    ScrollUtils.registerElement(el, obj);
                };
                ;
                ScrollUtils.updateRegistredElement = function (el, obj) {
                    var prop = 'element';
                    if (typeof el === 'string')
                        prop = 'id';
                    else if (typeof el === 'number')
                        prop = 'position';
                    for (var i in ScrollUtils.registredElements) {
                        if (ScrollUtils.registredElements[i][prop] === el || (prop === 'element' && ScrollUtils.registredElements[i][prop].name !== undefined && ScrollUtils.registredElements[i][prop].name === el.name)) {
                            for (var j in obj) {
                                ScrollUtils.registredElements[i][j] = obj[j];
                                if (prop === 'element' && ScrollUtils.registredElements[i][prop].position !== undefined && el.position !== undefined) {
                                    ScrollUtils.registredElements[i][prop].position = el.position;
                                }
                            }
                        }
                    }
                };
                ;
                ScrollUtils.removeRegistredElement = function (el) {
                    var prop = 'element';
                    if (typeof el === 'string')
                        prop = 'id';
                    else if (typeof el === 'number')
                        prop = 'position';
                    for (var i in ScrollUtils.registredElements) {
                        if (ScrollUtils.registredElements[i][prop] === el) {
                            ScrollUtils.registredElements.splice(Number(i), 1);
                        }
                    }
                };
                ;
                ScrollUtils.killScrollTo = function () {
                    TweenMax.killTweensOf(ScrollUtils.scrollingContainer);
                };
                ;
                ScrollUtils.scrollTo = function (options) {
                    var context = Context_2.default.getInstance();
                    var tween;
                    var value = 0;
                    var speed = (options.speed === undefined) ? 1 : options.speed;
                    var ease = (options.easing === undefined) ? '' : options.easing;
                    var target = options.target;
                    ScrollUtils.scrollingContainer = (options.scrollingContainer === undefined) ? window : options.scrollingContainer;
                    if (typeof target === 'number')
                        value = target;
                    else {
                        value = VanillaUtils_2.default.getOffsetRect(target).top;
                    }
                    if (options.offset !== undefined)
                        value -= options.offset;
                    var scrollEnd = (function (onEnd) {
                        return function (e) {
                            ScrollUtils.isScroll = false;
                            if (onEnd)
                                onEnd(tween);
                        };
                    })(options.onEnd);
                    var scrollStart = (function (onStart) {
                        return function (e) {
                            ScrollUtils.isScroll = true;
                            if (onStart)
                                onStart(tween);
                        };
                    })(options.onStart);
                    var scrollProgress = (function (onProgress) {
                        return function (e) {
                            ScrollUtils.isScroll = true;
                            if (onProgress)
                                onProgress(tween);
                        };
                    })(options.onProgress);
                    var viewport = context.viewport;
                    var maxHeight = viewport.htmlHeight - viewport.height;
                    if (value > maxHeight)
                        value = maxHeight;
                    if (window.TweenMax) {
                        tween = TweenMax.to(ScrollUtils.scrollingContainer, speed, {
                            scrollTo: { y: value, x: 0, autoKill: false },
                            ease: ease,
                            onComplete: scrollEnd,
                            onStart: scrollStart,
                            onUpdate: scrollProgress
                        });
                    }
                    else
                        throw new Error('TweenMax must be added in the projet');
                };
                ;
                ScrollUtils.isScroll = false;
                ScrollUtils.registredElements = [];
                ScrollUtils.registredEvents = [];
                ScrollUtils.isFirst = true;
                ScrollUtils._id = 0;
                ScrollUtils.removeRegistredEventOf = function (el) {
                    for (var i in ScrollUtils.registredEvents) {
                        if (ScrollUtils.registredEvents[i] === el) {
                            ScrollUtils.registredEvents.splice(Number(i), 1);
                            if (el.removeEventListener)
                                el.removeEventListener('scroll', el.scroll, false);
                            else if (el.detachEvent)
                                el.detachEvent('onscroll', el.scroll);
                            else
                                el.onscroll = null;
                        }
                    }
                };
                ScrollUtils.registerElement = function (el, obj) {
                    if (typeof el === 'number')
                        el = { position: el };
                    if (!ScrollUtils.hasEvent(window)) {
                        ScrollUtils.registerEventTo(window);
                    }
                    ScrollUtils._id++;
                    var key = 'element' + ScrollUtils._id;
                    ScrollUtils.registredElements.push({
                        id: key,
                        isAfter: null,
                        isOnce: obj.isOnce === undefined ? false : obj.isOnce,
                        element: el,
                        offset: obj.offset === undefined ? 0 : obj.offset,
                        after: obj.after,
                        before: obj.before
                    });
                    return key;
                };
                return ScrollUtils;
            }());
            exports_13("default", ScrollUtils);
        }
    };
});
System.register("src/ts/Abstract/Module", ["src/ts/vendors/frontools/src/abstract/AbstractDispatcher"], function (exports_14, context_14) {
    "use strict";
    var AbstractDispatcher_5, Module;
    var __moduleName = context_14 && context_14.id;
    return {
        setters: [
            function (AbstractDispatcher_5_1) {
                AbstractDispatcher_5 = AbstractDispatcher_5_1;
            }
        ],
        execute: function () {
            Module = (function (_super) {
                __extends(Module, _super);
                function Module(element, options) {
                    var _this = _super.call(this) || this;
                    _this._options = {
                        intersection: { rootMargin: '-150px 0px', threshold: 0 }
                    };
                    _this._element = element;
                    if (options) {
                        _this._options = options;
                    }
                    _this._intersectionObserver = new IntersectionObserver(_this._onIntersection, _this._options.intersection);
                    _this._intersectionObserver.observe(_this._element);
                    return _this;
                }
                Module.prototype.destroy = function () { };
                Module.prototype.onGroupMobile = function () { };
                Module.prototype.onGroupDesktop = function () { };
                Module.prototype.onTablet = function () { };
                Module.prototype.onMobile = function () { };
                Object.defineProperty(Module.prototype, "element", {
                    get: function () {
                        return this._element;
                    },
                    enumerable: true,
                    configurable: true
                });
                Module.prototype.showElement = function () {
                    TweenMax.set(this._element, { opacity: 1 });
                };
                Module.prototype._onIntersection = function (entries) {
                };
                Module.DOM_CHANGE = 'dom_change';
                return Module;
            }(AbstractDispatcher_5.default));
            exports_14("default", Module);
            ;
        }
    };
});
System.register("src/ts/Modules/Background", ["src/ts/Abstract/Module"], function (exports_15, context_15) {
    "use strict";
    var Module_1, Background, config;
    var __moduleName = context_15 && context_15.id;
    return {
        setters: [
            function (Module_1_1) {
                Module_1 = Module_1_1;
            }
        ],
        execute: function () {
            Background = (function (_super) {
                __extends(Background, _super);
                function Background(element) {
                    var _this = _super.call(this, element) || this;
                    _this._attribute = "data-background-notifier";
                    _this._currentNotified = element.getAttribute(_this._attribute);
                    _this._backgroundElement = document.querySelector("[data-background-id=" + _this._currentNotified + "]");
                    if (config[_this._currentNotified].tweenSet) {
                        TweenMax.set(_this._backgroundElement, config[_this._currentNotified].tweenSet);
                    }
                    _this._initScrollMagic();
                    return _this;
                }
                Background.prototype._initScrollMagic = function () {
                    this._controller = new ScrollMagic.Controller();
                    var t = TweenMax.to(this._backgroundElement, 1, config[this._currentNotified].tweenTo);
                    this._scene = new ScrollMagic.Scene({
                        triggerElement: this._element,
                        offset: config[this._currentNotified].scene.offset(this._element),
                        triggerHook: config[this._currentNotified].scene.triggerHook,
                        duration: config[this._currentNotified].scene.duration(this._element)
                    })
                        .setTween(t)
                        .addTo(this._controller);
                };
                Background.prototype.destroy = function () {
                };
                return Background;
            }(Module_1.default));
            exports_15("Background", Background);
            config = {
                "intro": {
                    "tweenTo": {
                        alpha: 0,
                        ease: Quad.easeOut
                    },
                    "scene": {
                        triggerHook: 0,
                        offset: function (element) {
                            return 0;
                        },
                        duration: function (element) {
                            return element.clientHeight * 3;
                        }
                    }
                },
                "outro": {
                    "tweenTo": {
                        alpha: 1,
                        ease: Quad.easeIn
                    },
                    "tweenSet": {
                        alpha: 0
                    },
                    "scene": {
                        triggerHook: 1,
                        offset: function (element) {
                            return -element.clientHeight;
                        },
                        duration: function (element) {
                            return element.clientHeight * 2;
                        }
                    }
                }
            };
        }
    };
});
System.register("src/ts/Modules/index", ["src/ts/Modules/Background"], function (exports_16, context_16) {
    "use strict";
    var Background_1, Modules;
    var __moduleName = context_16 && context_16.id;
    return {
        setters: [
            function (Background_1_1) {
                Background_1 = Background_1_1;
            }
        ],
        execute: function () {
            exports_16("Modules", Modules = {
                "background": Background_1.Background
            });
        }
    };
});
System.register("src/ts/Factories/ModuleFactory", ["src/ts/vendors/frontools/src/core/Breakpoint", "src/ts/vendors/frontools/src/decorators/Autobind", "src/ts/Modules/index", "src/ts/Abstract/Module", "src/ts/vendors/frontools/src/abstract/AbstractDispatcher"], function (exports_17, context_17) {
    "use strict";
    var Breakpoint_2, Autobind_1, index_1, Module_2, AbstractDispatcher_6, ModuleFactory;
    var __moduleName = context_17 && context_17.id;
    return {
        setters: [
            function (Breakpoint_2_1) {
                Breakpoint_2 = Breakpoint_2_1;
            },
            function (Autobind_1_1) {
                Autobind_1 = Autobind_1_1;
            },
            function (index_1_1) {
                index_1 = index_1_1;
            },
            function (Module_2_1) {
                Module_2 = Module_2_1;
            },
            function (AbstractDispatcher_6_1) {
                AbstractDispatcher_6 = AbstractDispatcher_6_1;
            }
        ],
        execute: function () {
            ModuleFactory = (function (_super) {
                __extends(ModuleFactory, _super);
                function ModuleFactory() {
                    var _this = _super.call(this) || this;
                    _this._moduleList = [];
                    _this._createModules();
                    Breakpoint_2.default.on('group_mobile', function () { return _this._onBreakpoint('onGroupMobile'); });
                    Breakpoint_2.default.on('group_desktop', function () { return _this._onBreakpoint('onGroupDesktop'); });
                    Breakpoint_2.default.on('mobile', function () { return _this._onBreakpoint('onMobile'); });
                    Breakpoint_2.default.on('tablet', function () { return _this._onBreakpoint('onTablet'); });
                    Breakpoint_2.default.trigger();
                    return _this;
                }
                ModuleFactory.prototype._onBreakpoint = function (breakpoint) {
                    for (var i = 0, l = this._moduleList.length; i < l; i++) {
                        if (typeof this._moduleList[i][breakpoint] === 'function') {
                            this._moduleList[i][breakpoint]();
                        }
                    }
                };
                ModuleFactory.prototype._createModules = function (elementToSearch) {
                    if (elementToSearch === void 0) { elementToSearch = document; }
                    console.log('createModules');
                    var elements = elementToSearch.querySelectorAll("[data-module]");
                    for (var i = 0, l = elements.length; i < l; i++) {
                        var element = elements[i];
                        var modules = element.getAttribute("data-module");
                        var modulesIds = modules.split('|');
                        for (var i_1 = 0; i_1 < modulesIds.length; i_1++) {
                            var id = modulesIds[i_1];
                            console.log(id);
                            if (index_1.Modules[id]) {
                                var module = new index_1.Modules[id](element);
                                module.addListener(Module_2.default.DOM_CHANGE, this._onDomChange);
                                this._moduleList.push(module);
                            }
                        }
                    }
                };
                ModuleFactory.prototype._onDomChange = function (e) {
                    for (var i = 0, l = e.data.elements.length; i < l; i++) {
                        this._createModules(e.data.elements[i]);
                    }
                };
                __decorate([
                    Autobind_1.autobind,
                    __metadata("design:type", Function),
                    __metadata("design:paramtypes", [Object]),
                    __metadata("design:returntype", void 0)
                ], ModuleFactory.prototype, "_onBreakpoint", null);
                __decorate([
                    Autobind_1.autobind,
                    __metadata("design:type", Function),
                    __metadata("design:paramtypes", [Object]),
                    __metadata("design:returntype", void 0)
                ], ModuleFactory.prototype, "_onDomChange", null);
                return ModuleFactory;
            }(AbstractDispatcher_6.default));
            exports_17("ModuleFactory", ModuleFactory);
        }
    };
});
System.register("src/ts/Factories/AnimationObserver", ["src/ts/vendors/frontools/src/decorators/Autobind"], function (exports_18, context_18) {
    "use strict";
    var Autobind_2, defaultOptions, AnimationObserver;
    var __moduleName = context_18 && context_18.id;
    return {
        setters: [
            function (Autobind_2_1) {
                Autobind_2 = Autobind_2_1;
            }
        ],
        execute: function () {
            defaultOptions = {
                defaultListener: {
                    selector: '[data-animation-observer]',
                    naturalDelay: {
                        left: 0.5,
                        top: 0
                    },
                    once: true,
                    intersectionOptions: {
                        rootMargin: '-120px 0px 0px 0px',
                        threshold: 0,
                    }
                }
            };
            AnimationObserver = (function () {
                function AnimationObserver(_options) {
                    this._options = _options;
                    this._intersectingAttribute = 'data-animation-observer';
                    if (AnimationObserver._instance)
                        throw new Error('Error: Use AnimationObserver.getInstance() instead of new.');
                    AnimationObserver._instance = this;
                    this._options = __assign({}, AnimationObserver.DEFAULT_OPTIONS, _options);
                    this.observe();
                }
                AnimationObserver.prototype.observe = function (listeners, targetElement) {
                    var _this = this;
                    if (listeners === void 0) { listeners = this._options.listeners; }
                    if (targetElement === void 0) { targetElement = document; }
                    var _loop_2 = function (i, l) {
                        var listener = __assign({}, this_1._options.defaultListener, listeners[i]);
                        var intersectionOptions = __assign({}, listeners[0].intersectionOptions, listener.intersectionOptions);
                        var intersectionObserver = new IntersectionObserver(function (entries, observer) { _this._onIntersect(entries, observer, listener); }, intersectionOptions);
                        var nodes = (typeof listener.selector === 'string') ? targetElement.querySelectorAll(listener.selector) : listener.selector;
                        for (var i_2 = 0, l_1 = nodes.length; i_2 < l_1; i_2++) {
                            intersectionObserver.observe(nodes[i_2]);
                        }
                    };
                    var this_1 = this;
                    for (var i = 0, l = listeners.length; i < l; i++) {
                        _loop_2(i, l);
                    }
                };
                ;
                AnimationObserver.prototype._onIntersect = function (entries, observer, listener) {
                    for (var i = 0, l = entries.length; i < l; i++) {
                        var entry = entries[i];
                        var element = entry.target;
                        var isIntersecting = entry.isIntersecting;
                        var animateOnce = listener.once;
                        if (listener.naturalDelay) {
                            var elementRect = element.getBoundingClientRect();
                            var delay = Math.abs(elementRect.left * listener.naturalDelay.left + elementRect.top * listener.naturalDelay.top);
                            entry.target.style.transitionDelay = delay / 1000 + "s";
                        }
                        if (typeof listener.onIntersect === 'function') {
                            listener.onIntersect(entry, observer);
                        }
                        if (isIntersecting === true)
                            this._markAsDone(element);
                        else if (!animateOnce && isIntersecting === false)
                            this._unmarkAsDone(element);
                        if (isIntersecting && animateOnce) {
                            observer.unobserve(element);
                        }
                    }
                };
                AnimationObserver.prototype._markAsDone = function (element) {
                    var attribute = element.getAttribute(this._intersectingAttribute);
                    if (attribute && attribute.indexOf('-intersecting') > -1)
                        return;
                    attribute = (attribute) ? attribute + "-intersecting" : "-intersecting";
                    element.setAttribute(this._intersectingAttribute, attribute);
                };
                AnimationObserver.prototype._unmarkAsDone = function (element) {
                    var attribute = element.getAttribute(this._intersectingAttribute);
                    attribute = attribute ? attribute.replace('-intersecting', '') : '';
                    element.setAttribute(this._intersectingAttribute, attribute);
                };
                Object.defineProperty(AnimationObserver, "instance", {
                    get: function () {
                        return AnimationObserver._instance;
                    },
                    enumerable: true,
                    configurable: true
                });
                AnimationObserver.DEFAULT_OPTIONS = defaultOptions;
                __decorate([
                    Autobind_2.autobind,
                    __metadata("design:type", Function),
                    __metadata("design:paramtypes", [Array, IntersectionObserver, Object]),
                    __metadata("design:returntype", void 0)
                ], AnimationObserver.prototype, "_onIntersect", null);
                return AnimationObserver;
            }());
            exports_18("default", AnimationObserver);
        }
    };
});
System.register("src/ts/Events/Tracker", [], function (exports_19, context_19) {
    "use strict";
    var Tracker;
    var __moduleName = context_19 && context_19.id;
    return {
        setters: [],
        execute: function () {
            Tracker = (function () {
                function Tracker() {
                }
                Tracker.virtualPageView = function (eventData) {
                    eventData.event = Tracker.VIRTUAL_PAGEVIEW;
                    this.event(eventData);
                };
                Tracker.social = function (eventData) {
                    eventData.event = Tracker.SOCIAL_EVENT;
                    var socialData = __assign({}, eventData, { socialNetwork: eventData.eventCategory, socialAction: eventData.eventAction, socialTarget: eventData.eventLabel });
                    this.push(socialData);
                };
                Tracker.event = function (eventData) {
                    if (typeof eventData['event'] === 'undefined') {
                        eventData.event = Tracker.GENERIC_EVENT;
                    }
                    this.push(eventData);
                };
                Tracker.parseEventString = function (eventData) {
                    if (!eventData.length) {
                        throw new Error('No string to parse');
                    }
                    var splitData = eventData.split('|');
                    if (splitData.length < 2) {
                        throw new Error('A minimum of 2 parts string required.');
                    }
                    return {
                        eventCategory: splitData[0],
                        eventAction: splitData[1],
                        eventLabel: splitData[2] || '',
                        event: Tracker.GENERIC_EVENT
                    };
                };
                Tracker.push = function (trackingData) {
                    dataLayer.push(trackingData);
                    if (Tracker._isVerbose) {
                        console.log('Tracker => ', trackingData);
                    }
                };
                Tracker._isVerbose = false;
                Tracker.SOCIAL_EVENT = 'socialInteraction';
                Tracker.VIRTUAL_PAGEVIEW = 'virtualPageview';
                Tracker.GENERIC_EVENT = 'genericGAEvent';
                return Tracker;
            }());
            exports_19("Tracker", Tracker);
        }
    };
});
System.register("src/ts/Events/EventBus", [], function (exports_20, context_20) {
    "use strict";
    var EventBus;
    var __moduleName = context_20 && context_20.id;
    return {
        setters: [],
        execute: function () {
            EventBus = (function () {
                function EventBus() {
                }
                EventBus.addListener = function (type, listener) {
                    if (EventBus.listeners[type] === undefined)
                        EventBus.listeners[type] = [];
                    if (EventBus.listeners[type].indexOf(listener) === -1) {
                        EventBus.listeners[type].push(listener);
                    }
                };
                EventBus.hasListener = function (type, listener) {
                    if (EventBus.listeners[type] !== undefined) {
                        if (EventBus.listeners[type].indexOf(listener) !== -1) {
                            return true;
                        }
                    }
                    return false;
                };
                EventBus.removeListener = function (type, listener) {
                    var listenersList = EventBus.listeners[type];
                    if (listenersList !== undefined) {
                        var index = listenersList.indexOf(listener);
                        if (index !== -1)
                            listenersList.splice(index, 1);
                    }
                };
                EventBus.removeAllListener = function () {
                    EventBus.listeners = {};
                };
                EventBus.dispatch = function (event) {
                    var listenersList = EventBus.listeners[event.type];
                    if (listenersList !== undefined) {
                        var array = [];
                        var length_2 = listenersList.length;
                        for (var i = 0; i < length_2; i++) {
                            array[i] = listenersList[i];
                            array[i].call(this, event);
                        }
                    }
                };
                EventBus.listeners = {};
                return EventBus;
            }());
            exports_20("EventBus", EventBus);
        }
    };
});
System.register("src/ts/Modules/Tracking", ["src/ts/Events/Tracker", "src/ts/vendors/frontools/src/core/Context", "src/ts/vendors/frontools/src/decorators/Autobind", "src/ts/Events/EventBus"], function (exports_21, context_21) {
    "use strict";
    var Tracker_1, Context_3, Autobind_3, EventBus_1, Tracking, TrackingEvents;
    var __moduleName = context_21 && context_21.id;
    return {
        setters: [
            function (Tracker_1_1) {
                Tracker_1 = Tracker_1_1;
            },
            function (Context_3_1) {
                Context_3 = Context_3_1;
            },
            function (Autobind_3_1) {
                Autobind_3 = Autobind_3_1;
            },
            function (EventBus_1_1) {
                EventBus_1 = EventBus_1_1;
            }
        ],
        execute: function () {
            Tracking = (function () {
                function Tracking() {
                    this._context = Context_3.default.getInstance();
                    this._init();
                    this._initCoveo();
                }
                Tracking.prototype._init = function () {
                    this._pageType = this._context.getValue('pageType');
                    this._elsGeneric = document.querySelectorAll('[data-tracking], [data-tracking-mobile]');
                    for (var i = 0, l = this._elsGeneric.length; i < l; i++) {
                        var element = this._elsGeneric[i];
                        element.addEventListener('click', this._handleGeneric);
                    }
                    var elsHomeBIArticles = document.querySelectorAll('.shortlist--bi .article--bi');
                    for (var i2 = 0, l2 = elsHomeBIArticles.length; i2 < l2; i2++) {
                        var element = elsHomeBIArticles[i2];
                        element.addEventListener('click', this._handleShortlistArticles);
                    }
                    EventBus_1.EventBus.addListener(TrackingEvents.FORM_SUBMITTED, this._onFormSubmit);
                    EventBus_1.EventBus.addListener(TrackingEvents.FORM_OPENED, this._onFormOpen);
                    EventBus_1.EventBus.addListener(TrackingEvents.CTA_CLICKED, this._onCTAClicked);
                };
                Tracking.prototype._initCoveo = function () {
                    var _this = this;
                    document.body.addEventListener('changeAnalyticsCustomData', function (e) {
                        var eventDetail = e.detail;
                        if (eventDetail.actionCause === 'facetSelect') {
                            var meta = eventDetail.metaObject;
                            var category = meta.facetTitle;
                            var facet = meta.facetValue;
                            Tracker_1.Tracker.event({
                                eventCategory: "filtre dans la page " + _this._context.getValue('pageType', 'autres'),
                                eventAction: category,
                                eventLabel: facet
                            });
                        }
                    });
                    var resultsList = document.querySelector('.CoveoResultList');
                    if (resultsList) {
                        resultsList.addEventListener('click', function (e) {
                            var target = e.target;
                            var closestResult = target.closest('.CoveoResult');
                            if (target.classList.contains('.CoveoResult') || closestResult) {
                                var result = closestResult || target;
                                var tagElement = result.querySelector('.article__tag') ? result.querySelector('.article__tag') : result.querySelector('.heading--small');
                                var titleElement = result.querySelector('.article__title') ? result.querySelector('.article__title') : result.querySelector('.heading--medium');
                                var action = (!tagElement || tagElement.innerHTML == '') ? 'autres' : tagElement.innerHTML;
                                var label = (!titleElement || titleElement.innerHTML == '') ? 'autres' : titleElement.innerHTML;
                                var pageType = _this._context.getValue('pageType', 'autres');
                                if (pageType === 'search' || pageType === 'recherche') {
                                    Tracker_1.Tracker.event({
                                        eventCategory: "recherche",
                                        eventAction: action,
                                        eventLabel: label
                                    });
                                }
                                else if (pageType === 'team' || pageType === 'equipe') {
                                    Tracker_1.Tracker.event({
                                        eventCategory: "equipe",
                                        eventAction: action,
                                        eventLabel: label
                                    });
                                }
                                else if (pageType === 'business-intelligence' || pageType === 'veille%20strategique' || pageType === 'veille-strategique') {
                                    Tracker_1.Tracker.event({
                                        eventCategory: "veille strategique",
                                        eventAction: action,
                                        eventLabel: label
                                    });
                                }
                            }
                        });
                    }
                };
                Tracking.prototype._onClickHandler = function (e) {
                    var target = e.target;
                    console.log(target);
                    if (target.classList.contains('article--bi') || target.closest('.article--bi')) {
                        var tag = target.querySelector('.article__tag');
                        var title = target.querySelector('.article__title');
                        Tracker_1.Tracker.event({
                            eventCategory: 'page...',
                            eventAction: tag.innerText,
                            eventLabel: title.innerText
                        });
                    }
                };
                Tracking.prototype._handleGeneric = function (e) {
                    if (e.currentTarget.dataset.tracking) {
                        Tracker_1.Tracker.event(Tracker_1.Tracker.parseEventString(e.currentTarget.dataset.tracking));
                    }
                    else if (e.currentTarget.dataset.TrackingMobile &&
                        this._context.isTouch) {
                        Tracker_1.Tracker.event(Tracker_1.Tracker.parseEventString(e.currentTarget.dataset.TrackingMobile));
                    }
                };
                Tracking.prototype._onFormSubmit = function (e) {
                    if (e.data.name === 'contact') {
                        Tracker_1.Tracker.event({
                            eventCategory: 'lead gen',
                            eventAction: 'email',
                            eventLabel: 'formulaire de contact'
                        });
                    }
                    Tracker_1.Tracker.virtualPageView({
                        uri: "/form/" + e.data.name + "?ga_page=confirmation-" + e.data.name
                    });
                };
                Tracking.prototype._onFormOpen = function (e) {
                    Tracker_1.Tracker.virtualPageView({
                        uri: "/form/" + e.data.name
                    });
                };
                Tracking.prototype._onCTAClicked = function (e) {
                    var ctaText = e.el.querySelector('.cta__text');
                    if (ctaText) {
                        Tracker_1.Tracker.event({
                            eventCategory: this._context.getValue('pageType', 'autres'),
                            eventAction: 'CTA',
                            eventLabel: ctaText.innerText
                        });
                    }
                };
                Tracking.prototype._handleShortlistArticles = function (e) {
                    var target = e.currentTarget;
                    var tag = target.querySelector('.article__tag');
                    var title = target.querySelector('.article__title');
                    Tracker_1.Tracker.event({
                        eventCategory: this._pageType,
                        eventAction: tag.innerText,
                        eventLabel: title.innerText
                    });
                };
                __decorate([
                    Autobind_3.autobind,
                    __metadata("design:type", Function),
                    __metadata("design:paramtypes", [Event]),
                    __metadata("design:returntype", void 0)
                ], Tracking.prototype, "_onClickHandler", null);
                __decorate([
                    Autobind_3.autobind,
                    __metadata("design:type", Function),
                    __metadata("design:paramtypes", [Object]),
                    __metadata("design:returntype", void 0)
                ], Tracking.prototype, "_handleGeneric", null);
                __decorate([
                    Autobind_3.autobind,
                    __metadata("design:type", Function),
                    __metadata("design:paramtypes", [Object]),
                    __metadata("design:returntype", void 0)
                ], Tracking.prototype, "_onFormSubmit", null);
                __decorate([
                    Autobind_3.autobind,
                    __metadata("design:type", Function),
                    __metadata("design:paramtypes", [Object]),
                    __metadata("design:returntype", void 0)
                ], Tracking.prototype, "_onFormOpen", null);
                __decorate([
                    Autobind_3.autobind,
                    __metadata("design:type", Function),
                    __metadata("design:paramtypes", [Object]),
                    __metadata("design:returntype", void 0)
                ], Tracking.prototype, "_onCTAClicked", null);
                __decorate([
                    Autobind_3.autobind,
                    __metadata("design:type", Function),
                    __metadata("design:paramtypes", [Object]),
                    __metadata("design:returntype", void 0)
                ], Tracking.prototype, "_handleShortlistArticles", null);
                return Tracking;
            }());
            exports_21("Tracking", Tracking);
            exports_21("TrackingEvents", TrackingEvents = {
                FORM_SUBMITTED: 'form_submitted',
                FORM_OPENED: 'form_opened',
                CTA_CLICKED: 'cta_clicked'
            });
        }
    };
});
System.register("src/ts/vendors/polyfills/ClosestPolyfill", [], function (exports_22, context_22) {
    "use strict";
    var ClosestPolyfill;
    var __moduleName = context_22 && context_22.id;
    return {
        setters: [],
        execute: function () {
            ClosestPolyfill = (function () {
                function ClosestPolyfill() {
                    if (!Element.prototype.matches)
                        Element.prototype.matches =
                            Element.prototype.msMatchesSelector ||
                                Element.prototype.webkitMatchesSelector;
                    if (!Element.prototype.closest) {
                        Element.prototype.closest = function (s) {
                            var el = this;
                            if (!document.documentElement.contains(el))
                                return null;
                            do {
                                if (el.matches(s))
                                    return el;
                                el = el.parentElement || el.parentNode;
                            } while (el !== null && el.nodeType === 1);
                            return null;
                        };
                    }
                }
                return ClosestPolyfill;
            }());
            exports_22("ClosestPolyfill", ClosestPolyfill);
        }
    };
});
System.register("src/ts/utilities/HeightUtils", [], function (exports_23, context_23) {
    "use strict";
    var HeightUtils;
    var __moduleName = context_23 && context_23.id;
    return {
        setters: [],
        execute: function () {
            HeightUtils = (function () {
                function HeightUtils() {
                    window.addEventListener('resize', this._onResize.bind(this));
                    this._onResize();
                }
                HeightUtils.prototype._onResize = function (e) {
                    this._vh = window.innerHeight * 0.01;
                    document.documentElement.style.setProperty('--vh', this._vh + "px");
                };
                return HeightUtils;
            }());
            exports_23("HeightUtils", HeightUtils);
        }
    };
});
System.register("src/ts/Main", ["src/ts/vendors/frontools/src/abstract/AbstractMain", "src/ts/Factories/ModuleFactory", "src/ts/vendors/frontools/src/core/Breakpoint", "src/ts/Factories/AnimationObserver", "src/ts/vendors/frontools/src/decorators/Autobind", "src/ts/vendors/frontools/src/utils/ScrollUtils", "src/ts/Modules/Tracking", "src/ts/vendors/polyfills/ClosestPolyfill", "src/ts/utilities/HeightUtils"], function (exports_24, context_24) {
    "use strict";
    var AbstractMain_1, ModuleFactory_1, Breakpoint_3, AnimationObserver_1, Autobind_4, ScrollUtils_1, Tracking_1, ClosestPolyfill_1, HeightUtils_1, Main;
    var __moduleName = context_24 && context_24.id;
    return {
        setters: [
            function (AbstractMain_1_1) {
                AbstractMain_1 = AbstractMain_1_1;
            },
            function (ModuleFactory_1_1) {
                ModuleFactory_1 = ModuleFactory_1_1;
            },
            function (Breakpoint_3_1) {
                Breakpoint_3 = Breakpoint_3_1;
            },
            function (AnimationObserver_1_1) {
                AnimationObserver_1 = AnimationObserver_1_1;
            },
            function (Autobind_4_1) {
                Autobind_4 = Autobind_4_1;
            },
            function (ScrollUtils_1_1) {
                ScrollUtils_1 = ScrollUtils_1_1;
            },
            function (Tracking_1_1) {
                Tracking_1 = Tracking_1_1;
            },
            function (ClosestPolyfill_1_1) {
                ClosestPolyfill_1 = ClosestPolyfill_1_1;
            },
            function (HeightUtils_1_1) {
                HeightUtils_1 = HeightUtils_1_1;
            }
        ],
        execute: function () {
            Main = (function (_super) {
                __extends(Main, _super);
                function Main(context) {
                    if (context === void 0) { context = {}; }
                    var _this = _super.call(this, context) || this;
                    _this.init();
                    _this.initClickDown();
                    return _this;
                }
                Main.prototype.init = function () {
                    objectFitImages(document.querySelectorAll('img.object-fit-cover'));
                    new ClosestPolyfill_1.ClosestPolyfill();
                    var options = {
                        listeners: [
                            AnimationObserver_1.default.DEFAULT_OPTIONS.defaultListener,
                            {
                                selector: ".rich-text > *, .page-list",
                            },
                            {
                                selector: "[data-animation-observer=\"ventures-nav-fixed\"]",
                                intersectionOptions: {
                                    rootMargin: "-40% 0px -19% 0px",
                                },
                                once: false,
                                onIntersect: function (entry, observer) {
                                    if (!entry.isIntersecting)
                                        document.documentElement.classList.add('ventures-nav-fixed');
                                    else
                                        document.documentElement.classList.remove('ventures-nav-fixed');
                                }
                            },
                            {
                                selector: "[data-animation-observer=\"callout\"]",
                                once: false,
                                intersectionOptions: {
                                    rootMargin: "-45% 0px -45% 0px",
                                },
                                onIntersect: function (entry, observer) {
                                    if (entry.isIntersecting)
                                        document.documentElement.classList.add('has-callout');
                                    else
                                        document.documentElement.classList.remove('has-callout');
                                }
                            }
                        ]
                    };
                    new AnimationObserver_1.default(options);
                    new HeightUtils_1.HeightUtils();
                    new ModuleFactory_1.ModuleFactory();
                };
                Main.prototype.initClickDown = function () {
                    var button = document.querySelector('[data-scroll-button]');
                    button.addEventListener('click', this._scrollTo);
                };
                Main.prototype._scrollTo = function (e) {
                    var button = e.currentTarget;
                    var target = "#" + button.getAttribute('data-scroll-button');
                    ScrollUtils_1.default.scrollTo({
                        target: document.querySelector(target),
                        speed: 1,
                        offset: 0,
                    });
                };
                Main.prototype.initBreakpoint = function () {
                    this._breakpoint = new Breakpoint_3.default({
                        debug: true,
                        breakpoints: [
                            Breakpoint_3.default.MOBILES = 'mobiles',
                            Breakpoint_3.default.MOBILE = 'mobile',
                            Breakpoint_3.default.TABLETP = 'tabletp',
                            Breakpoint_3.default.TABLET = 'tablet',
                            Breakpoint_3.default.DESKTOP = 'desktop',
                            Breakpoint_3.default.LARGE = 'large',
                            Breakpoint_3.default.HD = 'hd'
                        ],
                        groups: {
                            'group_mobile': [
                                Breakpoint_3.default.MOBILES,
                                Breakpoint_3.default.MOBILE,
                                Breakpoint_3.default.TABLETP,
                                Breakpoint_3.default.TABLET
                            ],
                            'group_desktop': [
                                Breakpoint_3.default.DESKTOP,
                                Breakpoint_3.default.LARGE,
                                Breakpoint_3.default.HD
                            ],
                        }
                    });
                };
                Main.prototype.initGTM = function () {
                    new Tracking_1.Tracking();
                };
                __decorate([
                    Autobind_4.autobind,
                    __metadata("design:type", Function),
                    __metadata("design:paramtypes", []),
                    __metadata("design:returntype", void 0)
                ], Main.prototype, "init", null);
                return Main;
            }(AbstractMain_1.default));
            exports_24("Main", Main);
        }
    };
});
System.register("src/ts/Events/Scroll", ["src/ts/vendors/frontools/src/decorators/Autobind"], function (exports_25, context_25) {
    "use strict";
    var Autobind_5, Scroll;
    var __moduleName = context_25 && context_25.id;
    return {
        setters: [
            function (Autobind_5_1) {
                Autobind_5 = Autobind_5_1;
            }
        ],
        execute: function () {
            Scroll = (function () {
                function Scroll() {
                    this._scrollPosition = 0;
                    this._lastScrollPosition = 0;
                    if (Scroll._instance)
                        throw Error('Scroll Error: Use Scroll.instance instead of new.');
                    Scroll._instance = this;
                    this._scrollingElement = document.scrollingElement || document.documentElement;
                    window.addEventListener('scroll', this.onScroll);
                }
                Scroll.prototype.onScroll = function (e) {
                    this._lastScrollPosition = this._scrollPosition;
                    this._scrollPosition = this._scrollingElement.scrollTop;
                    if (this._scrollPosition > 0) {
                        document.documentElement.classList.add('has-scrolled-down');
                    }
                    else {
                        document.documentElement.classList.remove('has-scrolled-down');
                    }
                };
                Object.defineProperty(Scroll.prototype, "direction", {
                    get: function () {
                        return this._scrollPosition >= this._lastScrollPosition ? 1 : -1;
                    },
                    enumerable: true,
                    configurable: true
                });
                Object.defineProperty(Scroll, "instance", {
                    get: function () {
                        if (typeof this._instance === 'undefined') {
                            throw Error('Scroll error: Scroll has to be instantiated first via new Scroll();');
                        }
                        return this._instance;
                    },
                    enumerable: true,
                    configurable: true
                });
                Object.defineProperty(Scroll.prototype, "scrollTop", {
                    get: function () {
                        return this._scrollingElement.scrollTop;
                    },
                    enumerable: true,
                    configurable: true
                });
                __decorate([
                    Autobind_5.autobind,
                    __metadata("design:type", Function),
                    __metadata("design:paramtypes", [Event]),
                    __metadata("design:returntype", void 0)
                ], Scroll.prototype, "onScroll", null);
                return Scroll;
            }());
            exports_25("default", Scroll);
        }
    };
});
System.register("src/ts/vendors/frontools/src/abstract/AbstractSingleton", [], function (exports_26, context_26) {
    "use strict";
    var AbstractSingleton;
    var __moduleName = context_26 && context_26.id;
    return {
        setters: [],
        execute: function () {
            AbstractSingleton = (function () {
                function AbstractSingleton() {
                    if (AbstractSingleton.instance)
                        console.warn('Error: Use [CLASS].getInstance() instead of new.');
                    AbstractSingleton.instance = this;
                }
                ;
                AbstractSingleton.getInstance = function () {
                    return AbstractSingleton.instance;
                };
                return AbstractSingleton;
            }());
            exports_26("default", AbstractSingleton);
        }
    };
});
System.register("src/ts/vendors/frontools/src/utils/NumberUtils", [], function (exports_27, context_27) {
    "use strict";
    var NumberUtils;
    var __moduleName = context_27 && context_27.id;
    return {
        setters: [],
        execute: function () {
            NumberUtils = (function () {
                function NumberUtils() {
                }
                NumberUtils.random = function (min, max, rounded) {
                    if (rounded === void 0) { rounded = true; }
                    var number = Math.random() * (max - min) + min;
                    return rounded === true ? Math.round(number) : number;
                };
                ;
                NumberUtils.boolean = function (chance) {
                    return (Math.random() < chance);
                };
                ;
                NumberUtils.isEven = function (value) {
                    return (value & 1) === 0;
                };
                ;
                NumberUtils.isOdd = function (value) {
                    return !NumberUtils.isEven(value);
                };
                ;
                NumberUtils.isInteger = function (value) {
                    return (value % 1) === 0;
                };
                ;
                NumberUtils.leadingZero = function (value, width, filler) {
                    if (width === void 0) { width = 2; }
                    if (filler === void 0) { filler = '0'; }
                    var str = value + '';
                    return str.length >= width ? str : new Array(width - str.length + 1).join(filler) + str;
                };
                ;
                NumberUtils.thousandSeparators = function (value, lang) {
                    if (lang === void 0) { lang = 'fr'; }
                    var sep = lang === 'fr' ? " " : ',';
                    return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, sep);
                };
                ;
                return NumberUtils;
            }());
            exports_27("default", NumberUtils);
        }
    };
});
System.register("src/ts/vendors/frontools/src/decorators/Debounce", ["src/ts/vendors/frontools/src/utils/DocumentUtils"], function (exports_28, context_28) {
    "use strict";
    var DocumentUtils_2;
    var __moduleName = context_28 && context_28.id;
    function debounce(delay) {
        if (delay === void 0) { delay = 500; }
        return function (target, propertyKey, descriptor) {
            var original = descriptor && descriptor.value;
            if (typeof original !== 'function' || original == undefined)
                return;
            descriptor.value = DocumentUtils_2.default.debounce(original, delay);
            return descriptor;
        };
    }
    exports_28("debounce", debounce);
    return {
        setters: [
            function (DocumentUtils_2_1) {
                DocumentUtils_2 = DocumentUtils_2_1;
            }
        ],
        execute: function () {
        }
    };
});
System.register("src/ts/vendors/frontools/src/animations/AnimManager", ["src/ts/vendors/frontools/src/abstract/AbstractDispatcher", "src/ts/vendors/frontools/src/core/Context", "src/ts/vendors/frontools/src/utils/ScrollUtils", "src/ts/vendors/frontools/src/utils/NumberUtils", "src/ts/vendors/frontools/src/decorators/Debounce", "src/ts/vendors/frontools/src/decorators/Autobind"], function (exports_29, context_29) {
    "use strict";
    var AbstractDispatcher_7, Context_4, ScrollUtils_2, NumberUtils_1, Debounce_1, Autobind_6, AnimManager, AnimManagerOptions, AnimManagerEvent;
    var __moduleName = context_29 && context_29.id;
    return {
        setters: [
            function (AbstractDispatcher_7_1) {
                AbstractDispatcher_7 = AbstractDispatcher_7_1;
            },
            function (Context_4_1) {
                Context_4 = Context_4_1;
            },
            function (ScrollUtils_2_1) {
                ScrollUtils_2 = ScrollUtils_2_1;
            },
            function (NumberUtils_1_1) {
                NumberUtils_1 = NumberUtils_1_1;
            },
            function (Debounce_1_1) {
                Debounce_1 = Debounce_1_1;
            },
            function (Autobind_6_1) {
                Autobind_6 = Autobind_6_1;
            }
        ],
        execute: function () {
            AnimManager = (function (_super) {
                __extends(AnimManager, _super);
                function AnimManager(_options) {
                    if (_options === void 0) { _options = new AnimManagerOptions(); }
                    var _this = _super.call(this) || this;
                    _this._options = _options;
                    _this._animHashMap = {};
                    _this._animQueue = [];
                    _this._lang = Context_4.default.getInstance().language;
                    _this._registered = [];
                    _this._items = document.querySelectorAll(_this._options.selector);
                    _this._createAnimsHashMap();
                    _this._parseElements();
                    window.addEventListener('resize', _this._onResize);
                    return _this;
                }
                AnimManager.prototype._parseElements = function () {
                    for (var i = 0, l = this._items.length; i < l; i++) {
                        var ele = this._items[i];
                        ScrollUtils_2.default.registerElement(ele, {
                            offset: Context_4.default.getInstance().viewport.height - 100,
                            before: function (el) { }.bind(this),
                            after: function (el) {
                                this._anim(el);
                            }.bind(this)
                        });
                        this._registered.push(ele);
                        ele.style.visibility = 'hidden';
                        ScrollUtils_2.default.triggerEventOf(window);
                    }
                };
                AnimManager.prototype._createAnimsHashMap = function () {
                    var _this = this;
                    this._animHashMap['fromBottom'] = function (e) {
                        TweenMax.from(e.target, 1, { delay: e.delay + (e.animationQueueIndex * .1), y: 50, alpha: 0, ease: Expo.easeOut, onComplete: _this._clear, onCompleteParams: [e.target] });
                    };
                    this._animHashMap['fromRight'] = function (e) {
                        TweenMax.from(e.target, 1, { delay: e.delay + (e.animationQueueIndex * .1), x: 50, alpha: 0, ease: Expo.easeOut, onComplete: _this._clear, onCompleteParams: [e.target] });
                    };
                    this._animHashMap['fromLeft'] = function (e) {
                        TweenMax.from(e.target, 1, { delay: e.delay + (e.animationQueueIndex * .1), x: -50, alpha: 0, ease: Expo.easeOut, onComplete: _this._clear, onCompleteParams: [e.target] });
                    };
                    this._animHashMap['fade'] = function (e) {
                        TweenMax.from(e.target, 1, { delay: e.delay + (e.animationQueueIndex * .1), alpha: 0, ease: Expo.easeOut, onComplete: _this._clear, onCompleteParams: [e.target] });
                    };
                    this._animHashMap['lineByLine'] = function (e) {
                        var split = new SplitText(e.target, { type: 'lines' });
                        TweenMax.staggerFrom(split.lines, .8, { delay: e.delay + (e.animationQueueIndex * .1), alpha: 0, x: 40, ease: Expo.easeOut, onComplete: _this._clearSplit, onCompleteParams: [e.target, split] }, .1);
                    };
                    this._animHashMap['charByChar'] = function (e) {
                        var split = new SplitText(e.target, { type: 'chars' });
                        TweenMax.staggerFrom(split.chars, .8, { delay: e.delay + (e.animationQueueIndex * .01), alpha: 0, ease: Expo.easeOut, onComplete: _this._clearSplit, onCompleteParams: [split] }, .1);
                    };
                    this._animHashMap['3dFromLeft'] = function (e) {
                        TweenMax.set(e.target, { backfaceVisibility: 'hidden', transformPerspective: 200, transformStyle: 'preserve-3d' });
                        TweenMax.from(e.target, 1, { delay: e.delay + (e.animationQueueIndex * .1), rotationY: -180, ease: Expo.easeOut, onComplete: _this._clear, onCompleteParams: [e.target] });
                    };
                    this._animHashMap['count'] = function (e) {
                        var value = parseInt(e.target.innerHTML, 10), counter = { num: 0 };
                        TweenMax.to(counter, e.speed, {
                            num: value,
                            onUpdate: function () {
                                var val = NumberUtils_1.default.thousandSeparators(Math.ceil(counter.num), this._lang);
                                e.target.innerText = String(val);
                            }.bind(_this),
                            ease: Expo.easeOut,
                            onComplete: _this._clear,
                            onCompleteParams: [e.target]
                        });
                    };
                };
                AnimManager.prototype._anim = function (ele) {
                    var type = ele.getAttribute(this._options.inviewAttribute);
                    ScrollUtils_2.default.removeRegistredElement(ele);
                    var indexOf = this._registered.indexOf(ele);
                    this._registered.splice(indexOf, 1);
                    if (this._options.removeAttribute) {
                        ele.removeAttribute('data-inview');
                    }
                    else {
                        ele.setAttribute(this._options.inviewAttribute, 'true');
                    }
                    var delayAttr = ele.getAttribute(this._options.delayAttribute);
                    var speedAttr = ele.getAttribute(this._options.speedAttribute);
                    var delay = delayAttr ? parseInt(delayAttr, 10) : 0;
                    var speed = speedAttr ? parseInt(speedAttr, 10) : 3;
                    var anim = function (i) {
                        var event = new AnimManagerEvent(i, ele, delay, speed);
                        this._animHashMap[type](event);
                        ele.style.visibility = 'visible';
                    }.bind(this);
                    this._animQueue.push(anim);
                    this._launchAnimation();
                };
                ;
                AnimManager.prototype._clear = function (ele, props) {
                    if (props === void 0) { props = 'x, y, opacity, visibility'; }
                    TweenMax.set(ele, { clearProps: props });
                };
                ;
                AnimManager.prototype._clearSplit = function (ele, split) {
                    split.revert();
                };
                AnimManager.prototype._launchAnimation = function () {
                    clearTimeout(this._animTimeout);
                    this._animTimeout = setTimeout(function () {
                        var i = 0;
                        while (this._animQueue.length > 0) {
                            this._animQueue.shift().call(this, i);
                            i++;
                        }
                    }.bind(this), 10);
                };
                ;
                AnimManager.prototype.addCustomAnimation = function (name, customFunction) {
                    if (this._animHashMap[name])
                        throw ('This animation name is already taken. Please use another');
                    if (!customFunction || {}.toString.call(customFunction) !== '[object Function]')
                        throw ('Custom animation is not a function');
                    this._animHashMap[name] = customFunction;
                };
                AnimManager.prototype._onResize = function () {
                    for (var i = 0, l = this._items.length; i < l; i++) {
                        var ele = this._items[i];
                        ScrollUtils_2.default.registerElement(ele, {});
                    }
                };
                ;
                AnimManager.prototype.destroy = function () {
                    for (var i = 0, l = this._registered.length; i < l; i++) {
                        ScrollUtils_2.default.removeRegistredElement(this._registered[i]);
                    }
                    window.removeEventListener('resize', this._onResize);
                };
                __decorate([
                    Autobind_6.autobind,
                    Debounce_1.debounce(200),
                    __metadata("design:type", Function),
                    __metadata("design:paramtypes", []),
                    __metadata("design:returntype", void 0)
                ], AnimManager.prototype, "_onResize", null);
                return AnimManager;
            }(AbstractDispatcher_7.default));
            exports_29("default", AnimManager);
            AnimManagerOptions = (function () {
                function AnimManagerOptions() {
                    this._selector = '[' + AnimManagerOptions.DEFAULT_INVIEW_ATTRIBUTE + ']';
                    this._inviewAttribute = AnimManagerOptions.DEFAULT_INVIEW_ATTRIBUTE;
                    this._delayAttribute = AnimManagerOptions.DEFAULT_DELAY_ATTRIBUTE;
                    this._speedAttribute = AnimManagerOptions.DEFAULT_SPEED_ATTRIBUTE;
                    this._removeAttribute = false;
                }
                Object.defineProperty(AnimManagerOptions.prototype, "selector", {
                    get: function () {
                        return this._selector;
                    },
                    set: function (value) { this._selector = value; },
                    enumerable: true,
                    configurable: true
                });
                Object.defineProperty(AnimManagerOptions.prototype, "inviewAttribute", {
                    get: function () {
                        return this._inviewAttribute;
                    },
                    set: function (value) {
                        this._inviewAttribute = value;
                    },
                    enumerable: true,
                    configurable: true
                });
                Object.defineProperty(AnimManagerOptions.prototype, "delayAttribute", {
                    get: function () {
                        return this._delayAttribute;
                    },
                    set: function (value) {
                        this._delayAttribute = value;
                    },
                    enumerable: true,
                    configurable: true
                });
                Object.defineProperty(AnimManagerOptions.prototype, "speedAttribute", {
                    get: function () {
                        return this._speedAttribute;
                    },
                    set: function (value) {
                        this.speedAttribute = value;
                    },
                    enumerable: true,
                    configurable: true
                });
                Object.defineProperty(AnimManagerOptions.prototype, "removeAttribute", {
                    get: function () {
                        return this._removeAttribute;
                    },
                    set: function (value) {
                        this._removeAttribute = value;
                    },
                    enumerable: true,
                    configurable: true
                });
                AnimManagerOptions.DEFAULT_INVIEW_ATTRIBUTE = 'data-inview';
                AnimManagerOptions.DEFAULT_DELAY_ATTRIBUTE = 'data-inview-delay';
                AnimManagerOptions.DEFAULT_SPEED_ATTRIBUTE = 'data-inview-speed';
                return AnimManagerOptions;
            }());
            exports_29("AnimManagerOptions", AnimManagerOptions);
            AnimManagerEvent = (function () {
                function AnimManagerEvent(_animationQueueIndex, _target, _delay, _speed) {
                    this._animationQueueIndex = _animationQueueIndex;
                    this._target = _target;
                    this._delay = _delay;
                    this._speed = _speed;
                }
                Object.defineProperty(AnimManagerEvent.prototype, "animationQueueIndex", {
                    get: function () {
                        return this._animationQueueIndex;
                    },
                    enumerable: true,
                    configurable: true
                });
                Object.defineProperty(AnimManagerEvent.prototype, "target", {
                    get: function () {
                        return this._target;
                    },
                    enumerable: true,
                    configurable: true
                });
                Object.defineProperty(AnimManagerEvent.prototype, "delay", {
                    get: function () {
                        return this._delay;
                    },
                    enumerable: true,
                    configurable: true
                });
                Object.defineProperty(AnimManagerEvent.prototype, "speed", {
                    get: function () {
                        return this._speed;
                    },
                    enumerable: true,
                    configurable: true
                });
                return AnimManagerEvent;
            }());
            exports_29("AnimManagerEvent", AnimManagerEvent);
        }
    };
});
System.register("src/ts/vendors/frontools/src/utils/ObjectUtils", [], function (exports_30, context_30) {
    "use strict";
    var ObjectUtils;
    var __moduleName = context_30 && context_30.id;
    return {
        setters: [],
        execute: function () {
            ObjectUtils = (function () {
                function ObjectUtils() {
                }
                ObjectUtils.extends = function (obj1, obj2) {
                    for (var i in obj2) {
                        obj1[i] = obj2[i];
                    }
                    return obj1;
                };
                ;
                ObjectUtils.clone = function (obj) {
                    if (null == obj || "object" != typeof obj)
                        return obj;
                    var copy = obj.constructor();
                    for (var attr in obj) {
                        if (obj.hasOwnProperty(attr))
                            copy[attr] = obj[attr];
                    }
                    return copy;
                };
                return ObjectUtils;
            }());
            exports_30("default", ObjectUtils);
        }
    };
});
System.register("src/ts/vendors/frontools/src/core/Routar", ["src/ts/vendors/frontools/src/utils/ObjectUtils", "src/ts/vendors/frontools/src/utils/UrlUtils", "src/ts/vendors/frontools/src/decorators/Autobind"], function (exports_31, context_31) {
    "use strict";
    var ObjectUtils_1, UrlUtils_2, Autobind_7, Routar;
    var __moduleName = context_31 && context_31.id;
    return {
        setters: [
            function (ObjectUtils_1_1) {
                ObjectUtils_1 = ObjectUtils_1_1;
            },
            function (UrlUtils_2_1) {
                UrlUtils_2 = UrlUtils_2_1;
            },
            function (Autobind_7_1) {
                Autobind_7 = Autobind_7_1;
            }
        ],
        execute: function () {
            Routar = (function () {
                function Routar(routes) {
                    if (routes === void 0) { routes = {}; }
                    this._supported = false;
                    this._root = '/';
                    this._routes = {};
                    this._status = '';
                    this._isStart = false;
                    if (Routar._instance)
                        throw new Error('Error: Use Routar.getInstance() instead of new.');
                    Routar._instance = this;
                    if (window.history && !window.history.pushState)
                        return;
                    else
                        this._supported = true;
                    this._routes = this.createRegExp(routes);
                    console.log(this._routes);
                    this.start();
                }
                Routar.prototype.onClickListener = function (event) {
                    if (!this._isStart)
                        return;
                    if (!event.currentTarget.href)
                        return;
                    if (UrlUtils_2.default.isExternalURL(event.currentTarget.href))
                        return;
                    var url = UrlUtils_2.default.parse(event.currentTarget.href);
                    var requestedRoute = this.parseUrl(url.path + (url.query != '' ? '?' + url.query : ''));
                    var route = this.getRoute(requestedRoute.path_without_query);
                    if (route) {
                        if (this._lastExecutedRoute && this._lastExecutedRoute.url === requestedRoute.url) {
                            event && event.preventDefault();
                            return;
                        }
                        event && event.preventDefault();
                        this._lastCalledRoute = requestedRoute;
                        requestedRoute = this.addRouteData(requestedRoute);
                        history.pushState(requestedRoute, requestedRoute.url, requestedRoute.url);
                        this.executeRoute(requestedRoute);
                    }
                };
                Routar.prototype.onPopState = function (event) {
                    if (!this._isStart)
                        return;
                    if (!event.state)
                        return;
                    event.state.params = {};
                    var parsedUrl = event.state;
                    this._lastCalledRoute = parsedUrl;
                    this.executeRoute(parsedUrl);
                };
                Routar.prototype.createRegExp = function (routeList) {
                    for (var route in routeList) {
                        routeList[route] = Object.create(routeList[route]);
                        var routeRegExp = route.toString();
                        if (routeRegExp.substr(0, 1) == '/')
                            routeRegExp = routeRegExp.substr(1);
                        if (routeRegExp.substr(routeRegExp.length - 1) == '/')
                            routeRegExp = routeRegExp.substr(0, routeRegExp.length - 1);
                        routeRegExp = routeRegExp.replace(/\//g, '\\/');
                        var paramList = [];
                        var regx = new RegExp("(\\(\\?)?:\\w+", "g");
                        var arr;
                        while ((arr = regx.exec(route)) !== null) {
                            paramList.push(arr[0].slice(1));
                        }
                        for (var param in paramList) {
                            routeRegExp = routeRegExp.replace(':' + paramList[param].toString(), '([A-Za-z0-9_\-]+)');
                        }
                        routeRegExp += '(\/?)(.*)?$';
                        routeList[route]['regex'] = routeRegExp;
                        routeList[route]['params'] = paramList;
                    }
                    return routeList;
                };
                Routar.prototype.addRouteData = function (parsedUrl) {
                    var route = this.getRoute(parsedUrl.path_without_query);
                    parsedUrl['route_params'] = {};
                    var i = 1;
                    var arr = [];
                    var regex = new RegExp(route.regex, 'g');
                    var regexResult = regex.exec(parsedUrl.path_without_query);
                    for (var param in route.params) {
                        parsedUrl['route_params'][route.params[param]] = regexResult[i];
                        i++;
                    }
                    return parsedUrl;
                };
                Routar.prototype.getRoute = function (path) {
                    if (path == '')
                        path = '/';
                    if (path.indexOf('://') != -1) {
                        var url = UrlUtils_2.default.parse(path);
                        path = url.path;
                    }
                    for (var route in this._routes) {
                        if (path.toString().match(this._routes[route].regex)) {
                            return this._routes[route];
                        }
                    }
                    return false;
                };
                Routar.prototype.routeHandler = function (parsedUrl) {
                    this._lastCalledRoute = parsedUrl;
                    parsedUrl = this.addRouteData(parsedUrl);
                    history.pushState(parsedUrl, parsedUrl.url, parsedUrl.url);
                    this.executeRoute(parsedUrl);
                };
                Routar.prototype.executeRoute = function (parsedUrl) {
                    var route = this.getRoute(parsedUrl.path_without_query);
                    if (!route)
                        return;
                    if (route.service === undefined)
                        route.service = false;
                    if (route.beforeUpdate) {
                        this.routeBeforeUpdate(parsedUrl);
                        return;
                    }
                    if (route.service !== false) {
                        this.serviceHandler(parsedUrl);
                        return;
                    }
                    this.routeUpdate(parsedUrl);
                };
                Routar.prototype.routeBeforeUpdate = function (parsedUrl) {
                    var route = this.getRoute(parsedUrl.path_without_query);
                    this._status = 'onBeforeUpdate';
                    route.beforeUpdate && route.beforeUpdate({ request: parsedUrl }, this.nextProcess.bind(this));
                };
                Routar.prototype.routeUpdate = function (parsedUrl, response) {
                    var route = this.getRoute(parsedUrl.path_without_query);
                    this._status = 'onUpdate';
                    route.update && route.update({ request: parsedUrl, response: response });
                    this._prevExecutedRoute = this._lastExecutedRoute;
                    this._lastExecutedRoute = parsedUrl;
                };
                Routar.prototype.serviceHandler = function (parsedUrl) {
                    var requestObject = {};
                    var route = this.getRoute(parsedUrl.path_without_query);
                    parsedUrl = this.addRouteData(parsedUrl);
                    if (route.service && typeof route.service === "string") {
                        requestObject.url = route.service;
                        requestObject.params = parsedUrl.route_params;
                    }
                    else if (route.service !== false) {
                        requestObject.url = parsedUrl.url;
                    }
                    var xhr = window.XMLHttpRequest ? new XMLHttpRequest() : new ActiveXObject("Microsoft.XMLHTTP");
                    xhr.open('GET', requestObject.url);
                    xhr.onreadystatechange = function () {
                        if (xhr.readyState > 3 && xhr.status == 200) {
                            var response = xhr.responseText;
                            if (parsedUrl.url === this._lastCalledRoute.url) {
                                this.routeUpdate(parsedUrl, response);
                            }
                        }
                    }.bind(this);
                    xhr.setRequestHeader('Content-Type', 'application/x-www-form-urlencoded');
                    xhr.send(requestObject.params);
                    return xhr;
                };
                Routar.prototype.nextProcess = function (service) {
                    if (!this._supported)
                        return;
                    if (!service)
                        this.routeUpdate(this._lastCalledRoute);
                    else
                        this._lastCalledRoute = service.request;
                    if (this._lastExecutedRoute.url === this._lastCalledRoute.url) {
                        this.routeUpdate(this._lastCalledRoute);
                        return;
                    }
                    var route = this.getRoute(this._lastCalledRoute.path_without_query);
                    switch (this._status) {
                        case 'onBeforeUpdate':
                            if (route.service !== false)
                                this.serviceHandler(this._lastCalledRoute);
                            else if (route.update)
                                this.routeUpdate(this._lastCalledRoute);
                            break;
                        case 'onUpdate':
                            break;
                    }
                    this._status = '';
                };
                ;
                Routar.prototype.parseUrl = function (url) {
                    var parser = document.createElement('a');
                    parser.href = url;
                    var path = url.replace(parser.protocol + '//' + parser.host + '/', '');
                    var route = /^[^?]*/i.exec(path);
                    var query = path.replace(route, '');
                    var parsedUrl = {
                        url: url,
                        path: path,
                        path_without_query: route[0],
                        query_string: query,
                        query: {},
                        route_params: {}
                    };
                    var regx = new RegExp("(?:\\?|\\&)((?:[^=]+)\\=(?:[^&]+))", "g");
                    var arr;
                    while ((arr = regx.exec(path)) !== null) {
                        var beforeEqual = arr[1].slice(0, +arr[1].indexOf('=')), afterEqual = arr[1].slice(+arr[1].indexOf('=') + 1);
                        parsedUrl['query'][beforeEqual] = afterEqual;
                    }
                    return parsedUrl;
                };
                Routar.prototype.start = function () {
                    if (!this._supported)
                        return;
                    this.bindLinks();
                    window.onpopstate = this.onPopState.bind(this);
                    var parsedUrl = this.parseUrl(document.location.href);
                    parsedUrl = this.addRouteData(parsedUrl);
                    this._prevExecutedRoute = parsedUrl;
                    this._lastExecutedRoute = parsedUrl;
                    this._lastCalledRoute = parsedUrl;
                    this._isStart = true;
                    this.routeHandler(parsedUrl);
                    console.info('ROUTAR: start');
                };
                ;
                Routar.prototype.bindLinks = function () {
                    var links = document.querySelectorAll('a');
                    for (var i = 0; i < links.length; i++) {
                        if (!links[i].getAttribute('data-routar-exclude')) {
                            links[i].removeEventListener('click', this.onClickListener);
                            links[i].addEventListener('click', this.onClickListener);
                        }
                    }
                };
                Routar.prototype.stop = function () {
                    if (!this._supported)
                        return;
                    var links = document.querySelectorAll('a');
                    for (var i = 0; i < links.length; i++) {
                        links[i].removeEventListener('click', this.onClickListener);
                    }
                    window.onpopstate = null;
                    this._isStart = false;
                    console.info('ROUTAR: stop');
                };
                ;
                Routar.prototype.navigate = function (route) {
                    if (!this._supported)
                        return;
                    var link = document.createElement('a');
                    link.href = route;
                    var requestedRoute = this.parseUrl(route);
                    if (this.getRoute(requestedRoute.path_without_query)) {
                        this.routeHandler(requestedRoute);
                    }
                    else
                        console.info('ROUTAR: route is not found -> ' + requestedRoute.path);
                };
                ;
                Routar.prototype.add = function (routes) {
                    if (!this._supported)
                        return;
                    var routesRegx = this.createRegExp(routes);
                    this._routes = ObjectUtils_1.default.extends(this._routes, routesRegx);
                    console.info('ROUTAR: ' + Object.keys(routes) + ' has added');
                    return this._routes;
                };
                ;
                Routar.prototype.remove = function (route) {
                    if (!this._supported)
                        return;
                    if (this._routes[route]) {
                        delete this._routes[route];
                        console.info('ROUTAR: ' + route + '\' has removed');
                    }
                    return this._routes;
                };
                ;
                Routar.prototype.clear = function () {
                    if (!this._supported)
                        return;
                    this._routes = {};
                    console.info('ROUTAR: routes have cleared');
                };
                ;
                Routar.prototype.getPreviousRequest = function () {
                    if (!this._supported)
                        return;
                    return this._lastExecutedRoute;
                };
                ;
                Routar.getInstance = function () {
                    return Routar._instance;
                };
                __decorate([
                    Autobind_7.autobind,
                    __metadata("design:type", Function),
                    __metadata("design:paramtypes", [Object]),
                    __metadata("design:returntype", void 0)
                ], Routar.prototype, "onClickListener", null);
                return Routar;
            }());
            exports_31("default", Routar);
        }
    };
});
System.register("src/ts/vendors/frontools/src/data/Cache", [], function (exports_32, context_32) {
    "use strict";
    var Cache;
    var __moduleName = context_32 && context_32.id;
    return {
        setters: [],
        execute: function () {
            Cache = (function () {
                function Cache() {
                }
                Cache.set = function (key, value, force, persistent) {
                    if (force === void 0) { force = false; }
                    if (persistent === void 0) { persistent = false; }
                    if (key === undefined) {
                        throw new Error('The key is not defined.');
                    }
                    else if (value === undefined) {
                        throw new Error('The value is not defined.');
                    }
                    else if (Cache.data[key] === undefined || force === true) {
                        Cache.data[key] = value;
                        if (persistent && Cache.isStorageAvailable()) {
                            var cache = window.localStorage.getItem('___cache');
                            if (cache && cache != '') {
                                cache = JSON.parse(cache);
                            }
                            else {
                                cache = {};
                            }
                            cache[key] = value;
                            window.localStorage.setItem('___cache', JSON.stringify(cache));
                        }
                    }
                    else {
                        throw new Error('The key "' + key + '" is defined. Use "force" parameter to replace it.');
                    }
                };
                ;
                Cache.get = function (key, defaultValue) {
                    if (defaultValue === void 0) { defaultValue = undefined; }
                    if (key === undefined) {
                        throw new Error('The key is not defined.');
                    }
                    else if (Cache.data[key] !== undefined) {
                        return Cache.data[key];
                    }
                    else {
                        if (defaultValue !== undefined) {
                            Cache.set(key, defaultValue);
                            return defaultValue;
                        }
                        else {
                            return false;
                        }
                    }
                };
                ;
                Cache.remove = function (key) {
                    if (key === undefined) {
                        throw new Error('The key is not defined.');
                    }
                    else {
                        Cache.data[key] = null;
                        delete Cache.data[key];
                        if (Cache.isStorageAvailable()) {
                            var cache = window.localStorage.getItem('___cache');
                            if (cache && cache != '') {
                                cache = JSON.parse(cache);
                                cache[key] = null;
                                delete cache[key];
                                window.localStorage.setItem('___cache', JSON.stringify(cache));
                            }
                        }
                        console.info('CACHE: DEL -> ', key);
                    }
                };
                ;
                Cache.clear = function (contains) {
                    for (var i in Cache.data) {
                        if (contains && i.indexOf(contains) != -1) {
                            Cache.remove(i);
                        }
                        else if (!contains) {
                            Cache.remove(i);
                        }
                    }
                    if (contains)
                        console.info('CACHE: CLEANED VALUES CONTAINS -> ' + contains);
                    else
                        console.info('CACHE: CLEANED');
                };
                ;
                Cache.isStorageAvailable = function () {
                    try {
                        var test = '__test__';
                        window.localStorage.setItem(test, test);
                        window.localStorage.removeItem(test);
                        return true;
                    }
                    catch (e) {
                        return false;
                    }
                };
                ;
                Cache.persistent = Cache.isStorageAvailable() && window.localStorage.getItem('___cache');
                Cache.data = {};
                return Cache;
            }());
            exports_32("default", Cache);
            if (Cache.isStorageAvailable()) {
                var cache = Cache.persistent != '' ? JSON.parse(Cache.persistent) : {};
                for (var i in cache) {
                    Cache.data[i] = cache[i];
                }
            }
        }
    };
});
System.register("src/ts/vendors/frontools/src/decorators/Deprecated", [], function (exports_33, context_33) {
    "use strict";
    var __moduleName = context_33 && context_33.id;
    function deprecated() {
        var args = [];
        for (var _i = 0; _i < arguments.length; _i++) {
            args[_i] = arguments[_i];
        }
        var alternative;
        function log(target, propertyKey, descriptor) {
            var original = descriptor && descriptor.value;
            if (typeof original !== 'function' || original == undefined)
                return;
            var message = '"{name}" is deprecated.', message = message.replace('{name}', propertyKey);
            if (alternative)
                message += ' Use "' + alternative + '" instead.';
            descriptor.value = function () {
                console.warn(message);
                return original.apply(target, arguments);
            };
            return descriptor;
        }
        if (typeof args[0] == 'string' || args[0] == undefined) {
            alternative = args[0];
            return log;
        }
        else
            log.apply(this, args);
    }
    exports_33("deprecated", deprecated);
    return {
        setters: [],
        execute: function () {
        }
    };
});
System.register("src/ts/vendors/frontools/src/utils/StringUtils", ["src/ts/vendors/frontools/src/decorators/Deprecated"], function (exports_34, context_34) {
    "use strict";
    var Deprecated_1, StringUtils;
    var __moduleName = context_34 && context_34.id;
    return {
        setters: [
            function (Deprecated_1_1) {
                Deprecated_1 = Deprecated_1_1;
            }
        ],
        execute: function () {
            StringUtils = (function () {
                function StringUtils() {
                }
                StringUtils.createGUID = function () {
                    var time = new Date().getTime();
                    function s4() {
                        return Math.floor((1 + Math.random() * time) * 0x10000).toString(16).substring(1);
                    }
                    return s4().substr(0, 10);
                };
                ;
                StringUtils.toSlug = function (str) {
                    str = str.toLowerCase();
                    str = StringUtils.normalize(str);
                    return str.toString()
                        .replace(/\s+/g, '-')
                        .replace(/&/g, '-')
                        .replace(/[^\w\-]+/g, '-')
                        .replace(/\-\-+/g, '-')
                        .replace(/^-+/, '')
                        .replace(/-+$/, '');
                };
                ;
                StringUtils.toPlural = function (str, count) {
                    var index = count > 1 ? 1 : 0;
                    count = String(count);
                    str = str.replace(/\[count\]/, count);
                    str = str.replace(/{(.[^}]*)}/g, function (wholematch, match) {
                        var values = match.split('|');
                        return values[index] || '';
                    });
                    return str;
                };
                ;
                StringUtils.convertAccent = function (str) {
                    return StringUtils.normalize(str);
                };
                ;
                StringUtils.normalize = function (str) {
                    var letterMap = {
                        'a': 'ãàáäâå', 'A': 'ÃÀÁÄÂÅ',
                        'c': 'ç', 'C': 'Ç',
                        'e': 'ẽèéëê', 'E': 'ẼÈÉËÊ',
                        'g': 'ǵ', 'G': 'Ǵ',
                        'h': 'ḧ', 'H': 'Ḧ',
                        'i': 'ìíïî', 'I': 'ÌÍÏÎ',
                        'm': 'ḿ', 'M': 'Ḿ',
                        'n': 'ñńǹ', 'N': 'ÑŃǸ',
                        'o': 'øðõòóöô', 'O': 'ØÐÕÒÓÖÔ',
                        'p': 'ṕþ', 'P': 'ṔÞ',
                        'r': 'ŕ', 'R': 'Ŕ',
                        's': 'śß', 'S': 'Ś',
                        'u': 'ùúûǘ', 'U': 'ÙÚÛǗ',
                        'w': 'ẃ', 'W': 'Ẃ',
                        'y': 'ýÿ', 'Y': 'ÝŸ',
                        'x': 'ẍ', 'X': 'Ẍ',
                        'z': 'ź', 'Z': 'Ź',
                        'ae': 'æ', 'AE': 'Æ',
                        'oe': 'œ', 'OE': 'Œ'
                    };
                    for (var letter in letterMap) {
                        var group = letterMap[letter];
                        group = group.length > 1 ? '[' + group + ']' : group;
                        str = str.replace(new RegExp(group, 'g'), letter);
                    }
                    return str;
                };
                ;
                StringUtils.contains = function (str, needle) {
                    return (needle === undefined) ? false : str.indexOf(needle) !== -1;
                };
                ;
                StringUtils.hasText = function (str) {
                    return !!str.length;
                };
                ;
                StringUtils.stripTags = function (str) {
                    return str.replace(/<\/?[^>]+>/igm, '');
                };
                ;
                StringUtils.trim = function (str) {
                    return str.replace(/^\s+|\s+$/g, '');
                };
                ;
                StringUtils.wordCount = function (str) {
                    return str.match(/\b\w+\b/g).length;
                };
                ;
                StringUtils.charCount = function (str) {
                    return str.length;
                };
                ;
                StringUtils.capitalizeFirst = function (str) {
                    return str.charAt(0).toUpperCase() + str.slice(1);
                };
                ;
                StringUtils.truncate = function (str, count, useWordBoundary) {
                    if (useWordBoundary === void 0) { useWordBoundary = false; }
                    var toLong = str.length > count, s = toLong ? str.substr(0, count - 1) : str;
                    s = useWordBoundary && toLong ? s.substr(0, s.lastIndexOf(' ')) : s;
                    return toLong ? s + '&hellip;' : s;
                };
                ;
                __decorate([
                    Deprecated_1.deprecated('convertAccent is deprecated -> please use StringUtils.normalize()'),
                    __metadata("design:type", Function),
                    __metadata("design:paramtypes", [String]),
                    __metadata("design:returntype", String)
                ], StringUtils, "convertAccent", null);
                return StringUtils;
            }());
            exports_34("default", StringUtils);
        }
    };
});
System.register("src/ts/vendors/frontools/src/data/FormValidation", ["src/ts/vendors/frontools/src/utils/StringUtils", "src/ts/vendors/frontools/src/abstract/AbstractDispatcher", "src/ts/vendors/frontools/src/utils/VanillaUtils"], function (exports_35, context_35) {
    "use strict";
    var StringUtils_1, AbstractDispatcher_8, VanillaUtils_3, FormValidation, FormElement, FormElementType, FormErrorType, FormEventType;
    var __moduleName = context_35 && context_35.id;
    return {
        setters: [
            function (StringUtils_1_1) {
                StringUtils_1 = StringUtils_1_1;
            },
            function (AbstractDispatcher_8_1) {
                AbstractDispatcher_8 = AbstractDispatcher_8_1;
            },
            function (VanillaUtils_3_1) {
                VanillaUtils_3 = VanillaUtils_3_1;
            }
        ],
        execute: function () {
            FormValidation = (function (_super) {
                __extends(FormValidation, _super);
                function FormValidation(form) {
                    var _this = _super.call(this) || this;
                    _this._fields = [];
                    _this._requires = [];
                    _this._radiogroups = {};
                    _this._checkboxgroups = {};
                    _this._el = form;
                    _this._el.setAttribute('novalidate', 'true');
                    _this._el.onsubmit = _this.onValidateHandler.bind(_this);
                    if (!_this._el.hasfix) {
                        var input = document.createElement('input');
                        input.type = 'hidden';
                        input.name = '_iefix';
                        _this._el.appendChild(input);
                        _this._el.hasfix = true;
                    }
                    for (var i = 0; i < _this._el.elements.length; i++) {
                        var element = _this._el.elements[i];
                        switch (element.nodeName) {
                            case FormElement.INPUT:
                            case FormElement.TEXTAREA:
                            case FormElement.SELECT:
                                switch (element.type) {
                                    case FormElementType.CHECKBOX:
                                    case FormElementType.RADIO:
                                        VanillaUtils_3.default.addEvent(element, 'change', _this.onFieldChange.bind(_this));
                                        break;
                                    default:
                                        VanillaUtils_3.default.addEvent(element, 'input', _this.onFieldChange.bind(_this));
                                }
                                if (element.nodeName == FormElement.TEXTAREA) {
                                    VanillaUtils_3.default.addEvent(element, 'input', _this.onFieldChange.bind(_this));
                                }
                                if (element.nodeName == FormElement.SELECT) {
                                    VanillaUtils_3.default.addEvent(element, 'change', _this.onFieldChange.bind(_this));
                                }
                                if (element.type != "hidden")
                                    _this._fields.push(element);
                                break;
                        }
                        if (element.getAttribute('data-val-required')) {
                            _this._requires.push(element);
                        }
                    }
                    return _this;
                }
                FormValidation.prototype.executeValidation = function (field) {
                    var validation;
                    if (field.nodeName == FormElement.INPUT) {
                        if (field.type != FormElementType.PASSWORD && field.type != FormElementType.FILE) {
                            field.value = StringUtils_1.default.trim(field.value);
                        }
                        switch (field.type) {
                            case FormElementType.NUMBER:
                                validation = this.validateText(field);
                                if (validation === true)
                                    validation = this.validateNumber(field);
                                break;
                            case FormElementType.EMAIL:
                                validation = this.validateText(field);
                                if (validation === true)
                                    validation = this.validateEmail(field);
                                break;
                            case FormElementType.CHECKBOX:
                                validation = this.validateCheckbox(field);
                                break;
                            case FormElementType.RADIO:
                                validation = this.validateRadio(field);
                                break;
                            default:
                                validation = this.validateText(field);
                        }
                    }
                    else if (field.nodeName == FormElement.SELECT) {
                        validation = this.validateSelect(field);
                    }
                    else if (field.nodeName == FormElement.TEXTAREA) {
                        validation = this.validateText(field);
                    }
                    return validation;
                };
                FormValidation.prototype.validateText = function (field) {
                    var value = field.value;
                    var minlength = field.minLength;
                    var maxlength = field.maxLength;
                    var length = value.length;
                    if (this._requires.indexOf(field) != -1 && length <= 0)
                        return { error: FormErrorType.MANDATORY };
                    if (length > 0) {
                        if (maxlength != -1 && length > maxlength)
                            return { error: FormErrorType.MAXLENGTH };
                        else if (minlength != -1 && length < minlength)
                            return { error: FormErrorType.MINLENGTH };
                        var custom = field.getAttribute('data-custom');
                        if (custom && custom != '') {
                            var patt = new RegExp(custom);
                            if (patt.test(value) == false)
                                return { error: FormErrorType.CUSTOM };
                            else
                                return true;
                        }
                    }
                    return true;
                };
                FormValidation.prototype.validateNumber = function (field) {
                    var value = Number(field.value);
                    var max = field.max;
                    var min = field.min;
                    if (max != '' && value > Number(max))
                        return { error: FormErrorType.MAX };
                    else if (min != '' && value < Number(min))
                        return { error: FormErrorType.MIN };
                    return true;
                };
                FormValidation.prototype.validateEmail = function (field) {
                    var value = field.value;
                    if (value.indexOf('@') == -1)
                        return { error: FormErrorType.EMAIL };
                    else
                        return true;
                };
                FormValidation.prototype.validateCheckbox = function (field) {
                    this._checkboxgroups = {};
                    for (var i in this._fields) {
                        var element = this._fields[i];
                        if (element.type == FormElementType.CHECKBOX) {
                            var name = element.getAttribute('data-group');
                            if (name) {
                                if (!this._checkboxgroups[name]) {
                                    this._checkboxgroups[name] = { required: false, checked: false, fields: [] };
                                }
                                this._checkboxgroups[name].fields.push(element);
                                if (this._requires.indexOf(element) != -1) {
                                    this._checkboxgroups[name].required = true;
                                }
                                if (element.checked == true)
                                    this._checkboxgroups[name].checked = true;
                            }
                        }
                    }
                    var currentGroup = field.getAttribute('data-group');
                    if (currentGroup && (this._checkboxgroups[currentGroup].required && !this._checkboxgroups[currentGroup].checked)) {
                        return { error: FormErrorType.MANDATORY };
                    }
                    return true;
                };
                FormValidation.prototype.validateRadio = function (field) {
                    this._radiogroups = {};
                    for (var i in this._fields) {
                        var element = this._fields[i];
                        if (element.type == FormElementType.RADIO) {
                            var name = element.name;
                            if (name) {
                                if (!this._radiogroups[name]) {
                                    this._radiogroups[name] = { required: false, checked: false, fields: [] };
                                }
                                this._radiogroups[name].fields.push(element);
                                if (this._requires.indexOf(element) != -1) {
                                    this._radiogroups[name].required = true;
                                }
                                if (element.checked == true)
                                    this._radiogroups[name].checked = true;
                            }
                        }
                    }
                    if (this._radiogroups[field.name].required && !this._radiogroups[field.name].checked) {
                        return { error: FormErrorType.MANDATORY };
                    }
                    return true;
                };
                FormValidation.prototype.validateSelect = function (field) {
                    if (this._requires.indexOf(field) != -1 && field.value == '')
                        return { error: FormErrorType.MANDATORY };
                    return true;
                };
                FormValidation.prototype.onFieldChange = function (e) {
                    var element = e.currentTarget;
                    if (element.type == FormElementType.RADIO) {
                        var group = this._radiogroups[element.name];
                        if (group) {
                            for (var i = 0; i < group.fields.length; i++) {
                                this.removeClassType(group.fields[i], 'error');
                            }
                        }
                    }
                    else {
                        this.removeClassType(element, 'error');
                    }
                    this.removeParentClassType(element, 'error');
                };
                FormValidation.prototype.onValidateHandler = function (e) {
                    var errors = this.validate();
                    if (errors.length > 0)
                        e.preventDefault();
                    else
                        this.dispatch({ type: FormEventType.SEND, submit: e });
                };
                FormValidation.prototype.removeClassType = function (el, type) {
                    var classes = el.className.split(" ").filter(function (c) {
                        return c.lastIndexOf(type, 0) !== 0;
                    });
                    el.className = classes.join(' ').trim();
                };
                FormValidation.prototype.removeParentClassType = function (el, type) {
                    var parent = el.parentNode;
                    while (parent.nodeName != 'FORM') {
                        this.removeClassType(parent, type);
                        parent = parent.parentNode;
                    }
                };
                FormValidation.prototype.validate = function () {
                    var isValid = true;
                    var fields = [];
                    for (var i = 0; i < this._fields.length; i++) {
                        var field = this._fields[i];
                        this.removeClassType(field, 'error');
                        this.removeParentClassType(field, 'error');
                        var result = this.executeValidation(field);
                        if (result !== true) {
                            isValid = false;
                            VanillaUtils_3.default.addClass(field, 'error');
                            VanillaUtils_3.default.addClass(field, 'error_' + result.error);
                            var parent = field.parentNode;
                            while (parent && parent.nodeName != 'FORM') {
                                console.log(parent, "add class");
                                VanillaUtils_3.default.addClass(parent, 'error');
                                VanillaUtils_3.default.addClass(parent, 'error_' + result.error);
                                parent = parent.parentNode;
                            }
                            result.fieldname = field.name || i;
                            fields.push(result);
                        }
                    }
                    if (fields.length > 0) {
                        this.dispatch({ type: FormEventType.ERROR, errors: fields });
                    }
                    return fields;
                };
                FormValidation.prototype.clear = function (force_hidden) {
                    if (force_hidden === void 0) { force_hidden = false; }
                    for (var i = 0; i < this._fields.length; i++) {
                        var field = this._fields[i];
                        switch (field.type) {
                            case FormElementType.RADIO:
                            case FormElementType.CHECKBOX:
                                if (field.checked)
                                    field.checked = false;
                                break;
                            case FormElementType.SELECT_ONE:
                            case FormElementType.SELECT_MULTIPLE:
                                field.selectedIndex = -1;
                                break;
                            case FormElementType.HIDDEN:
                                if (force_hidden)
                                    field.value = '';
                                break;
                            default:
                                field.value = '';
                        }
                    }
                    this._radiogroups = {};
                    this._checkboxgroups = {};
                };
                FormValidation.prototype.getElementByName = function (name) {
                    for (var i = 0; i < this._el.elements.length; i++) {
                        var element = this._el.elements[i];
                        if (element.name == name) {
                            return element;
                        }
                    }
                    return false;
                };
                Object.defineProperty(FormValidation.prototype, "isValid", {
                    get: function () {
                        for (var i = 0; i < this._fields.length; i++) {
                            var field = this._fields[i];
                            var result = this.executeValidation(field);
                            if (result !== true)
                                return false;
                        }
                        return true;
                    },
                    enumerable: true,
                    configurable: true
                });
                Object.defineProperty(FormValidation.prototype, "element", {
                    get: function () {
                        return this._el;
                    },
                    enumerable: true,
                    configurable: true
                });
                Object.defineProperty(FormValidation.prototype, "fields", {
                    get: function () {
                        return this._fields;
                    },
                    enumerable: true,
                    configurable: true
                });
                Object.defineProperty(FormValidation.prototype, "requiresFields", {
                    get: function () {
                        return this._requires;
                    },
                    enumerable: true,
                    configurable: true
                });
                FormValidation.isEqual = function (field1, field2) {
                    var value1 = field1.value;
                    var value2 = field2.value;
                    if (value1 == value2)
                        return true;
                    return false;
                };
                FormValidation.prototype.serialize = function () {
                    return FormValidation.serialize(this._el);
                };
                FormValidation.prototype.serializeToJSON = function () {
                    return FormValidation.serializeToJSON(this._el);
                };
                FormValidation.serialize = function (form) {
                    var serializedJSON = FormValidation.serializeToJSON(form), q = [], urlencode = function (str) {
                        return encodeURIComponent(str).replace(/%20/g, '+');
                    };
                    if (serializedJSON) {
                        for (var key in serializedJSON) {
                            if (typeof (serializedJSON[key]) === "string") {
                                q.push(urlencode(key) + "=" + urlencode(serializedJSON[key]));
                            }
                            else {
                                for (var k = 0; k < serializedJSON[key].length; k++) {
                                    q.push(urlencode(key) + "=" + urlencode(serializedJSON[key][k]));
                                }
                            }
                        }
                        return q.join("&");
                    }
                    return;
                };
                FormValidation.serializeToJSON = function (form) {
                    var serializedJSON = {}, singleValueElements = {
                        'INPUT': [
                            'text',
                            'email',
                            'number',
                            'hidden',
                            'password',
                            'file'
                        ],
                        'TEXTAREA': [
                            '*'
                        ],
                        'SELECT': [
                            'select-one'
                        ]
                    }, multipleValuesElements = {
                        'INPUT': [
                            'radio',
                            'checkbox'
                        ],
                        'SELECT': [
                            'select-multiple'
                        ]
                    };
                    for (var i = form.elements.length - 1; i >= 0; i--) {
                        var element = form.elements[i];
                        var nodeName = element.nodeName;
                        if (element.getAttribute('data-sc-field-name')) {
                            var name = element.getAttribute('data-sc-field-name').split(' ').join('').toLowerCase();
                        }
                        else {
                            var name = element.name;
                        }
                        var type = element.type;
                        if (nodeName in singleValueElements &&
                            (singleValueElements[nodeName].indexOf(type) > -1 || singleValueElements[nodeName][0] === '*')) {
                            var value = StringUtils_1.default.trim(element.value);
                            if (value != '')
                                serializedJSON[name] = value;
                        }
                        else if (nodeName in multipleValuesElements &&
                            (multipleValuesElements[nodeName].indexOf(type) > -1 || multipleValuesElements[nodeName][0] === '*')) {
                            var selectedElements = [];
                            var checkAttribute = 'checked';
                            var elementsList = [];
                            if (nodeName === FormElement.INPUT) {
                                elementsList = document.getElementsByName(name);
                                checkAttribute = 'checked';
                            }
                            else {
                                elementsList = element.options;
                                checkAttribute = "selected";
                            }
                            for (var j = 0; j < elementsList.length; j++) {
                                if (elementsList[j][checkAttribute])
                                    selectedElements.push(elementsList[j].value);
                            }
                            if (selectedElements.length == 1) {
                                serializedJSON[name] = selectedElements[0];
                            }
                            else if (selectedElements.length > 1) {
                                serializedJSON[name] = selectedElements;
                            }
                        }
                    }
                    return serializedJSON;
                };
                ;
                return FormValidation;
            }(AbstractDispatcher_8.default));
            exports_35("default", FormValidation);
            FormElement = (function () {
                function FormElement() {
                }
                FormElement.INPUT = 'INPUT';
                FormElement.TEXTAREA = 'TEXTAREA';
                FormElement.SELECT = 'SELECT';
                return FormElement;
            }());
            exports_35("FormElement", FormElement);
            FormElementType = (function () {
                function FormElementType() {
                }
                FormElementType.TEXT = 'text';
                FormElementType.NUMBER = 'number';
                FormElementType.PASSWORD = 'password';
                FormElementType.EMAIL = 'email';
                FormElementType.TEL = 'tel';
                FormElementType.CHECKBOX = 'checkbox';
                FormElementType.RADIO = 'radio';
                FormElementType.SELECT_ONE = 'select-one';
                FormElementType.SELECT_MULTIPLE = 'select-multiple';
                FormElementType.FILE = 'file';
                FormElementType.HIDDEN = 'hidden';
                return FormElementType;
            }());
            exports_35("FormElementType", FormElementType);
            FormErrorType = (function () {
                function FormErrorType() {
                }
                FormErrorType.MANDATORY = 'required';
                FormErrorType.EMAIL = 'email';
                FormErrorType.MAX = 'max';
                FormErrorType.MIN = 'min';
                FormErrorType.STEP = 'step';
                FormErrorType.MAXLENGTH = 'maxlength';
                FormErrorType.MINLENGTH = 'minlength';
                FormErrorType.CUSTOM = 'custom';
                return FormErrorType;
            }());
            exports_35("FormErrorType", FormErrorType);
            FormEventType = (function () {
                function FormEventType() {
                }
                FormEventType.ERROR = 'error';
                FormEventType.SEND = 'send';
                return FormEventType;
            }());
            exports_35("FormEventType", FormEventType);
        }
    };
});
System.register("src/ts/vendors/frontools/src/data/Preloader", ["src/ts/vendors/frontools/src/abstract/AbstractDispatcher"], function (exports_36, context_36) {
    "use strict";
    var AbstractDispatcher_9, Preloader;
    var __moduleName = context_36 && context_36.id;
    return {
        setters: [
            function (AbstractDispatcher_9_1) {
                AbstractDispatcher_9 = AbstractDispatcher_9_1;
            }
        ],
        execute: function () {
            Preloader = (function (_super) {
                __extends(Preloader, _super);
                function Preloader(files) {
                    var _this = _super.call(this) || this;
                    _this._filesList = [];
                    _this._index = 0;
                    _this._isComplete = false;
                    _this._filesList = files;
                    return _this;
                }
                Preloader.prototype.getFiletype = function (filesrc) {
                    if (filesrc.match(/\.(jpg|jpeg|png|gif|svg)$/))
                        return Preloader.FILETYPE.IMAGE;
                    else
                        return Preloader.FILETYPE.UNKNOWN;
                };
                Preloader.prototype.completed = function () {
                    this._isComplete = true;
                    this.dispatch({ type: Preloader.COMPLETED });
                };
                Preloader.prototype.loaded = function (file) {
                    this.dispatch({
                        type: Preloader.LOADED,
                        path: this._filesList[this._index],
                        file: file
                    });
                    this.loadNext();
                };
                Preloader.prototype.error = function (e) {
                    this.dispatch(e);
                    if (console && console.warn)
                        console.warn('Preloader error -> ' + e.path, e);
                    this.loadNext();
                };
                Preloader.prototype.load = function (filesrc) {
                    var file;
                    var type = this.getFiletype(filesrc);
                    this.dispatch({ type: Preloader.STARTED, path: filesrc });
                    if (type == Preloader.FILETYPE.IMAGE) {
                        file = new Image();
                        file.src = filesrc;
                        file.onload = function () {
                            this.loaded(file);
                        }.bind(this);
                        file.onerror = function (e) {
                            this.error({
                                type: Preloader.ERROR,
                                path: filesrc,
                                error: { message: 'File not found', type: 2 }
                            });
                        }.bind(this);
                    }
                    else if (type == Preloader.FILETYPE.UNKNOWN) {
                        this.error({
                            type: Preloader.ERROR,
                            path: filesrc,
                            error: { message: 'File is not supported.', type: 1 }
                        });
                    }
                };
                Preloader.prototype.loadNext = function () {
                    this._index++;
                    if (this._index >= this._filesList.length)
                        this.completed();
                    else
                        this.load(this._filesList[this._index]);
                };
                Preloader.prototype.start = function () {
                    if (this._filesList.length == 0) {
                        if (console && console.warn)
                            console.warn('no files to load');
                        return;
                    }
                    if (!this._isComplete)
                        this.load(this._filesList[0]);
                    else if (console && console.log)
                        console.log('loading is completed');
                };
                ;
                Preloader.FILETYPE = {
                    IMAGE: 'image',
                    UNKNOWN: 'unknown'
                };
                Preloader.COMPLETED = 'completed';
                Preloader.STARTED = 'started';
                Preloader.LOADED = 'loaded';
                Preloader.ERROR = 'error';
                return Preloader;
            }(AbstractDispatcher_9.default));
            exports_36("default", Preloader);
        }
    };
});
System.register("src/ts/vendors/frontools/src/data/Request", ["src/ts/vendors/frontools/src/abstract/AbstractDispatcher", "src/ts/vendors/frontools/src/decorators/Autobind"], function (exports_37, context_37) {
    "use strict";
    var AbstractDispatcher_10, Autobind_8, Request, RequestConfig, RequestMethod, RequestEvent;
    var __moduleName = context_37 && context_37.id;
    return {
        setters: [
            function (AbstractDispatcher_10_1) {
                AbstractDispatcher_10 = AbstractDispatcher_10_1;
            },
            function (Autobind_8_1) {
                Autobind_8 = Autobind_8_1;
            }
        ],
        execute: function () {
            Request = (function (_super) {
                __extends(Request, _super);
                function Request(config) {
                    var _this = _super.call(this) || this;
                    _this._xhr = new XMLHttpRequest();
                    _this._defaultHeaders = { 'X-Requested-With': 'XMLHttpRequest' };
                    _this._config = config;
                    if (typeof _this._config.url === 'undefined') {
                        throw new Error('Property "id" must be specified');
                    }
                    _this._xhr.onprogress = _this._onProgress;
                    _this._xhr.open(_this._config.method, _this._config.url, _this._config.async);
                    _this._config.headers = __assign({}, _this._defaultHeaders, _this._config.headers);
                    Object.keys(_this._config.headers).forEach(function (key) {
                        this._xhr.setRequestHeader(key, this._config.headers[key]);
                    }.bind(_this));
                    _this._xhr.onload = _this._onLoad;
                    _this._xhr.onerror = _this._onError;
                    _this._xhr.send(_this._config.data);
                    return _this;
                }
                ;
                Request.prototype._onProgress = function (e) {
                    if (e.lenghtComputable) {
                        var percent = e.position / e.totalSize * 100;
                        this.dispatch({ type: RequestEvent.PROGRESS, event: e, percent: percent });
                    }
                    else {
                        this.dispatch({ type: RequestEvent.PROGRESS, event: e, percent: null });
                    }
                };
                Request.prototype._onLoad = function (e) {
                    this.dispatch({ type: RequestEvent.LOAD, event: e, response: e.target.response });
                };
                ;
                Request.prototype._onError = function (e) {
                    this.dispatch({ type: RequestEvent.ERROR, event: e, status: e.target.status });
                };
                ;
                Request.prototype.abort = function () {
                    if (this._xhr.status !== 200) {
                        this._xhr.abort();
                    }
                };
                Object.defineProperty(Request.prototype, "status", {
                    get: function () {
                        return {
                            code: this._xhr.status,
                            message: this._xhr.statusText
                        };
                    },
                    enumerable: true,
                    configurable: true
                });
                __decorate([
                    Autobind_8.autobind,
                    __metadata("design:type", Function),
                    __metadata("design:paramtypes", [Object]),
                    __metadata("design:returntype", void 0)
                ], Request.prototype, "_onProgress", null);
                __decorate([
                    Autobind_8.autobind,
                    __metadata("design:type", Function),
                    __metadata("design:paramtypes", [Object]),
                    __metadata("design:returntype", void 0)
                ], Request.prototype, "_onLoad", null);
                __decorate([
                    Autobind_8.autobind,
                    __metadata("design:type", Function),
                    __metadata("design:paramtypes", [Object]),
                    __metadata("design:returntype", void 0)
                ], Request.prototype, "_onError", null);
                __decorate([
                    Autobind_8.autobind,
                    __metadata("design:type", Function),
                    __metadata("design:paramtypes", []),
                    __metadata("design:returntype", void 0)
                ], Request.prototype, "abort", null);
                return Request;
            }(AbstractDispatcher_10.default));
            exports_37("default", Request);
            RequestConfig = (function () {
                function RequestConfig() {
                    this._method = RequestMethod.GET;
                    this._data = null;
                    this._async = true;
                }
                Object.defineProperty(RequestConfig.prototype, "url", {
                    get: function () { return this._url; },
                    set: function (value) { this._url = value; },
                    enumerable: true,
                    configurable: true
                });
                Object.defineProperty(RequestConfig.prototype, "method", {
                    get: function () { return this._method; },
                    set: function (value) { this._method = value; },
                    enumerable: true,
                    configurable: true
                });
                Object.defineProperty(RequestConfig.prototype, "headers", {
                    get: function () { return this._headers; },
                    set: function (value) { this._headers = value; },
                    enumerable: true,
                    configurable: true
                });
                Object.defineProperty(RequestConfig.prototype, "data", {
                    get: function () { return this._data; },
                    set: function (value) { this._data = value; },
                    enumerable: true,
                    configurable: true
                });
                Object.defineProperty(RequestConfig.prototype, "async", {
                    get: function () { return this._async; },
                    set: function (value) { this._async = value; },
                    enumerable: true,
                    configurable: true
                });
                return RequestConfig;
            }());
            exports_37("RequestConfig", RequestConfig);
            RequestMethod = (function () {
                function RequestMethod() {
                }
                RequestMethod.GET = 'GET';
                RequestMethod.POST = 'POST';
                return RequestMethod;
            }());
            exports_37("RequestMethod", RequestMethod);
            RequestEvent = (function () {
                function RequestEvent() {
                }
                RequestEvent.PROGRESS = 'progress';
                RequestEvent.LOAD = 'load';
                RequestEvent.ERROR = 'error';
                return RequestEvent;
            }());
            exports_37("RequestEvent", RequestEvent);
        }
    };
});
System.register("src/ts/vendors/frontools/src/data/Template", ["src/ts/vendors/frontools/src/utils/StringUtils"], function (exports_38, context_38) {
    "use strict";
    var StringUtils_2, Template;
    var __moduleName = context_38 && context_38.id;
    return {
        setters: [
            function (StringUtils_2_1) {
                StringUtils_2 = StringUtils_2_1;
            }
        ],
        execute: function () {
            Template = (function () {
                function Template(template, data) {
                    this._html = '';
                    this._html = template.innerHTML ? template.innerHTML : template;
                    this._html = this._html.replace(Template.settings.escape, function (match, token) {
                        token = StringUtils_2.default.trim(token);
                        return encodeURI(data[token] === undefined ? '' : data[token]);
                    }.bind(this));
                    this._html = this._html.replace(Template.settings.interpolate, function (match, token) {
                        token = StringUtils_2.default.trim(token);
                        return data[token] === undefined ? '' : data[token];
                    }.bind(this));
                    this._html = this._html.replace(Template.settings.evaluate, function (match, token) {
                        token = StringUtils_2.default.trim(token);
                        return data[token];
                    }.bind(this));
                }
                Object.defineProperty(Template.prototype, "htmlAsString", {
                    get: function () {
                        return this._html;
                    },
                    enumerable: true,
                    configurable: true
                });
                Object.defineProperty(Template.prototype, "htmlAsDOM", {
                    get: function () {
                        var div = document.createElement('div');
                        div.innerHTML = this._html;
                        return div;
                    },
                    enumerable: true,
                    configurable: true
                });
                Template.settings = {
                    evaluate: /<%([\s\S]+?)%>/g,
                    interpolate: /<%=([\s\S]+?)%>/g,
                    escape: /<%-([\s\S]+?)%>/g
                };
                return Template;
            }());
            exports_38("default", Template);
        }
    };
});
System.register("src/ts/vendors/frontools/src/decorators/Log", [], function (exports_39, context_39) {
    "use strict";
    var __moduleName = context_39 && context_39.id;
    function log() {
        var args = [];
        for (var _i = 0; _i < arguments.length; _i++) {
            args[_i] = arguments[_i];
        }
        function method(target, key, descriptor) {
            if (descriptor === undefined)
                descriptor = Object.getOwnPropertyDescriptor(target, key);
            var original = descriptor.value;
            var metadataKey = "__log_" + key + "_parameters";
            var indices = target[metadataKey];
            function logParameters() {
                var args = [];
                for (var _i = 0; _i < arguments.length; _i++) {
                    args[_i] = arguments[_i];
                }
                console.group('arguments');
                for (var i = 0; i < args[0].length; i++) {
                    if (indices.indexOf(i) !== -1) {
                        var arg = args[0][i];
                        console.log(i + ":", arg);
                    }
                }
                console.groupEnd();
            }
            function logResult(result) {
                console.group('return');
                console.log(result);
                console.groupEnd();
            }
            descriptor.value = function () {
                var args = [];
                for (var _i = 0; _i < arguments.length; _i++) {
                    args[_i] = arguments[_i];
                }
                var result = original.apply(this, args);
                if (!indices && !result) {
                    console.log("" + key);
                }
                else {
                    console.group("" + key);
                    if (indices)
                        logParameters(args);
                    if (result)
                        logResult(result);
                    console.groupEnd();
                }
                return result;
            };
            return descriptor;
        }
        function parameter(target, key, index) {
            var metadataKey = "__log_" + key + "_parameters";
            if (Array.isArray(target[metadataKey])) {
                target[metadataKey].push(index);
            }
            else {
                target[metadataKey] = [index];
            }
        }
        function property(target, propertyKey) {
            return {
                set: function (value) {
                    console.log(propertyKey + " = " + value);
                }
            };
        }
        function instance(target) {
            function construct(constructor, args) {
                var c = function () {
                    console.log("" + target.name, args);
                    return constructor.apply(this, args);
                };
                c.prototype = constructor.prototype;
                return new c();
            }
            var f = function () {
                var args = [];
                for (var _i = 0; _i < arguments.length; _i++) {
                    args[_i] = arguments[_i];
                }
                return construct(target, args);
            };
            for (var i in target)
                f[i] = target[i];
            f.prototype = target.prototype;
            return f;
        }
        switch (args.length) {
            case 1: return instance.apply(this, args);
            case 2: return property.apply(this, args);
            case 3:
                if (args[2] === undefined) {
                    return property.apply(this, args);
                }
                else if (typeof args[2] === 'number') {
                    return parameter.apply(this, args);
                }
                return method.apply(this, args);
            default: throw new Error();
        }
    }
    exports_39("log", log);
    return {
        setters: [],
        execute: function () {
        }
    };
});
System.register("src/ts/vendors/frontools/src/decorators/Timeout", [], function (exports_40, context_40) {
    "use strict";
    var __moduleName = context_40 && context_40.id;
    function timeout(delay) {
        if (delay === void 0) { delay = 500; }
        return function (target, propertyKey, descriptor) {
            var original = descriptor && descriptor.value;
            if (typeof original !== 'function' || original == undefined)
                return;
            descriptor.value = function () {
                var args = arguments;
                setTimeout(function () {
                    original.apply(this, args);
                }.bind(this), delay);
            };
            return descriptor;
        };
    }
    exports_40("timeout", timeout);
    return {
        setters: [],
        execute: function () {
        }
    };
});
System.register("src/ts/vendors/frontools/src/utils/ArrayUtils", [], function (exports_41, context_41) {
    "use strict";
    var ArrayUtils;
    var __moduleName = context_41 && context_41.id;
    return {
        setters: [],
        execute: function () {
            ArrayUtils = (function () {
                function ArrayUtils() {
                }
                ArrayUtils.clone = function (arr) {
                    var array = [];
                    for (var i = 0, l = arr.length; i < l; ++i) {
                        array.push(arr[i]);
                    }
                    return array;
                };
                ;
                ArrayUtils.contains = function (arr, needle) {
                    return (arr.indexOf(needle) !== -1);
                };
                ;
                ArrayUtils.remove = function (needle, arr) {
                    arr = ArrayUtils.clone(arr);
                    for (var i = arr.length; i > -1; i--) {
                        if (arr[i] === needle)
                            arr.splice(i, 1);
                    }
                    return arr;
                };
                ;
                ArrayUtils.insert = function (needle, arr, position, clone) {
                    if (position === void 0) { position = 0; }
                    if (clone === void 0) { clone = false; }
                    var aA = arr.slice(0, position), aB = arr.slice(position);
                    aA.push(needle);
                    var merged = ArrayUtils.merge(aA, aB);
                    arr = clone ? merged : ArrayUtils.clone(merged);
                    return arr;
                };
                ;
                ArrayUtils.equal = function (arr1, arr2) {
                    if (arr1.length != arr2.length)
                        return false;
                    for (var i = 0, l = arr1.length; i < l; i++) {
                        if (arr1[i] !== arr2[i])
                            return false;
                    }
                    return true;
                };
                ;
                ArrayUtils.merge = function (arr1, arr2) {
                    var arr3 = arr1.concat(arr2), i = arr1.length - 1;
                    return arr3;
                };
                ;
                ArrayUtils.randomize = function (arr) {
                    for (var i = arr.length - 1; i > 0; i--) {
                        var j = Math.floor(Math.random() * (i + 1));
                        var temp = arr[i];
                        arr[i] = arr[j];
                        arr[j] = temp;
                    }
                    return arr;
                };
                ;
                return ArrayUtils;
            }());
            exports_41("default", ArrayUtils);
        }
    };
});
System.register("src/ts/vendors/frontools/src/map/HtmlMarker", ["src/ts/vendors/frontools/src/utils/ObjectUtils"], function (exports_42, context_42) {
    "use strict";
    var ObjectUtils_2, HtmlMarker;
    var __moduleName = context_42 && context_42.id;
    return {
        setters: [
            function (ObjectUtils_2_1) {
                ObjectUtils_2 = ObjectUtils_2_1;
            }
        ],
        execute: function () {
            HtmlMarker = (function () {
                function HtmlMarker(obj) {
                    this._isRendered = false;
                    this._options = {
                        anchorX: 0,
                        anchorY: 0,
                        callbacks: {},
                        template: '<div>default</div>',
                        classname: 'html-marker'
                    };
                    if (!google.maps.OverlayView) {
                        throw new Error('GoogleMap need to be "ready" to use HtmlMarker');
                    }
                    ObjectUtils_2.default.extends(this._options, obj);
                    if (!document.querySelector('#' + this._options.classname)) {
                        var head = document.head || document.getElementsByTagName('head')[0];
                        var css = '.' + this._options.classname + ' *{pointer-events: none};';
                        var div = document.createElement("div");
                        div.innerHTML = '<p>x</p><style id="' + this._options.classname + '">' + css + '</style>';
                        head.appendChild(div.childNodes[1]);
                    }
                    this._overlay = new google.maps.OverlayView();
                    if (obj.position instanceof google.maps.LatLng)
                        this._latlng = obj.position;
                    else
                        this._latlng = new google.maps.LatLng(obj.position.lat, obj.position.lng);
                    this._overlay.draw = this.draw.bind(this);
                    this._overlay.remove = this.removeOverlay.bind(this);
                    this._overlay.latlng = this._latlng;
                    this._zindex = Math.round((60 - this._latlng.lat()) * 10000);
                    if (!obj.map) {
                        throw new Error('map is not specified. HtmlMarker need to have a map. Can be set on constructor object.');
                    }
                    if (obj.map) {
                        this._map = obj.map;
                        console.log(obj.map);
                        this._overlay.setMap(obj.map);
                    }
                }
                HtmlMarker.prototype.setMarker = function () {
                    this._marker = this._overlay.div = document.createElement('div');
                    this._marker.className = this._options.classname;
                    this._marker.style.position = 'absolute';
                    this._marker.innerHTML = this._options.template;
                    var panes = this._overlay.getPanes();
                    panes.overlayImage.appendChild(this._marker);
                    for (var event in this._options.callbacks) {
                        this._marker.style.cursor = 'pointer';
                        google.maps.event.addDomListener(this._marker, event, (function (marker, event) {
                            return function (e) {
                                this._options.callbacks[event](e, this);
                            };
                        })(this._marker, event).bind(this));
                    }
                    this.setZIndex();
                    this._isRendered = true;
                    if (this._options.callbacks.ready)
                        this._options.callbacks.ready(this);
                };
                HtmlMarker.prototype.draw = function () {
                    this._marker = this._overlay.div;
                    if (!this._marker)
                        this.setMarker();
                    var point = this._overlay.getProjection().fromLatLngToDivPixel(this._latlng);
                    if (point) {
                        this._marker.style.left = (point.x - this._options.anchorX) + 'px';
                        this._marker.style.top = (point.y - this._options.anchorY) + 'px';
                    }
                };
                HtmlMarker.prototype.removeOverlay = function () {
                    if (this._marker) {
                        this._marker.parentNode.removeChild(this._marker);
                        this._isRendered = false;
                        self = null;
                    }
                };
                ;
                HtmlMarker.prototype.remove = function () {
                    if (this._overlay)
                        this._overlay.setMap(null);
                };
                ;
                HtmlMarker.prototype.getHtmlElement = function () {
                    if (this._marker)
                        return this._marker;
                    else if (!this._isRendered)
                        throw new Error('Marker is not rendered. Perhaps the marker is not ready, use it on ready callback of the marker.');
                };
                ;
                HtmlMarker.prototype.getPosition = function () {
                    return this._latlng;
                };
                ;
                HtmlMarker.prototype.getMap = function () {
                    return this._map;
                };
                ;
                HtmlMarker.prototype.setZIndex = function (value) {
                    this._marker.style.zIndex = value || this._zindex;
                };
                HtmlMarker.prototype.getZIndex = function () {
                    return this._marker.style.zIndex;
                };
                ;
                HtmlMarker.prototype.showAtTop = function () {
                    this.setZIndex(this._zindex + HtmlMarker.MAX_ZINDEX);
                };
                ;
                HtmlMarker.prototype.getAnchor = function () {
                    return { x: this._options.anchorX, y: this._options.anchorY };
                };
                ;
                HtmlMarker.prototype.isRendered = function () {
                    return this._isRendered;
                };
                ;
                HtmlMarker.prototype.get = function (key) {
                    return this[key];
                };
                ;
                HtmlMarker.prototype.set = function (key, value) {
                    this[key] = value;
                };
                HtmlMarker.MAX_ZINDEX = 60000;
                return HtmlMarker;
            }());
            exports_42("default", HtmlMarker);
        }
    };
});
System.register("src/ts/vendors/frontools/src/map/GoogleMap", ["src/ts/vendors/frontools/src/abstract/AbstractDispatcher", "src/ts/vendors/frontools/src/utils/ArrayUtils", "src/ts/vendors/frontools/src/utils/ObjectUtils", "src/ts/vendors/frontools/src/utils/UrlUtils", "src/ts/vendors/frontools/src/map/HtmlMarker"], function (exports_43, context_43) {
    "use strict";
    var AbstractDispatcher_11, ArrayUtils_1, ObjectUtils_3, UrlUtils_3, HtmlMarker_1, GoogleMap;
    var __moduleName = context_43 && context_43.id;
    return {
        setters: [
            function (AbstractDispatcher_11_1) {
                AbstractDispatcher_11 = AbstractDispatcher_11_1;
            },
            function (ArrayUtils_1_1) {
                ArrayUtils_1 = ArrayUtils_1_1;
            },
            function (ObjectUtils_3_1) {
                ObjectUtils_3 = ObjectUtils_3_1;
            },
            function (UrlUtils_3_1) {
                UrlUtils_3 = UrlUtils_3_1;
            },
            function (HtmlMarker_1_1) {
                HtmlMarker_1 = HtmlMarker_1_1;
            }
        ],
        execute: function () {
            GoogleMap = (function (_super) {
                __extends(GoogleMap, _super);
                function GoogleMap(obj) {
                    if (obj === void 0) { obj = {}; }
                    var _this = _super.call(this) || this;
                    _this._map = null;
                    _this._index = null;
                    _this._markers = {};
                    _this._directions = {};
                    _this._options = {
                        target: document.getElementById('map'),
                        libraries: null,
                        disableInfoPlaces: false,
                        mapOptions: {
                            zoom: 13,
                            center: { lat: 45.505, lng: -73.555 },
                        },
                        styles: [{}],
                        callbacks: {}
                    };
                    GoogleMap._mapCount++;
                    _this._index = GoogleMap._mapCount;
                    _this._options = ObjectUtils_3.default.extends(_this._options, obj);
                    if (!_this._options.target)
                        throw new Error('Your map target is not found in DOM');
                    if (GoogleMap.KEY === '') {
                        if (window.console)
                            console.warn('GOOGLE_MAP: Don\'t forget to set your GoogleMap.KEY');
                    }
                    window['gmapInit' + _this._index] = function (stack) {
                        GoogleMap._hasScriptReady = true;
                        this._map = new google.maps.Map(this._options.target, this._options.mapOptions);
                        this._map.setOptions({ styles: this._options.styles });
                        google.maps.visualRefresh = true;
                        this.addBaseListeners();
                        if (this._options.disableInfoPlaces === true) {
                            var set = google.maps.InfoWindow.prototype.set;
                            google.maps.InfoWindow.prototype.set = function (key, val) {
                                if (key === 'map') {
                                    if (!this.get('noSupress'))
                                        return;
                                }
                                set.apply(this, arguments);
                            };
                        }
                        if (this._options.markers) {
                            for (var i in this._options.markers) {
                                this.addMarker(this._options.markers[i]);
                            }
                        }
                        if (stack === undefined) {
                            while (GoogleMap._stackInit.length !== 0) {
                                var init = GoogleMap._stackInit[0];
                                GoogleMap._stackInit.splice(0, 1);
                                init.call(null, [true]);
                            }
                        }
                        if (this._options.callbacks.ready !== undefined) {
                            this._options.callbacks.ready();
                            this.dispatch({ type: 'ready'
                            });
                        }
                        window['gmapInit' + this._index] = undefined;
                        try {
                            delete window['gmapInit' + this._index];
                        }
                        catch (e) { }
                    }.bind(_this);
                    if (GoogleMap._hasScriptDOM === false) {
                        GoogleMap._hasScriptDOM = true;
                        var params = {
                            key: GoogleMap.KEY,
                            v: GoogleMap.VERSION,
                            callback: 'gmapInit' + _this._index,
                            language: GoogleMap.LANGUAGE,
                            region: GoogleMap.REGION
                        };
                        if (_this._options.libraries !== null) {
                            params['libraries'] = _this._options.libraries;
                        }
                        _this.addScript(params);
                    }
                    else {
                        if (GoogleMap._hasScriptReady === false)
                            GoogleMap._stackInit.push(window['gmapInit' + _this._index]);
                        else
                            window['gmapInit' + _this._index].call();
                    }
                    return _this;
                }
                ;
                GoogleMap.prototype.addScript = function (data) {
                    var sdk = UrlUtils_3.default.query('https://maps.googleapis.com/maps/api/js', data);
                    var script = document.createElement('script');
                    script.type = 'text/javascript';
                    script.src = sdk;
                    document.getElementsByTagName('body')[0].appendChild(script);
                };
                ;
                GoogleMap.prototype.addBaseListeners = function () {
                    var listeners = {
                        'idle': function () { },
                        'click': function () { },
                        'dblclick': function () { },
                        'rightclick': function (e) {
                            if (window.console && window.console.log) {
                                console.info('GOOGLE_MAP: lat:' + e.latLng.lat() + ' lng:' + e.latLng.lng());
                            }
                        },
                        'drag': function () { },
                        'dragend': function () { },
                        'dragstart': function () { },
                        'mousemove': function () { },
                        'mouseout': function () { },
                        'mouseover': function () { },
                        'resize': function () { },
                        'bounds_changed': function () { },
                        'center_changed': function () { },
                        'zoom_changed': function () { },
                    };
                    for (var func in listeners) {
                        google.maps.event.addListener(this._map, func, (function (func) {
                            return function (e) {
                                if (this._options.callbacks[func] !== undefined)
                                    this._options.callbacks[func](e);
                                listeners[func](e);
                                this.dispatch({ type: func, data: e });
                            };
                        })(func).bind(this));
                    }
                };
                ;
                GoogleMap.prototype.getDirection = function (direction) {
                    if (typeof (direction) === 'object') {
                        return direction;
                    }
                    else if (typeof direction === 'number') {
                        var cpt = 0, found = false;
                        for (var key in direction) {
                            if (direction === cpt) {
                                return this._directions[key];
                            }
                            cpt += 1;
                        }
                        if (!found) {
                            throw new Error('Direction index(' + direction + ') not found');
                        }
                    }
                    else if (direction in this._directions) {
                        return this._directions[direction];
                    }
                    else {
                        throw new Error('Direction id(' + direction + ') not found');
                    }
                };
                ;
                GoogleMap.prototype.validateMap = function () {
                    if (!this._map) {
                        throw new Error('Map is not defined. Maybe that SDK is not ready. Watch it with "ready" callback');
                    }
                    return true;
                };
                ;
                GoogleMap.prototype.toggleDirection = function (direction, show) {
                    if (show === void 0) { show = true; }
                    var parentToAttached = show ? this._map : null;
                    if (this.validateMap()) {
                        var obj = this.getDirection(direction);
                        obj.setMap(parentToAttached);
                    }
                };
                ;
                GoogleMap.prototype.toggleMarker = function (marker, show) {
                    if (show === void 0) { show = true; }
                    var parentToAttached = show ? this._map : null;
                    if (this.validateMap()) {
                        var obj = this.getMarker(marker);
                        obj.setMap(parentToAttached);
                    }
                };
                ;
                GoogleMap.prototype.addDirection = function (options) {
                    if (this.validateMap()) {
                        var travelModes = [
                            google.maps.TravelMode.DRIVING,
                            google.maps.TravelMode.BICYCLING,
                            google.maps.TravelMode.TRANSIT,
                            google.maps.TravelMode.WALKING
                        ], units = [
                            google.maps.UnitSystem.METRIC,
                            google.maps.UnitSystem.IMPERIAL
                        ];
                        if (!(options.origin instanceof google.maps.Marker) || !(options.destination instanceof google.maps.Marker))
                            throw new Error('origin and destination must be instance of Marker google.maps.Marker');
                        if (!(ArrayUtils_1.default.contains(travelModes, options.travelMode)))
                            options.travelMode = google.maps.TravelMode.DRIVING;
                        if (!(ArrayUtils_1.default.contains(units, options.unit)))
                            options.unit = google.maps.UnitSystem.METRIC;
                        var uniqId = 'direction-' +
                            options.origin.get('id') + '-' +
                            options.destination.get('id') + '-' +
                            options.unit + '-' +
                            options.travelMode;
                        if (uniqId in this._directions) {
                            this.showDirection(this._directions[uniqId]);
                            this.showMarker(options.origin);
                            this.showMarker(options.destination);
                            this.fit();
                        }
                        else {
                            var directionsService = new google.maps.DirectionsService(), directionsDisplay = new google.maps.DirectionsRenderer({
                                suppressMarkers: true,
                                polylineOptions: {
                                    strokeColor: '#2eab89',
                                    strokeOpacity: 0.8,
                                    strokeWeight: 7
                                }
                            }), request = {
                                origin: options.origin.getPosition(),
                                destination: options.destination.getPosition(),
                                travelMode: options.travelMode,
                                unitSystem: options.unit
                            };
                            directionsDisplay.setMap(this._map);
                            this._directions[uniqId] = directionsDisplay;
                            directionsService.route(request, function (result, status) {
                                if (status == google.maps.DirectionsStatus.OK) {
                                    this.showMarker(options.origin);
                                    this.showMarker(options.destination);
                                    directionsDisplay.setDirections(result);
                                    this.fit();
                                }
                            });
                        }
                    }
                };
                ;
                GoogleMap.prototype.addMarker = function (options, addToList) {
                    if (this.validateMap()) {
                        if (options.map === undefined)
                            options.map = this._map;
                        if (options.position === undefined)
                            throw new Error('Marker don\'t have position.');
                        if (!(options.position instanceof google.maps.LatLng)) {
                            if (options.position.lat === undefined)
                                throw new Error('Position object need to have "lat" property.');
                            if (options.position.lng === undefined)
                                throw new Error('Position object need to have "lng" property.');
                            options.position = new google.maps.LatLng(options.position.lat, options.position.lng);
                        }
                        if (options.icon)
                            options.icon = GoogleMap.createIcon(options.icon);
                        var marker = null;
                        if (options.template) {
                            marker = new HtmlMarker_1.default(options);
                        }
                        else if (options.icon) {
                            marker = new google.maps.Marker(options);
                        }
                        if (!marker)
                            throw new Error('The marker need to have "icon" or "template" property');
                        if (!marker.get('id')) {
                            var lat = options.position.lat().toString(), lng = options.position.lng().toString();
                            marker.set('id', 'marker-' + lat.replace(/[^\d]/i, '') + lng.replace(/[^\d]/i, ''));
                        }
                        addToList = addToList != undefined ? addToList : true;
                        if (addToList)
                            this._markers[marker.get('id')] = marker;
                        if (marker instanceof google.maps.Marker) {
                            google.maps.event.addListener(marker, 'click', (function (marker) {
                                return function () {
                                    if (marker.callback && typeof (marker.callback) === 'function')
                                        marker.callback(marker);
                                };
                            })(marker));
                        }
                        return marker;
                    }
                };
                ;
                GoogleMap.createIcon = function (icon) {
                    if (icon !== undefined && !(icon instanceof google.maps.MarkerImage)) {
                        var _icon = icon;
                        var url = null;
                        if (_icon.img === undefined)
                            throw new Error('Icon image is undefined. Property "img" must be defined.');
                        else if (_icon.retina === undefined)
                            url = _icon.img;
                        else
                            url = window.devicePixelRatio > 1 ? _icon.retina : _icon.img;
                        var size = null;
                        if (_icon.width !== undefined && _icon.height !== undefined) {
                            size = new google.maps.Size(_icon.width, _icon.height);
                        }
                        var origin = null;
                        if (_icon.originx !== undefined && _icon.originy !== undefined) {
                            origin = new google.maps.Point(_icon.originx, _icon.originy);
                        }
                        var anchor = null;
                        if (_icon.anchorx !== undefined && _icon.anchory !== undefined) {
                            anchor = new google.maps.Point(_icon.anchorx, _icon.anchory);
                        }
                        var scaledSize = null;
                        if (_icon.scaledSizeWidth !== undefined && _icon.scaledSizeHeight !== undefined) {
                            scaledSize = new google.maps.Size(_icon.scaledSizeWidth, _icon.scaledSizeHeight);
                        }
                        return new google.maps.MarkerImage(url, size, origin, anchor, scaledSize);
                    }
                    return icon;
                };
                ;
                GoogleMap.prototype.deleteDirections = function () {
                    for (var key in this._directions) {
                        this.hideDirections();
                    }
                    this._directions = {};
                };
                ;
                GoogleMap.prototype.deleteMarkers = function () {
                    for (var key in this._markers) {
                        this.hideMarker(this._markers[key]);
                    }
                    this._markers = {};
                };
                ;
                GoogleMap.prototype.fit = function () {
                    if (this.validateMap()) {
                        var bounds = new google.maps.LatLngBounds();
                        for (var id in this._markers) {
                            if (this._markers[id].getMap() !== null) {
                                bounds.extend(this._markers[id].getPosition());
                            }
                        }
                        this._map.fitBounds(bounds);
                    }
                };
                ;
                GoogleMap.prototype.getDirections = function () {
                    return this._directions;
                };
                ;
                GoogleMap.prototype.getIndex = function () {
                    return this._index;
                };
                ;
                GoogleMap.prototype.getMap = function () {
                    return this._map;
                };
                ;
                GoogleMap.prototype.getMarkers = function () {
                    return this._markers;
                };
                ;
                GoogleMap.prototype.getMarker = function (marker) {
                    if (marker instanceof google.maps.Marker) {
                        return marker;
                    }
                    else if (typeof marker === 'number') {
                        var cpt = 0, found = false;
                        for (var key in this._markers) {
                            if (marker === cpt) {
                                return this._markers[key];
                            }
                            cpt += 1;
                        }
                        if (!found) {
                            throw new Error('Marker index(' + marker + ') not found');
                        }
                    }
                    else if (marker in this._markers) {
                        return this._markers[marker];
                    }
                    else {
                        throw new Error('Marker id(' + marker + ') not found');
                    }
                };
                ;
                GoogleMap.prototype.hideDirection = function (direction) {
                    this.toggleDirection(direction);
                };
                ;
                GoogleMap.prototype.hideDirections = function () {
                    for (var key in this._directions) {
                        this.hideDirection(this._directions[key]);
                    }
                };
                ;
                GoogleMap.prototype.hideMarker = function (marker) {
                    this.toggleMarker(marker);
                };
                ;
                GoogleMap.prototype.hideMarkers = function () {
                    for (var key in this._markers) {
                        this.hideMarker(this._markers[key]);
                    }
                };
                ;
                GoogleMap.prototype.removeDirection = function (direction) {
                    var obj = this.getDirection(direction);
                    this.toggleDirection(obj);
                    delete this._directions[obj.get('id')];
                };
                ;
                GoogleMap.prototype.removeMarker = function (marker) {
                    var obj = this.getMarker(marker);
                    this.toggleMarker(obj);
                    delete this._markers[obj.get('id')];
                };
                ;
                GoogleMap.prototype.showDirection = function (direction) {
                    this.toggleDirection(direction, true);
                };
                ;
                GoogleMap.prototype.showMarker = function (marker) {
                    this.toggleMarker(marker, true);
                };
                ;
                GoogleMap.prototype.showMarkers = function () {
                    for (var key in this._markers) {
                        this.showMarker(this._markers[key]);
                    }
                };
                ;
                GoogleMap.prototype.offsetCenter = function (latlng, offsetx, offsety, panTo) {
                    var scale = Math.pow(2, this._map.getZoom());
                    var nw = new google.maps.LatLng(this._map.getBounds().getNorthEast().lat(), this._map.getBounds().getSouthWest().lng());
                    var worldCoordinateCenter = this._map.getProjection().fromLatLngToPoint(latlng);
                    var pixelOffset = new google.maps.Point((offsetx / scale) || 0, (offsety / scale) || 0);
                    var worldCoordinateNewCenter = new google.maps.Point(worldCoordinateCenter.x - pixelOffset.x, worldCoordinateCenter.y + pixelOffset.y);
                    var newCenter = this._map.getProjection().fromPointToLatLng(worldCoordinateNewCenter);
                    if (panTo)
                        this._map.panTo(newCenter);
                    else
                        this._map.setCenter(newCenter);
                };
                ;
                GoogleMap.prototype.setMapOptions = function (options) {
                    this._map.setOptions(options);
                };
                ;
                GoogleMap.prototype.getOptions = function () {
                    return this._options;
                };
                ;
                GoogleMap.prototype.resize = function () {
                    if (this._map) {
                        google.maps.event.trigger(this._map, 'resize');
                    }
                    else {
                        throw new Error('Map is not defined. Maybe that SDK is not ready. Watch it with "ready" callback');
                    }
                };
                ;
                GoogleMap.JSONtoURL = function (json) {
                    var _items = [], separator = '|', _parameters = '';
                    var isColor = function (value) { return /^#[0-9a-f]{6}$/i.test(value.toString()); };
                    var toColor = function (value) { return '0x' + value.slice(1); };
                    for (var i = 0; i < json.length; i++) {
                        var item = json[i], hasFeature = item.hasOwnProperty('featureType'), hasElement = item.hasOwnProperty('elementType'), stylers = item.stylers, target = '', style = '';
                        if (!hasFeature && !hasElement) {
                            target = 'feature:all';
                        }
                        else {
                            if (hasFeature) {
                                target = 'feature:' + item.featureType;
                            }
                            if (hasElement) {
                                target = target ? target + separator : '';
                                target += 'element:' + item.elementType;
                            }
                        }
                        for (var s = 0; s < stylers.length; s++) {
                            var styleItem = stylers[s], key = Object.keys(styleItem)[0];
                            style = style ? style + separator : '';
                            style += key + ':' + (isColor(styleItem[key]) ? toColor(styleItem[key]) : styleItem[key]);
                        }
                        _items.push(target + separator + style);
                    }
                    return '&style=' + _items.join('&style=');
                };
                ;
                GoogleMap.KEY = '';
                GoogleMap.VERSION = '';
                GoogleMap.LANGUAGE = 'fr';
                GoogleMap.REGION = 'CA';
                GoogleMap._hasScriptDOM = false;
                GoogleMap._hasScriptReady = false;
                GoogleMap._mapCount = 0;
                GoogleMap._stackInit = [];
                return GoogleMap;
            }(AbstractDispatcher_11.default));
            exports_43("default", GoogleMap);
        }
    };
});
System.register("src/ts/vendors/frontools/src/map/HtmlOverlay", ["src/ts/vendors/frontools/src/utils/ObjectUtils"], function (exports_44, context_44) {
    "use strict";
    var ObjectUtils_4, HtmlOverlay;
    var __moduleName = context_44 && context_44.id;
    return {
        setters: [
            function (ObjectUtils_4_1) {
                ObjectUtils_4 = ObjectUtils_4_1;
            }
        ],
        execute: function () {
            HtmlOverlay = (function () {
                function HtmlOverlay(obj) {
                    this._isRendered = false;
                    this._options = {
                        anchorX: 0,
                        anchorY: 0,
                        template: '<div>default</div>',
                        close: '<div>x</div>',
                        classname: 'html-overlay'
                    };
                    if (!google.maps.OverlayView) {
                        throw new Error('GoogleMap need to be "ready" to use HtmlOverlay');
                    }
                    ObjectUtils_4.default.extends(this._options, obj);
                    this._overlay = new google.maps.OverlayView();
                    if (obj.position instanceof google.maps.LatLng)
                        this._latlng = obj.position;
                    else
                        this._latlng = new google.maps.LatLng(obj.position.lat, obj.position.lng);
                    this._overlay.draw = this.draw.bind(this);
                    this._overlay.remove = this.removeOverlay.bind(this);
                    this._overlay.latlng = this._latlng;
                    if (!obj.map) {
                        throw new Error('map is not specified. HtmlOverlay need to have a map. Can be set on constructor object.');
                    }
                    else
                        this._overlay.setMap(obj.map);
                }
                HtmlOverlay.prototype.setHolder = function () {
                    this._holder = this._overlay.div = document.createElement('div');
                    this._holder.className = this._options.classname;
                    this._holder.style.position = 'absolute';
                    this._holder.innerHTML = this._options.template;
                    var panes = this._overlay.getPanes();
                    panes.overlayImage.appendChild(this._holder);
                    var close = document.createElement('div');
                    close.className = 'button-close';
                    close.innerHTML = this._options.close;
                    close.addEventListener('click', function () {
                        if (this._options.callbacks.close)
                            this._options.callbacks.close();
                        this.parentElement.removeChild(this);
                    }.bind(this));
                    this._holder.appendChild(close);
                    this.getHtmlElement().style.zIndex = String(HtmlOverlay.zIndex);
                    HtmlOverlay.zIndex++;
                    this._isRendered = true;
                    if (this._options.callbacks.ready)
                        this._options.callbacks.ready(this);
                };
                HtmlOverlay.prototype.draw = function () {
                    this._holder = this._overlay.div;
                    if (!this._holder)
                        this.setHolder();
                    var point = this._overlay.getProjection().fromLatLngToDivPixel(this._latlng);
                    if (point) {
                        this._holder.style.left = (point.x - this._options.anchorX) + 'px';
                        this._holder.style.top = (point.y - this._options.anchorY) + 'px';
                    }
                };
                HtmlOverlay.prototype.removeOverlay = function () {
                    if (this._holder) {
                        this._holder.parentNode.removeChild(this._holder);
                        this._isRendered = false;
                    }
                };
                ;
                HtmlOverlay.prototype.remove = function () {
                    if (this._overlay)
                        this._overlay.setMap(null);
                };
                ;
                HtmlOverlay.prototype.getHtmlElement = function () {
                    if (this._holder)
                        return this._holder;
                    else if (!this._isRendered)
                        throw new Error('Overlay is not rendered. Perhaps it\'s not added to a map or overlay is not ready');
                };
                ;
                HtmlOverlay.prototype.getPosition = function () {
                    return this._latlng;
                };
                ;
                HtmlOverlay.prototype.getAnchor = function () {
                    return { x: this._options.anchorX, y: this._options.anchorY };
                };
                ;
                HtmlOverlay.prototype.get = function (key) {
                    return this[key];
                };
                ;
                HtmlOverlay.prototype.set = function (key, value) {
                    this[key] = value;
                };
                HtmlOverlay.zIndex = 4000000;
                return HtmlOverlay;
            }());
            exports_44("default", HtmlOverlay);
        }
    };
});
System.register("src/ts/vendors/frontools/src/medias/image/LazyLoadManager", ["src/ts/vendors/frontools/src/abstract/AbstractDispatcher", "src/ts/vendors/frontools/src/utils/ScrollUtils", "src/ts/vendors/frontools/src/core/Context", "src/ts/vendors/frontools/src/decorators/Autobind"], function (exports_45, context_45) {
    "use strict";
    var AbstractDispatcher_12, ScrollUtils_3, Context_5, Autobind_9, LazyLoadManager, LazyLoadManagerOptions;
    var __moduleName = context_45 && context_45.id;
    return {
        setters: [
            function (AbstractDispatcher_12_1) {
                AbstractDispatcher_12 = AbstractDispatcher_12_1;
            },
            function (ScrollUtils_3_1) {
                ScrollUtils_3 = ScrollUtils_3_1;
            },
            function (Context_5_1) {
                Context_5 = Context_5_1;
            },
            function (Autobind_9_1) {
                Autobind_9 = Autobind_9_1;
            }
        ],
        execute: function () {
            LazyLoadManager = (function (_super) {
                __extends(LazyLoadManager, _super);
                function LazyLoadManager(_options) {
                    if (_options === void 0) { _options = new LazyLoadManagerOptions(); }
                    var _this = _super.call(this) || this;
                    _this._options = _options;
                    _this._registered = [];
                    _this._elItems = document.querySelectorAll(_this._options.selector);
                    _this.parseElements();
                    return _this;
                }
                ;
                LazyLoadManager.prototype.parseElements = function (elItems) {
                    var _this = this;
                    if (elItems === void 0) { elItems = this._elItems; }
                    var _loop_3 = function (i, l) {
                        var ele = elItems[i];
                        var image = (ele.nodeName == "IMG") ? ele : ele.querySelector('img');
                        this_2._onImageInit(image);
                        ScrollUtils_3.default.registerElement(ele, {
                            offset: Context_5.default.getInstance().viewport.height - this_2._options.offset,
                            after: function (el) {
                                image.onload = _this._onImageLoad;
                                _this._load(image);
                                ScrollUtils_3.default.removeRegistredElement(ele);
                                var indexOf = _this._registered.indexOf(ele);
                                _this._registered.splice(indexOf, 1);
                            }
                        });
                        this_2._registered.push(ele);
                        ScrollUtils_3.default.triggerEventOf(window);
                    };
                    var this_2 = this;
                    for (var i = 0, l = elItems.length; i < l; i++) {
                        _loop_3(i, l);
                    }
                };
                LazyLoadManager.prototype._load = function (image) {
                    var source = image.getAttribute(this._options.sourceAttribute), sourceSet = image.getAttribute(this._options.sourceSetAttribute);
                    if (source)
                        image.src = source;
                    if (sourceSet)
                        image.srcset = sourceSet;
                };
                LazyLoadManager.prototype._onImageLoad = function (e) {
                    this.dispatch({ type: LazyLoadManager.IMAGE_LOADED, image: e.currentTarget });
                    this._onImageAnimateIn(e.currentTarget);
                };
                LazyLoadManager.prototype._onImageAnimateIn = function (image) {
                    TweenMax.to(image, 1, { opacity: 1, ease: Expo.easeOut });
                };
                LazyLoadManager.prototype.destroy = function () {
                    for (var i = 0, l = this._registered.length; i < l; i++) {
                        ScrollUtils_3.default.removeRegistredElement(this._registered[i]);
                    }
                };
                LazyLoadManager.prototype._onImageInit = function (image) {
                    TweenMax.set(image, { opacity: 0 });
                };
                Object.defineProperty(LazyLoadManager.prototype, "options", {
                    get: function () {
                        return this._options;
                    },
                    enumerable: true,
                    configurable: true
                });
                LazyLoadManager.IMAGE_LOADED = 'image_loaded';
                __decorate([
                    Autobind_9.autobind,
                    __metadata("design:type", Function),
                    __metadata("design:paramtypes", [Object]),
                    __metadata("design:returntype", void 0)
                ], LazyLoadManager.prototype, "_onImageLoad", null);
                return LazyLoadManager;
            }(AbstractDispatcher_12.default));
            exports_45("default", LazyLoadManager);
            LazyLoadManagerOptions = (function () {
                function LazyLoadManagerOptions() {
                    this._selector = '[data-lazy-load]';
                    this._sourceAttribute = 'data-src';
                    this._sourceSetAttribute = 'data-src-set';
                    this._offset = 200;
                }
                Object.defineProperty(LazyLoadManagerOptions.prototype, "selector", {
                    get: function () {
                        return this._selector;
                    },
                    set: function (selector) {
                        this._selector = selector;
                    },
                    enumerable: true,
                    configurable: true
                });
                Object.defineProperty(LazyLoadManagerOptions.prototype, "sourceAttribute", {
                    get: function () {
                        return this._sourceAttribute;
                    },
                    set: function (sourceAttribute) {
                        this._sourceAttribute = sourceAttribute;
                    },
                    enumerable: true,
                    configurable: true
                });
                Object.defineProperty(LazyLoadManagerOptions.prototype, "offset", {
                    get: function () {
                        return this._offset;
                    },
                    set: function (offset) {
                        this._offset = offset;
                    },
                    enumerable: true,
                    configurable: true
                });
                Object.defineProperty(LazyLoadManagerOptions.prototype, "sourceSetAttribute", {
                    get: function () {
                        return this._sourceSetAttribute;
                    },
                    enumerable: true,
                    configurable: true
                });
                return LazyLoadManagerOptions;
            }());
            exports_45("LazyLoadManagerOptions", LazyLoadManagerOptions);
        }
    };
});
System.register("src/ts/vendors/frontools/src/medias/video/VideoPlayer", ["src/ts/vendors/frontools/src/abstract/AbstractDispatcher", "src/ts/vendors/frontools/src/decorators/Autobind"], function (exports_46, context_46) {
    "use strict";
    var AbstractDispatcher_13, Autobind_10, VideoPlayer;
    var __moduleName = context_46 && context_46.id;
    return {
        setters: [
            function (AbstractDispatcher_13_1) {
                AbstractDispatcher_13 = AbstractDispatcher_13_1;
            },
            function (Autobind_10_1) {
                Autobind_10 = Autobind_10_1;
            }
        ],
        execute: function () {
            VideoPlayer = (function (_super) {
                __extends(VideoPlayer, _super);
                function VideoPlayer(obj) {
                    if (obj === void 0) { obj = {}; }
                    var _this = _super.call(this) || this;
                    _this._video = null;
                    _this._options = {
                        target: null,
                        width: 500,
                        height: 500,
                        fullsize: false,
                        forceratio: false,
                        volume: 1,
                        position: 'static',
                        backgroundColor: '#000000',
                        video: {
                            attributes: {
                                autoplay: true,
                                loop: true
                            },
                            sources: [{
                                    src: '',
                                    type: ''
                                }]
                        },
                        callbacks: {
                            onended: function (e) { },
                            onplay: function (e) { },
                            onpause: function (e) { },
                            onloadedmetadata: function (e) { }
                        }
                    };
                    _this.isPlaying = function () {
                        if (this._video) {
                            return !this._video.paused;
                        }
                        else {
                            return false;
                        }
                    };
                    if (!_this._isHtml5Video())
                        return _this;
                    for (var i in obj)
                        _this._options[i] = obj[i];
                    if (_this._options.target === undefined)
                        throw new Error('Property "target" must be specified');
                    var mp4 = _this._options.target.getAttribute('data-mp4'), webm = _this._options.target.getAttribute('data-webm'), ogv = _this._options.target.getAttribute('data-ogv'), ogg = _this._options.target.getAttribute('data-ogg');
                    if (_this._options.video.sources === undefined)
                        _this._options.video.sources = [];
                    if (mp4 !== null)
                        _this._options.video.sources.push({ src: mp4, type: 'video/mp4' });
                    if (webm !== null)
                        _this._options.video.sources.push({ src: webm, type: 'video/webm' });
                    if (ogv !== null)
                        _this._options.video.sources.push({ src: ogv, type: 'video/ogv' });
                    if (ogg !== null)
                        _this._options.video.sources.push({ src: ogg, type: 'video/ogv' });
                    if (_this._options.video.sources.length === 0)
                        throw new Error('No sources are found.');
                    _this._video = document.createElement('video');
                    for (var j in _this._options.video.sources) {
                        var source = document.createElement('source');
                        source.src = _this._options.video.sources[j].src;
                        source.type = _this._options.video.sources[j].type;
                        _this._video.appendChild(source);
                    }
                    _this._video.style.backgroundColor = _this._options.backgroundColor;
                    _this._video.volume = _this._options.volume;
                    for (var attribute in _this._options.video.attributes) {
                        var value = _this._options.video.attributes[attribute];
                        if (VideoPlayer.propsDict.indexOf(attribute) >= 0) {
                            _this._video[attribute] = value;
                        }
                        else {
                            value = value === true ? '' : value;
                            _this._video.setAttribute(attribute, value);
                        }
                    }
                    if (_this._options.fullsize === true || _this._options.forceratio === true) {
                        if (!_this._options.position)
                            _this._video.style.position = 'absolute';
                        else
                            _this._video.style.position = _this._options.position;
                    }
                    if (_this._options.fullsize === true) {
                        document.body.onresize = _this._resize;
                        _this._resize();
                    }
                    else {
                        _this._video.width = _this._options.width;
                        _this._video.height = _this._options.height;
                    }
                    _this._options.target.innerHTML = '';
                    _this._options.target.appendChild(_this._video);
                    _this._addBaseListeners();
                    return _this;
                }
                VideoPlayer.prototype._isHtml5Video = function () {
                    return !!document.createElement('video').canPlayType;
                };
                ;
                VideoPlayer.prototype._addBaseListeners = function () {
                    var listeners = {
                        'onloadedmetadata': function (e) {
                            if (this._video.videoWidth && this._video.videoHeight) {
                                if (this._options.forceratio) {
                                    this.setSize(this._options.width, this._options.height);
                                }
                                if (this._options.fullsize === true) {
                                    this._resize();
                                }
                            }
                        }.bind(this)
                    };
                    for (var func in listeners) {
                        if (this._video.addEventListener) {
                            this._video.addEventListener(func.substr(2), function (e) {
                                var name = 'on' + e.type;
                                listeners[name](e);
                                if (typeof this._options.callbacks[name] !== 'undefined')
                                    this._options.callbacks[name](e);
                            }.bind(this));
                        }
                        else {
                            this._video[func] = (function (base, method) {
                                return function (e) {
                                    base(e);
                                    if (typeof this._options.callbacks[method] !== 'undefined')
                                        this._options.callbacks[method](e);
                                };
                            })(listeners[func], func);
                        }
                    }
                    for (var callback in this._options.callbacks) {
                        if (this._video.addEventListener) {
                            this._video.addEventListener(callback.substr(2), this._options.callbacks[callback]);
                        }
                        else {
                            this._video[callback] = this._options.callbacks[callback];
                        }
                    }
                };
                VideoPlayer.prototype.setSize = function (refWidth, refHeight) {
                    var width = 0;
                    var height = 0;
                    var nWidth = this._video.videoWidth || refWidth;
                    var nHeight = this._video.videoHeight || refHeight;
                    var scaleX = refWidth / nWidth;
                    var scaleY = refHeight / nHeight;
                    var scale = (scaleX > scaleY) ? scaleX : scaleY;
                    width = Math.ceil(nWidth * scale);
                    height = Math.ceil(nHeight * scale);
                    var offsetX = Math.ceil((refWidth - width) / 2);
                    var offsetY = Math.ceil((refHeight - height) / 2);
                    this._video.width = width;
                    this._video.height = height;
                    this._video.style.left = offsetX + 'px';
                    this._video.style.top = offsetY + 'px';
                };
                ;
                VideoPlayer.prototype._resize = function () {
                    var width = Math.max(document.documentElement["clientWidth"], document.body["scrollWidth"], document.documentElement["scrollWidth"], document.body["offsetWidth"], document.documentElement["offsetWidth"]);
                    this.setSize(width, window.innerHeight);
                };
                VideoPlayer.prototype.play = function (position) {
                    if (this._video) {
                        if (position !== undefined)
                            this.seek(position);
                        this._video.play();
                    }
                };
                ;
                VideoPlayer.prototype.resume = function () {
                    if (this._video)
                        this._video.play();
                };
                ;
                VideoPlayer.prototype.pause = function () {
                    if (this._video)
                        this._video.pause();
                };
                ;
                VideoPlayer.prototype.seek = function (position) {
                    if (this._video)
                        this._video.currentTime = position;
                };
                ;
                VideoPlayer.prototype.getVideo = function () {
                    return this._video;
                };
                ;
                VideoPlayer.prototype.getOptions = function () {
                    return this._options;
                };
                ;
                VideoPlayer.propsDict = [
                    'src', 'muted', 'poster', 'preload', 'autoplay', 'loop', 'audio', 'controls', 'width', 'height'
                ];
                __decorate([
                    Autobind_10.autobind,
                    __metadata("design:type", Function),
                    __metadata("design:paramtypes", []),
                    __metadata("design:returntype", void 0)
                ], VideoPlayer.prototype, "_resize", null);
                return VideoPlayer;
            }(AbstractDispatcher_13.default));
            exports_46("default", VideoPlayer);
        }
    };
});
System.register("src/ts/vendors/frontools/src/medias/video/Vimeo", ["src/ts/vendors/frontools/src/abstract/AbstractDispatcher", "src/ts/vendors/frontools/src/decorators/Autobind"], function (exports_47, context_47) {
    "use strict";
    var AbstractDispatcher_14, Autobind_11, Vimeo;
    var __moduleName = context_47 && context_47.id;
    return {
        setters: [
            function (AbstractDispatcher_14_1) {
                AbstractDispatcher_14 = AbstractDispatcher_14_1;
            },
            function (Autobind_11_1) {
                Autobind_11 = Autobind_11_1;
            }
        ],
        execute: function () {
            Vimeo = (function (_super) {
                __extends(Vimeo, _super);
                function Vimeo(obj) {
                    if (obj === void 0) { obj = {}; }
                    var _this = _super.call(this) || this;
                    _this._index = null;
                    _this._player = null;
                    _this._url = null;
                    _this._options = {
                        api: true,
                        id: null,
                        target: null,
                        width: 200,
                        height: 200,
                        namespace: 'player',
                        aspect_ratio: '16:9',
                        responsive: false,
                        responsiveWrapperClass: 'player_wrapper',
                        player: {},
                        iframe: {},
                        callback: {
                            onReady: function () { },
                            onProgress: function (data) { },
                            onPause: function (time) { },
                            onComplete: function () { }
                        }
                    };
                    Vimeo._playerCount++;
                    _this._index = Vimeo._playerCount;
                    for (var i in obj) {
                        _this._options[i] = obj[i];
                    }
                    if (_this._options.id === null)
                        throw new Error('Property "id" must be specified');
                    if (_this._options.target === null)
                        throw new Error('Property "target" must be specified');
                    _this._createFrame();
                    if (_this._options.api)
                        _this._createAPI();
                    return _this;
                }
                ;
                Vimeo.prototype._createFrame = function () {
                    var src = '//player.vimeo.com/video/' + this._options.id + '?api=' + this._options.api + '&player_id=' + (this._options.namespace + this._index);
                    for (var j in this._options.player) {
                        src += '&' + j + '=' + this._options.player[j];
                    }
                    this._player = document.createElement('iframe');
                    this._player.setAttribute('src', src);
                    this._player.setAttribute('id', this._options.namespace + this._index);
                    this._player.setAttribute('frameborder', String(0));
                    this._player.setAttribute('webkitallowfullscreen', '');
                    this._player.setAttribute('mozallowfullscreen', '');
                    this._player.setAttribute('allowfullscreen', '');
                    for (var k in this._options.iframe) {
                        this._player.setAttribute(k, this._options.iframe[k]);
                    }
                    if (this._options.responsive) {
                        this._createResponsiveWrapper();
                    }
                    else {
                        this._player.setAttribute('width', String(this._options.width));
                        this._player.setAttribute('height', String(this._options.height));
                        this._options.target.appendChild(this._player);
                    }
                    this._url = window.location.protocol + src.split('?')[0];
                };
                ;
                Vimeo.prototype._createResponsiveWrapper = function () {
                    if (!this._options.aspect_ratio) {
                        throw new Error('You need an aspect ratio for the responsive player to work');
                    }
                    var ratioParts = this._options.aspect_ratio.split(':'), ratio = Number(ratioParts[1]) / Number(ratioParts[0]);
                    var wrapper = document.createElement('div');
                    wrapper.setAttribute('class', this._options.responsiveWrapperClass);
                    wrapper.setAttribute('style', 'padding-top: ' + ratio * 100 + '%;');
                    wrapper.appendChild(this._player);
                    this._options.target.appendChild(wrapper);
                };
                Vimeo.prototype._onMessage = function (e) {
                    var data = JSON.parse(e.data);
                    switch (data.event) {
                        case 'ready':
                            if (!Vimeo._hasBindedReady) {
                                Vimeo._hasBindedReady = true;
                                this._api('addEventListener', 'pause');
                                this._api('addEventListener', 'finish');
                                this._api('addEventListener', 'playProgress');
                            }
                            console.info('VIMEO: Ready', this._options.id);
                            if (this._options.callback.onReady)
                                this._options.callback.onReady();
                            break;
                        case 'playProgress':
                            if (this._options.callback.onProgress)
                                this._options.callback.onProgress(data.data);
                            break;
                        case 'pause':
                            if (this._options.callback.onPause)
                                this._options.callback.onPause();
                            break;
                        case 'finish':
                            if (this._options.callback.onComplete)
                                this._options.callback.onComplete();
                            break;
                    }
                };
                ;
                Vimeo.prototype._createAPI = function () {
                    if (!Vimeo._hasBinded) {
                        window.addEventListener('message', this._onMessage, false);
                        Vimeo._hasBinded = true;
                    }
                };
                ;
                Vimeo.prototype._api = function (action, value) {
                    var data = {
                        method: action
                    };
                    if (value != undefined) {
                        data['value'] = value;
                    }
                    var message = JSON.stringify(data);
                    this._player.contentWindow.postMessage(data, this._url);
                };
                ;
                Vimeo.prototype.getOptions = function () {
                    return this._options;
                };
                ;
                Vimeo.prototype.getPlayer = function () {
                    return this._player;
                };
                ;
                Vimeo.prototype.getIndex = function () {
                    return this._index;
                };
                ;
                Vimeo._playerCount = 0;
                Vimeo._hasBinded = false;
                Vimeo._hasBindedReady = false;
                __decorate([
                    Autobind_11.autobind,
                    __metadata("design:type", Function),
                    __metadata("design:paramtypes", [Object]),
                    __metadata("design:returntype", void 0)
                ], Vimeo.prototype, "_onMessage", null);
                return Vimeo;
            }(AbstractDispatcher_14.default));
            exports_47("default", Vimeo);
        }
    };
});
System.register("src/ts/vendors/frontools/src/medias/video/Youtube", ["src/ts/vendors/frontools/src/abstract/AbstractDispatcher", "src/ts/vendors/frontools/src/decorators/Autobind"], function (exports_48, context_48) {
    "use strict";
    var AbstractDispatcher_15, Autobind_12, Youtube, YoutubeObject;
    var __moduleName = context_48 && context_48.id;
    return {
        setters: [
            function (AbstractDispatcher_15_1) {
                AbstractDispatcher_15 = AbstractDispatcher_15_1;
            },
            function (Autobind_12_1) {
                Autobind_12 = Autobind_12_1;
            }
        ],
        execute: function () {
            Youtube = (function (_super) {
                __extends(Youtube, _super);
                function Youtube(obj) {
                    var _this = _super.call(this) || this;
                    _this._isPlaying = false;
                    _this._progressTimer = null;
                    _this._lastPercentRange = 0;
                    _this._checkerInterval = null;
                    _this._prevCurrentTime = 0;
                    _this._hasSeek = false;
                    _this._tracked = {};
                    _this._index = 0;
                    _this._player = null;
                    _this._settings = obj;
                    if (_this._settings.id === undefined)
                        throw new Error('Property "id" must be specified');
                    if (_this._settings.target === undefined)
                        throw new Error('Property "target" must be specified');
                    _this.createPlayer();
                    return _this;
                }
                Youtube.prototype.createPlayer = function () {
                    this._isPlaying = false;
                    this._settings.target.innerHTML = '';
                    clearTimeout(this._progressTimer);
                    Youtube.playerCount++;
                    this._index = Youtube.playerCount;
                    if (!document.getElementById('youtube-js-style') && this._settings.responsive) {
                        var head = document.head || document.getElementsByTagName('head')[0];
                        var css = '.youtube_video_wrapper{width:100%;position:relative;padding:0;}.youtube_video_wrapper iframe,.youtube_video_wrapper object,.youtube_video_wrapper embed {position:absolute;top:0;left:0;width:100%;height:100%;}';
                        var div = document.createElement("div");
                        div.innerHTML = '<p>x</p><style id="youtube-js-style">' + css + '</style>';
                        head.appendChild(div.childNodes[1]);
                    }
                    if (this._settings.playerSettings.enablejsapi)
                        this.createAPI();
                    else
                        this.createFrame();
                };
                Youtube.prototype.createFrame = function () {
                    var src = 'https://www.youtube.com/embed/' + this._settings.id;
                    var no = 0;
                    for (var j in this._settings.playerSettings) {
                        src += (no === 0 ? '?' : '&') + j + '=' + this._settings.playerSettings[j];
                        no++;
                    }
                    this._player = document.createElement('iframe');
                    this._player.setAttribute('src', src);
                    this._player.setAttribute('id', this._settings.namespace + this._index);
                    for (var k in this._settings.iframeSettings) {
                        this._player.setAttribute(k, this._settings.iframeSettings[k]);
                    }
                    if (this._settings.responsive) {
                        this.createWrapperFor(this._player);
                    }
                    else {
                        this._player.setAttribute('width', String(this._settings.width));
                        this._player.setAttribute('height', String(this._settings.height));
                        this._settings.target.appendChild(this._player);
                    }
                };
                Youtube.prototype.createWrapperFor = function (child) {
                    if (!this._settings.aspectRatio)
                        this._settings.aspectRatio = this._settings.height / this._settings.width;
                    else {
                        if (typeof this._settings.aspectRatio == 'string') {
                            var ratio = this._settings.aspectRatio.split(':');
                            this._settings.aspectRatio = ratio[1] / ratio[0];
                        }
                    }
                    var wrapper = document.createElement('div');
                    wrapper.setAttribute('class', "youtube_video_wrapper");
                    wrapper.setAttribute('style', 'padding-top: ' + this._settings.aspectRatio * 100 + '%;');
                    wrapper.appendChild(child);
                    this._settings.target.appendChild(wrapper);
                };
                Youtube.prototype.createAPI = function () {
                    this._APIEvents = {
                        onReady: this.handleApiReady,
                        onError: this.handleApiError,
                        onStateChange: this.handleApiStateChange
                    };
                    window['youtubeInit' + this._index] = this.handleApi;
                    if (window.onYoutubeIframeAPIReady) {
                        Youtube.hasAPIDefined = true;
                        window.onYouTubeIframeAPIReady = this.handleIframeApiReady;
                    }
                    else if (!Youtube.hasAPIDefined) {
                        this._checkerInterval = 0;
                        this._checkerInterval = setInterval(this.checkAPI, 500);
                    }
                    if (Youtube.hasScriptDOM === false) {
                        Youtube.hasScriptDOM = true;
                        var script = document.createElement('script');
                        script.type = 'text/javascript';
                        script.src = 'https://www.youtube.com/iframe_api';
                        document.getElementsByTagName('body')[0].appendChild(script);
                    }
                    else {
                        if (Youtube.hasScriptReady === false)
                            Youtube.playerStack.push(window['youtubeInit' + this._index]);
                        else
                            window['youtubeInit' + this._index].call();
                    }
                };
                Youtube.prototype.handleApi = function (stack) {
                    clearInterval(this._checkerInterval);
                    var iframe = document.createElement('div');
                    iframe.setAttribute('id', this._settings.namespace + this._index);
                    if (this._settings.responsive)
                        this.createWrapperFor(iframe);
                    else
                        this._settings.target.appendChild(iframe);
                    this._player = new YT.Player(this._settings.namespace + this._index, {
                        width: this._settings.width,
                        height: this._settings.height,
                        videoId: this._settings.id,
                        playerVars: this._settings.playerSettings,
                        events: this._APIEvents
                    });
                    if (stack === undefined) {
                        while (Youtube.playerStack.length !== 0) {
                            var init = Youtube.playerStack[0];
                            Youtube.playerStack.splice(0, 1);
                            init.call(null, [true]);
                        }
                    }
                    window['youtubeInit' + this._index] = undefined;
                    try {
                        delete window['youtubeInit' + this._index];
                    }
                    catch (e) { }
                };
                Youtube.prototype.handleIframeApiReady = function () {
                    Youtube.hasScriptReady = true;
                    window['youtubeInit' + this._index].call();
                };
                Youtube.prototype.checkAPI = function () {
                    if (window.YT && window.YT.Player) {
                        Youtube.hasScriptReady = true;
                        Youtube.hasAPIDefined = true;
                        clearInterval(this._checkerInterval);
                        window['youtubeInit' + this._index].call();
                    }
                };
                Youtube.prototype.handleApiReady = function (event) {
                    console.info('YOUTUBE: Ready', this._settings.id);
                    this.dispatch({ type: Youtube.READY });
                };
                Youtube.prototype.handleApiError = function (event) {
                    console.info('YOUTUBE: Error', this._settings.id);
                    this.dispatch({ type: Youtube.ERROR });
                };
                Youtube.prototype.handleApiStateChange = function (event) {
                    if (event.data === YT.PlayerState.BUFFERING) {
                        this._isPlaying = false;
                    }
                    if (event.data === YT.PlayerState.PLAYING) {
                        this._isPlaying = true;
                        this._progressTimer = setTimeout(this.onProgress, Youtube.PROGRESS_DELAY);
                        this.onCheckSeek();
                    }
                    if (event.data === YT.PlayerState.ENDED || event.data === YT.PlayerState.PAUSED) {
                        this._isPlaying = false;
                        clearTimeout(this._progressTimer);
                    }
                    console.info('YOUTUBE: State Change', event.data);
                    this.dispatch({ type: Youtube.STATE_CHANGE, data: event.data });
                };
                Youtube.prototype.onProgress = function () {
                    if (this._player && this._player.getPlayerState() === YT.PlayerState.PLAYING) {
                        this.track();
                        this.dispatch({ type: Youtube.PROGRESS, currentTime: this._player.getCurrentTime() });
                        this._progressTimer = setTimeout(this.onProgress, Youtube.PROGRESS_DELAY);
                    }
                };
                Youtube.prototype.track = function () {
                    var time = this._player.getCurrentTime();
                    var duration = this._player.getDuration();
                    var second = Math.floor(time);
                    var percent = Math.floor(time / duration * 100);
                    var quarter = Math.floor(time / duration * 4);
                    var percentRange = quarter * 25;
                    if (isFinite(percentRange) && percentRange >= 0 && percentRange <= 100 && percentRange !== this._lastPercentRange) {
                        this._lastPercentRange = percentRange;
                        this.dispatch({ type: Youtube.PROGRESS_QUARTER, percentRange: percentRange, hasSeek: this._hasSeek });
                    }
                    for (var i = 0; i < this._settings.trackSeconds.length; i++) {
                        if (second >= this._settings.trackSeconds[i] && !this._tracked['second_' + i]) {
                            this._tracked['second_' + i] = true;
                            this.dispatch({ type: Youtube.PROGRESS_TRACK, second: this._settings.trackSeconds[i], hasSeek: this._hasSeek });
                        }
                    }
                    for (var i = 0; i < this._settings.trackPercents.length; i++) {
                        if (percent >= this._settings.trackPercents[i] && !this._tracked['percent_' + i]) {
                            this._tracked['percent_' + i] = true;
                            this.dispatch({ type: Youtube.PROGRESS_TRACK, percent: this._settings.trackPercents[i], hasSeek: this._hasSeek });
                        }
                    }
                };
                Youtube.prototype.onCheckSeek = function () {
                    if (!this._isPlaying) {
                        this._prevCurrentTime = -1;
                        return;
                    }
                    var currentTime = this._player.getCurrentTime();
                    if (this._prevCurrentTime > 0) {
                        var diff = (currentTime - this._prevCurrentTime) * 1000;
                        if (Math.abs(diff - Youtube.SEEK_PERID) > Youtube.SEEK_MARGIN) {
                            this._hasSeek = true;
                            this.dispatch({ type: Youtube.SEEK, currentTime: currentTime });
                        }
                    }
                    this._prevCurrentTime = currentTime;
                    setTimeout(this.onCheckSeek, Youtube.SEEK_PERID);
                };
                Youtube.prototype.destroy = function () {
                    if (this._settings.playerSettings.enablejsapi) {
                        this._player.destroy();
                    }
                    else {
                        this._player.parentNode.removeChild(this._player);
                    }
                };
                Object.defineProperty(Youtube.prototype, "player", {
                    get: function () { return this._player; },
                    enumerable: true,
                    configurable: true
                });
                Object.defineProperty(Youtube.prototype, "isPlaying", {
                    get: function () { return this._isPlaying; },
                    enumerable: true,
                    configurable: true
                });
                Object.defineProperty(Youtube.prototype, "id", {
                    get: function () {
                        return this._settings.id;
                    },
                    set: function (id) {
                        this._settings.id = id;
                        this.createPlayer();
                    },
                    enumerable: true,
                    configurable: true
                });
                Object.defineProperty(Youtube.prototype, "src", {
                    set: function (url) {
                        this._settings.id = Youtube.getIdFromUrl(url);
                        this.createPlayer();
                    },
                    enumerable: true,
                    configurable: true
                });
                Youtube.getIdFromUrl = function (url) {
                    var regExp = /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|\&v=)([^#\&\?]*).*/, match = url.match(regExp);
                    return match && match[2].length == 11 ? match[2] : null;
                };
                Youtube.READY = "onready";
                Youtube.ERROR = "onerror";
                Youtube.STATE_CHANGE = "onstatechange";
                Youtube.PROGRESS = "onProgress";
                Youtube.PROGRESS_QUARTER = "onprogressQuarter";
                Youtube.PROGRESS_TRACK = "onProgressTrack";
                Youtube.SEEK = "onSeek";
                Youtube.PROGRESS_DELAY = 500;
                Youtube.SEEK_PERID = 500;
                Youtube.SEEK_MARGIN = 500;
                Youtube.playerCount = 0;
                Youtube.playerStack = [];
                Youtube.hasScriptDOM = false;
                Youtube.hasScriptReady = false;
                Youtube.hasAPIDefined = false;
                __decorate([
                    Autobind_12.autobind,
                    __metadata("design:type", Function),
                    __metadata("design:paramtypes", [Object]),
                    __metadata("design:returntype", void 0)
                ], Youtube.prototype, "handleApi", null);
                __decorate([
                    Autobind_12.autobind,
                    __metadata("design:type", Function),
                    __metadata("design:paramtypes", []),
                    __metadata("design:returntype", void 0)
                ], Youtube.prototype, "handleIframeApiReady", null);
                __decorate([
                    Autobind_12.autobind,
                    __metadata("design:type", Function),
                    __metadata("design:paramtypes", []),
                    __metadata("design:returntype", void 0)
                ], Youtube.prototype, "checkAPI", null);
                __decorate([
                    Autobind_12.autobind,
                    __metadata("design:type", Function),
                    __metadata("design:paramtypes", [Object]),
                    __metadata("design:returntype", void 0)
                ], Youtube.prototype, "handleApiReady", null);
                __decorate([
                    Autobind_12.autobind,
                    __metadata("design:type", Function),
                    __metadata("design:paramtypes", [Object]),
                    __metadata("design:returntype", void 0)
                ], Youtube.prototype, "handleApiError", null);
                __decorate([
                    Autobind_12.autobind,
                    __metadata("design:type", Function),
                    __metadata("design:paramtypes", [Object]),
                    __metadata("design:returntype", void 0)
                ], Youtube.prototype, "handleApiStateChange", null);
                __decorate([
                    Autobind_12.autobind,
                    __metadata("design:type", Function),
                    __metadata("design:paramtypes", []),
                    __metadata("design:returntype", void 0)
                ], Youtube.prototype, "onProgress", null);
                __decorate([
                    Autobind_12.autobind,
                    __metadata("design:type", Function),
                    __metadata("design:paramtypes", []),
                    __metadata("design:returntype", void 0)
                ], Youtube.prototype, "onCheckSeek", null);
                return Youtube;
            }(AbstractDispatcher_15.default));
            exports_48("default", Youtube);
            YoutubeObject = (function () {
                function YoutubeObject() {
                    this._width = 560;
                    this._height = 315;
                    this._isResponsive = true;
                    this._aspectRatio = '16:9';
                    this._namespace = 'player_';
                    this._trackSeconds = [30];
                    this._trackPercents = [10, 25, 50, 75, 90];
                    this._iframeSettings = {
                        frameBorder: 0,
                        allowFullScreen: ''
                    };
                    this._playerSettings = {
                        autoplay: 1,
                        controls: 1,
                        showinfo: 1,
                        autohide: 1,
                        rel: 0,
                        enablejsapi: 1
                    };
                }
                Object.defineProperty(YoutubeObject.prototype, "id", {
                    get: function () { return this._id; },
                    set: function (value) { this._id = value; },
                    enumerable: true,
                    configurable: true
                });
                Object.defineProperty(YoutubeObject.prototype, "target", {
                    get: function () { return this._target; },
                    set: function (value) { this._target = value; },
                    enumerable: true,
                    configurable: true
                });
                Object.defineProperty(YoutubeObject.prototype, "width", {
                    get: function () { return this._width; },
                    set: function (value) { this._width = value; },
                    enumerable: true,
                    configurable: true
                });
                Object.defineProperty(YoutubeObject.prototype, "height", {
                    get: function () { return this._height; },
                    set: function (value) { this._height = value; },
                    enumerable: true,
                    configurable: true
                });
                Object.defineProperty(YoutubeObject.prototype, "responsive", {
                    get: function () { return this._isResponsive; },
                    set: function (value) { this._isResponsive = value; },
                    enumerable: true,
                    configurable: true
                });
                Object.defineProperty(YoutubeObject.prototype, "aspectRatio", {
                    get: function () { return this._aspectRatio; },
                    set: function (value) { this._aspectRatio = value; },
                    enumerable: true,
                    configurable: true
                });
                Object.defineProperty(YoutubeObject.prototype, "namespace", {
                    get: function () { return this._namespace; },
                    set: function (value) { this._namespace = value; },
                    enumerable: true,
                    configurable: true
                });
                Object.defineProperty(YoutubeObject.prototype, "iframeSettings", {
                    get: function () { return this._iframeSettings; },
                    set: function (value) { this._iframeSettings = value; },
                    enumerable: true,
                    configurable: true
                });
                Object.defineProperty(YoutubeObject.prototype, "trackSeconds", {
                    get: function () { return this._trackSeconds; },
                    set: function (value) { this._trackSeconds = value; },
                    enumerable: true,
                    configurable: true
                });
                Object.defineProperty(YoutubeObject.prototype, "trackPercents", {
                    get: function () { return this._trackPercents; },
                    set: function (value) { this._trackPercents = value; },
                    enumerable: true,
                    configurable: true
                });
                Object.defineProperty(YoutubeObject.prototype, "playerSettings", {
                    get: function () { return this._playerSettings; },
                    set: function (value) { this._playerSettings = value; },
                    enumerable: true,
                    configurable: true
                });
                return YoutubeObject;
            }());
            exports_48("YoutubeObject", YoutubeObject);
        }
    };
});
System.register("src/ts/vendors/frontools/src/medias/video/YoutubePlaylist", ["src/ts/vendors/frontools/src/data/Request", "src/ts/vendors/frontools/src/abstract/AbstractDispatcher", "src/ts/vendors/frontools/src/data/Template"], function (exports_49, context_49) {
    "use strict";
    var Request_1, Request_2, AbstractDispatcher_16, Template_1, YoutubePlaylist, YoutubePlaylistEvent;
    var __moduleName = context_49 && context_49.id;
    return {
        setters: [
            function (Request_1_1) {
                Request_1 = Request_1_1;
                Request_2 = Request_1_1;
            },
            function (AbstractDispatcher_16_1) {
                AbstractDispatcher_16 = AbstractDispatcher_16_1;
            },
            function (Template_1_1) {
                Template_1 = Template_1_1;
            }
        ],
        execute: function () {
            YoutubePlaylist = (function (_super) {
                __extends(YoutubePlaylist, _super);
                function YoutubePlaylist(apikey, playlistid) {
                    var _this = _super.call(this) || this;
                    _this._apikey = apikey;
                    _this._playlistid = playlistid;
                    return _this;
                }
                YoutubePlaylist.prototype.parseDataToBasicHtml = function (data) {
                    var items = data.items;
                    var html = '';
                    for (var i in items) {
                        var item = items[i].snippet;
                        if (item.thumbnails) {
                            html += new Template_1.default(YoutubePlaylist.BASIC_HTML_TEMPLATE, {
                                videoId: item.resourceId.videoId,
                                thumbnails: item.thumbnails.default.url,
                                title: item.title
                            }).htmlAsString;
                        }
                    }
                    return html;
                };
                YoutubePlaylist.prototype.api = function (apiurl, type) {
                    var _this = this;
                    var config = new Request_1.RequestConfig();
                    config.url = apiurl + '&key=' + this._apikey;
                    var request = new Request_2.default(config);
                    request.addListener(Request_1.RequestEvent.LOAD, function (e) {
                        var response = JSON.parse(e.response);
                        if (response.error) {
                            _this.dispatch({
                                type: YoutubePlaylistEvent.PLAYLIST_ERROR,
                                error: response
                            });
                        }
                        else {
                            if (type == YoutubePlaylistEvent.PLAYLIST_HTML) {
                                var html = _this.parseDataToBasicHtml(response);
                                _this.dispatch({
                                    type: type,
                                    response: response,
                                    html: html
                                });
                            }
                            else {
                                _this.dispatch({
                                    type: type,
                                    response: response
                                });
                            }
                        }
                    });
                    request.addListener(Request_1.RequestEvent.ERROR, function (e) {
                        _this.dispatch({ type: YoutubePlaylistEvent.PLAYLIST_ERROR });
                    });
                };
                YoutubePlaylist.prototype.getPlaylistInfo = function () {
                    this.api('https://www.googleapis.com/youtube/v3/playlists?part=snippet&id=' + this._playlistid, YoutubePlaylistEvent.PLAYLIST_INFO);
                };
                YoutubePlaylist.prototype.getPlaylistData = function (max) {
                    if (max === void 0) { max = 50; }
                    this.api('https://www.googleapis.com/youtube/v3/playlistItems?part=snippet&maxResults=' + max + '&playlistId=' + this._playlistid, YoutubePlaylistEvent.PLAYLIST_DATA);
                };
                YoutubePlaylist.prototype.getPlaylistHtml = function (max) {
                    if (max === void 0) { max = 50; }
                    this.api('https://www.googleapis.com/youtube/v3/playlistItems?part=snippet&maxResults=' + max + '&playlistId=' + this._playlistid, YoutubePlaylistEvent.PLAYLIST_HTML);
                };
                YoutubePlaylist.BASIC_HTML_TEMPLATE = "\n        <div data-id=\"<% videoId %>\">\n            <h2><% title %></h2>\n            <img src=\"<% thumbnails %>\">\n        </div>\n    ";
                return YoutubePlaylist;
            }(AbstractDispatcher_16.default));
            exports_49("default", YoutubePlaylist);
            YoutubePlaylistEvent = (function () {
                function YoutubePlaylistEvent() {
                }
                YoutubePlaylistEvent.PLAYLIST_INFO = 'playlistinfo';
                YoutubePlaylistEvent.PLAYLIST_DATA = 'playlistdata';
                YoutubePlaylistEvent.PLAYLIST_HTML = 'playlisthtml';
                YoutubePlaylistEvent.PLAYLIST_ERROR = 'playlisterror';
                return YoutubePlaylistEvent;
            }());
            exports_49("YoutubePlaylistEvent", YoutubePlaylistEvent);
        }
    };
});
System.register("src/ts/vendors/frontools/src/social/Disqus", [], function (exports_50, context_50) {
    "use strict";
    var Disqus;
    var __moduleName = context_50 && context_50.id;
    return {
        setters: [],
        execute: function () {
            Disqus = (function () {
                function Disqus(obj) {
                    if (Disqus._instance)
                        throw new Error('Error: Use Disqus.getInstance() instead of new.');
                    Disqus._instance = this;
                    if (obj.shortname)
                        window.disqus_shortname = obj.shortname;
                    if (obj.identifier)
                        window.disqus_identifier = obj.identifier;
                    if (obj.title)
                        window.disqus_title = obj.title;
                    if (obj.url)
                        window.disqus_url = obj.url;
                    if (obj.category_id)
                        window.disqus_category_id = obj.category_id;
                    if (obj.disable_mobile)
                        window.disqus_disable_mobile = obj.disable_mobile;
                    if (obj.config)
                        window.disqus_config = obj.config;
                    var dsq = document.createElement('script');
                    dsq.type = 'text/javascript';
                    dsq.async = true;
                    dsq.src = '//' + obj.shortname + '.disqus.com/embed.js';
                    (document.getElementsByTagName('head')[0] || document.getElementsByTagName('body')[0]).appendChild(dsq);
                    if (window.addEventListener) {
                        window.addEventListener('orientationchange', function () {
                            if (typeof (window.DISQUS) !== 'undefined') {
                                window.DISQUS.reset({
                                    reload: true
                                });
                            }
                        });
                    }
                    console.info('DISQUS:', obj);
                }
                Disqus.getInstance = function () {
                    return Disqus._instance;
                };
                return Disqus;
            }());
            exports_50("default", Disqus);
        }
    };
});
System.register("src/ts/vendors/frontools/src/social/Facebook", ["src/ts/vendors/frontools/src/utils/UrlUtils", "src/ts/vendors/frontools/src/utils/DocumentUtils"], function (exports_51, context_51) {
    "use strict";
    var UrlUtils_4, DocumentUtils_3, Facebook;
    var __moduleName = context_51 && context_51.id;
    return {
        setters: [
            function (UrlUtils_4_1) {
                UrlUtils_4 = UrlUtils_4_1;
            },
            function (DocumentUtils_3_1) {
                DocumentUtils_3 = DocumentUtils_3_1;
            }
        ],
        execute: function () {
            Facebook = (function () {
                function Facebook(option, callbacks) {
                    this.isReady = false;
                    if (Facebook._instance)
                        throw new Error('Error: Use Facebook.getInstance() instead of new.');
                    Facebook._instance = this;
                    this._appid = option.appId;
                    window.fbAsyncInit = function () {
                        FB.init(option);
                        console.info('FACEBOOK: ', this._appid);
                        if (callbacks) {
                            if (typeof (callbacks.ready) === 'function') {
                                this.isReady = true;
                                callbacks.ready();
                            }
                            if (typeof (callbacks.like) === 'function')
                                FB.Event.subscribe('edge.create', callbacks.like);
                            if (typeof (callbacks.removeLike) === 'function')
                                FB.Event.subscribe('edge.remove', callbacks.removeLike);
                            if (typeof (callbacks.comment) === 'function')
                                FB.Event.subscribe('comment.create', callbacks.comment);
                            if (typeof (callbacks.removeComment) === 'function')
                                FB.Event.subscribe('comment.remove', callbacks.removeComment);
                            if (typeof (callbacks.share) === 'function')
                                FB.Event.subscribe('message.send', callbacks.share);
                            if (typeof (callbacks.xfbmlRender) === 'function')
                                FB.Event.subscribe('xfbml.render', callbacks.xfbmlRender);
                        }
                    }.bind(this);
                    (function (d, s, id) { var js, fjs = d.getElementsByTagName(s)[0]; if (d.getElementById(id))
                        return; js = d.createElement(s); js.id = id; js.src = "//connect.facebook.net/" + Facebook.LANGUE + "/sdk.js"; fjs.parentNode.insertBefore(js, fjs); }(document, 'script', 'facebook-jssdk'));
                }
                Facebook.sharer = function (url, popup, width, height) {
                    if (popup === void 0) { popup = true; }
                    if (width === void 0) { width = 600; }
                    if (height === void 0) { height = 400; }
                    url = UrlUtils_4.default.query(url, {
                        utm_source: 'facebook',
                        utm_medium: 'social'
                    });
                    var link = 'http://www.facebook.com/sharer/sharer.php?u=' + encodeURIComponent(url);
                    if (popup === true)
                        DocumentUtils_3.default.openPopup(link, width, height);
                    else
                        window.open(link, '_blank');
                };
                ;
                Facebook.getInstance = function () {
                    return Facebook._instance;
                };
                Facebook.LANGUE = 'fr_CA';
                return Facebook;
            }());
            exports_51("default", Facebook);
        }
    };
});
System.register("src/ts/vendors/frontools/src/social/GoogleConnect", [], function (exports_52, context_52) {
    "use strict";
    var GoogleConnect;
    var __moduleName = context_52 && context_52.id;
    return {
        setters: [],
        execute: function () {
            GoogleConnect = (function () {
                function GoogleConnect(option, callbacks) {
                    if (GoogleConnect._instance)
                        throw new Error('Error: Use GoogleConnect.getInstance() instead of new.');
                    GoogleConnect._instance = this;
                    this._appid = option.appId;
                    this._button = option.button;
                    if (callbacks) {
                        if (typeof (callbacks.onConnected) === 'function')
                            this._onConnected = callbacks.onConnected;
                        if (typeof (callbacks.onError) === 'function')
                            this._onError = callbacks.onError;
                    }
                    window.googleAsyncInit = function () {
                        console.info('GOOGLE CONNECT: ', this._appid);
                        gapi.load('auth2', function () {
                            this._auth2 = gapi.auth2.init({
                                client_id: this._appid,
                                cookiepolicy: 'single_host_origin',
                            });
                            if (this._button.length) {
                                for (var buttonId in this._button) {
                                    this._attachSignInGoogle(this._button[buttonId]);
                                }
                            }
                            else
                                this._attachSignInGoogle(this._button);
                        }.bind(this));
                    }.bind(this);
                    (function () { var po = document.createElement('script'); po.type = 'text/javascript'; po.async = true; po.src = '//apis.google.com/js/api:client.js?onload=googleAsyncInit'; var s = document.getElementsByTagName('script')[0]; s.parentNode.insertBefore(po, s); })();
                }
                GoogleConnect.prototype._attachSignInGoogle = function (element) {
                    this._auth2.attachClickHandler(element, {}, function (googleUser) {
                        if (this._onConnected)
                            this._onConnected(googleUser);
                    }.bind(this), function (error) {
                        if (this._onError)
                            this._onError(error);
                    }.bind(this));
                };
                GoogleConnect.getInstance = function () {
                    return GoogleConnect._instance;
                };
                return GoogleConnect;
            }());
            exports_52("default", GoogleConnect);
        }
    };
});
System.register("src/ts/vendors/frontools/src/social/GooglePlus", ["src/ts/vendors/frontools/src/utils/DocumentUtils", "src/ts/vendors/frontools/src/utils/UrlUtils"], function (exports_53, context_53) {
    "use strict";
    var DocumentUtils_4, UrlUtils_5, GooglePlus;
    var __moduleName = context_53 && context_53.id;
    return {
        setters: [
            function (DocumentUtils_4_1) {
                DocumentUtils_4 = DocumentUtils_4_1;
            },
            function (UrlUtils_5_1) {
                UrlUtils_5 = UrlUtils_5_1;
            }
        ],
        execute: function () {
            GooglePlus = (function () {
                function GooglePlus() {
                    if (GooglePlus._instance)
                        throw new Error('Error: Use GooglePlus.getInstance() instead of new.');
                    GooglePlus._instance = this;
                    window.___gcfg = { lang: GooglePlus.LANGUE };
                    (function () { var po = document.createElement('script'); po.type = 'text/javascript'; po.async = true; po.src = '//apis.google.com/js/platform.js'; var s = document.getElementsByTagName('script')[0]; s.parentNode.insertBefore(po, s); })();
                }
                GooglePlus.sharer = function (url, popup, width, height) {
                    if (popup === void 0) { popup = true; }
                    if (width === void 0) { width = 600; }
                    if (height === void 0) { height = 400; }
                    url = UrlUtils_5.default.query(url, {
                        utm_source: 'google',
                        utm_medium: 'social'
                    });
                    var link = 'https://plus.google.com/share?url=' + encodeURIComponent(url);
                    if (popup === true)
                        DocumentUtils_4.default.openPopup(link, width, height);
                    else
                        window.open(link, '_blank');
                };
                ;
                GooglePlus.getInstance = function () {
                    return GooglePlus._instance;
                };
                GooglePlus.LANGUE = 'fr-CA';
                return GooglePlus;
            }());
            exports_53("default", GooglePlus);
        }
    };
});
System.register("src/ts/vendors/frontools/src/social/LinkedIn", ["src/ts/vendors/frontools/src/utils/DocumentUtils", "src/ts/vendors/frontools/src/utils/UrlUtils"], function (exports_54, context_54) {
    "use strict";
    var DocumentUtils_5, UrlUtils_6, LinkedIn;
    var __moduleName = context_54 && context_54.id;
    return {
        setters: [
            function (DocumentUtils_5_1) {
                DocumentUtils_5 = DocumentUtils_5_1;
            },
            function (UrlUtils_6_1) {
                UrlUtils_6 = UrlUtils_6_1;
            }
        ],
        execute: function () {
            LinkedIn = (function () {
                function LinkedIn() {
                    if (LinkedIn._instance)
                        throw new Error('Error: Use LinkedIn.getInstance() instead of new.');
                    LinkedIn._instance = this;
                }
                LinkedIn.sharer = function (options, popup, width, height) {
                    if (popup === void 0) { popup = true; }
                    if (width === void 0) { width = 600; }
                    if (height === void 0) { height = 400; }
                    if (options.url) {
                        options.url = UrlUtils_6.default.query(options.url, {
                            utm_source: 'linkedin',
                            utm_medium: 'social'
                        });
                    }
                    else
                        throw new Error('Url is not defined');
                    var link = UrlUtils_6.default.query('http://www.linkedin.com/shareArticle', options);
                    if (popup === true)
                        DocumentUtils_5.default.openPopup(link, width, height);
                    else
                        window.open(link, '_blank');
                };
                ;
                LinkedIn.getInstance = function () {
                    return LinkedIn._instance;
                };
                return LinkedIn;
            }());
            exports_54("default", LinkedIn);
        }
    };
});
System.register("src/ts/vendors/frontools/src/social/Pinterest", [], function (exports_55, context_55) {
    "use strict";
    var Pinterest;
    var __moduleName = context_55 && context_55.id;
    return {
        setters: [],
        execute: function () {
            Pinterest = (function () {
                function Pinterest(parseName) {
                    if (parseName === void 0) { parseName = 'parsePinBtns'; }
                    if (Pinterest._instance)
                        throw new Error('Error: Use Pinterest.getInstance() instead of new.');
                    Pinterest._instance = this;
                    (function () { var po = document.createElement('script'); po['data-pin-build'] = parseName; po.src = '//assets.pinterest.com/js/pinit.js'; var s = document.getElementsByTagName('script')[0]; s.parentNode.insertBefore(po, s); })();
                }
                Pinterest.parse = function (func) {
                    if (func === void 0) { func = 'parsePinBtns'; }
                    if (window[func])
                        window[func]();
                };
                ;
                Pinterest.getInstance = function () {
                    return Pinterest._instance;
                };
                return Pinterest;
            }());
            exports_55("default", Pinterest);
        }
    };
});
System.register("src/ts/vendors/frontools/src/social/Twitter", ["src/ts/vendors/frontools/src/utils/DocumentUtils", "src/ts/vendors/frontools/src/utils/UrlUtils"], function (exports_56, context_56) {
    "use strict";
    var DocumentUtils_6, UrlUtils_7, Twitter;
    var __moduleName = context_56 && context_56.id;
    return {
        setters: [
            function (DocumentUtils_6_1) {
                DocumentUtils_6 = DocumentUtils_6_1;
            },
            function (UrlUtils_7_1) {
                UrlUtils_7 = UrlUtils_7_1;
            }
        ],
        execute: function () {
            Twitter = (function () {
                function Twitter(callbacks) {
                    if (callbacks === void 0) { callbacks = {}; }
                    if (Twitter._instance)
                        throw new Error('Error: Use Twitter.getInstance() instead of new.');
                    Twitter._instance = this;
                    window.twttr = (function (d, s, id) { var t, js, fjs = d.getElementsByTagName(s)[0]; if (d.getElementById(id))
                        return; js = d.createElement(s); js.id = id; js.src = "//platform.twitter.com/widgets.js"; fjs.parentNode.insertBefore(js, fjs); return window.twttr || (t = { _e: [], ready: function (f) { t._e.push(f); } }); }(document, "script", "twitter-wjs"));
                    window.twttr.ready(function (twttr) {
                        if (typeof (callbacks.ready) === 'function')
                            callbacks.ready();
                        if (typeof (callbacks.click) === 'function')
                            window.twttr.events.bind('click', callbacks.click);
                        if (typeof (callbacks.tweet) === 'function')
                            window.twttr.events.bind('tweet', callbacks.tweet);
                        if (typeof (callbacks.retweet) === 'function')
                            window.twttr.events.bind('retweet', callbacks.retweet);
                        if (typeof (callbacks.favorite) === 'function')
                            window.twttr.events.bind('favorite', callbacks.favorite);
                        if (typeof (callbacks.follow) === 'function')
                            window.twttr.events.bind('follow', callbacks.follow);
                    });
                }
                Twitter.parse = function () {
                    if (window.twttr)
                        window.twttr.widgets.load();
                    else
                        throw new Error('window.twttr is not defined. new Twitter() must be called.');
                };
                ;
                Twitter.open = function (url, options, popup, width, height) {
                    if (options === void 0) { options = {}; }
                    if (popup === void 0) { popup = true; }
                    if (width === void 0) { width = 600; }
                    if (height === void 0) { height = 400; }
                    url = UrlUtils_7.default.query(url, options);
                    if (popup === true)
                        DocumentUtils_6.default.openPopup(url, width, height);
                    else
                        window.open(url, '_blank');
                };
                ;
                Twitter.tweet = function (options, popup, width, height) {
                    if (options === void 0) { options = {}; }
                    if (popup === void 0) { popup = true; }
                    if (width === void 0) { width = 600; }
                    if (height === void 0) { height = 400; }
                    if (options.url) {
                        options.url = UrlUtils_7.default.query(options.url, {
                            utm_source: 'twitter',
                            utm_medium: 'social'
                        });
                    }
                    Twitter.open('https://twitter.com/intent/tweet', options, popup, width, height);
                };
                ;
                Twitter.retweet = function (options, popup, width, height) {
                    if (options === void 0) { options = {}; }
                    if (popup === void 0) { popup = true; }
                    if (width === void 0) { width = 600; }
                    if (height === void 0) { height = 400; }
                    if (!options.tweet_id)
                        throw new Error('tweet_id is not defined');
                    Twitter.open('https://twitter.com/intent/retweet', options, popup, width, height);
                };
                ;
                Twitter.favorite = function (options, popup, width, height) {
                    if (options === void 0) { options = {}; }
                    if (popup === void 0) { popup = true; }
                    if (width === void 0) { width = 600; }
                    if (height === void 0) { height = 400; }
                    if (!options.tweet_id)
                        throw new Error('tweet_id is not defined');
                    Twitter.open('https://twitter.com/intent/favorite', options, popup, width, height);
                };
                ;
                Twitter.user = function (options, popup, width, height) {
                    if (options === void 0) { options = {}; }
                    if (popup === void 0) { popup = true; }
                    if (width === void 0) { width = 600; }
                    if (height === void 0) { height = 400; }
                    if (!options.screen_name && !options.user_id)
                        throw new Error('screen_name and user_id are not defined');
                    Twitter.open('https://twitter.com/intent/user', options, popup, width, height);
                };
                ;
                Twitter.parseURL = function (str) {
                    return str.replace(/[A-Za-z]+:\/\/[A-Za-z0-9-​_]+\.[A-Za-z0-9-_​:%&~\?\/.=]+/g, function (url) {
                        return '<a href="' + url + '" target="_blank">' + url + '</a>';
                    });
                };
                ;
                Twitter.parseUsername = function (str) {
                    return str.replace(/[@]+[A-Za-z0-9-_öäüÖÄÜáàâéèêúùûóòôÁÀÂÉÈÊÚÙÛÓÒÔ]+/g, function (u) {
                        var username = u.replace('@', '');
                        return '<a href="https://twitter.com/' + encodeURIComponent(username) + '" target="_blank">' + u + '</a>';
                    });
                };
                ;
                Twitter.parseHashtag = function (str) {
                    return str.replace(/[#]+[A-Za-z0-9-_öäüÖÄÜáàâéèêúùûóòôÁÀÂÉÈÊÚÙÛÓÒÔ]+/g, function (t) {
                        return '<a href="https://twitter.com/search?q=' + encodeURIComponent(t) + '" target="_blank">' + t + '</a>';
                    });
                };
                ;
                Twitter.getInstance = function () {
                    return Twitter._instance;
                };
                return Twitter;
            }());
            exports_56("default", Twitter);
        }
    };
});
System.register("src/ts/vendors/frontools/src/utils/ColorUtils", [], function (exports_57, context_57) {
    "use strict";
    var ColorUtils;
    var __moduleName = context_57 && context_57.id;
    return {
        setters: [],
        execute: function () {
            ColorUtils = (function () {
                function ColorUtils() {
                }
                ColorUtils.rgb2hex = function (color) {
                    var rgb = color.match(/^rgba?[\s+]?\([\s+]?(\d+)[\s+]?,[\s+]?(\d+)[\s+]?,[\s+]?(\d+)[\s+]?/i);
                    return (rgb && rgb.length === 4) ? '0x' +
                        ("0" + parseInt(rgb[1], 10).toString(16)).slice(-2) +
                        ("0" + parseInt(rgb[2], 10).toString(16)).slice(-2) +
                        ("0" + parseInt(rgb[3], 10).toString(16)).slice(-2) : '';
                };
                ColorUtils.hex2rgb = function (hex) {
                    var result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
                    return result ? {
                        r: parseInt(result[1], 16),
                        g: parseInt(result[2], 16),
                        b: parseInt(result[3], 16)
                    } : null;
                };
                ColorUtils.getBrightness = function (rgb) {
                    return (rgb['r'] * 299 + rgb['g'] * 587 + rgb['b'] * 114) / 1000;
                };
                ColorUtils.isDark = function (rgb) {
                    return ColorUtils.getBrightness(rgb) < 128;
                };
                ColorUtils.isLight = function (rgb) {
                    return !ColorUtils.isDark(rgb);
                };
                return ColorUtils;
            }());
            exports_57("default", ColorUtils);
        }
    };
});
System.register("src/ts/vendors/frontools/src/utils/DisplayUtils", [], function (exports_58, context_58) {
    "use strict";
    var DisplayUtils;
    var __moduleName = context_58 && context_58.id;
    return {
        setters: [],
        execute: function () {
            DisplayUtils = (function () {
                function DisplayUtils() {
                }
                DisplayUtils.fitIntoRect = function (targetSize, rectSize, alignType, fillRect) {
                    if (alignType === void 0) { alignType = DisplayUtils.CENTER; }
                    if (fillRect === void 0) { fillRect = false; }
                    var matrix = {};
                    var width = rectSize.width;
                    var height = rectSize.width * targetSize.height / targetSize.width;
                    if (height < rectSize.height && fillRect) {
                        height = rectSize.height;
                        width = rectSize.height * targetSize.width / targetSize.height;
                    }
                    var x = 0;
                    var y = 0;
                    switch (alignType) {
                        case DisplayUtils.LEFT:
                        case DisplayUtils.TOP_LEFT:
                        case DisplayUtils.BOTTOM_LEFT:
                            x = 0;
                            break;
                        case DisplayUtils.RIGHT:
                        case DisplayUtils.TOP_RIGHT:
                        case DisplayUtils.BOTTOM_RIGHT:
                            x = rectSize.width - width;
                            break;
                        default:
                            x = (rectSize.width - width) / 2;
                    }
                    switch (alignType) {
                        case DisplayUtils.TOP:
                        case DisplayUtils.TOP_LEFT:
                        case DisplayUtils.TOP_RIGHT:
                            y = 0;
                            break;
                        case DisplayUtils.BOTTOM:
                        case DisplayUtils.BOTTOM_LEFT:
                        case DisplayUtils.BOTTOM_RIGHT:
                            y = rectSize.height - height;
                            break;
                        default:
                            y = (rectSize.height - height) / 2;
                    }
                    matrix.width = width;
                    matrix.height = height;
                    matrix.x = x;
                    matrix.y = y;
                    matrix.scale = width / targetSize.width;
                    return matrix;
                };
                DisplayUtils.setEqualHeight = function (elements, rowLength) {
                    var total = elements.length, elementsArr = Array.prototype.slice.call(elements), maxHeight = 0, rowItems = [], n = 0;
                    rowLength = total < rowLength ? total : rowLength;
                    for (var i_3 = 0, l_2 = total; i_3 < l_2; i_3++) {
                        var ele = elements[i_3];
                        ele.style.height = '';
                        maxHeight = Math.max(ele.offsetHeight, maxHeight);
                        rowItems.push(ele);
                        if (rowLength) {
                            if (n < rowLength - 1 && i_3 < (total - 1)) {
                                n++;
                            }
                            else {
                                for (var j = 0, l_3 = rowItems.length; j < l_3; j++) {
                                    rowItems[j].style.height = maxHeight + 'px';
                                }
                                n = maxHeight = 0;
                                rowItems = [];
                            }
                        }
                    }
                    if (!rowLength) {
                        for (var i = 0, l = total; i < l; i++) {
                            var ele_1 = elements[i];
                            ele_1.style.height = maxHeight + 'px';
                        }
                    }
                };
                ;
                DisplayUtils.LEFT = 'L';
                DisplayUtils.RIGHT = 'R';
                DisplayUtils.TOP = 'T';
                DisplayUtils.BOTTOM = 'B';
                DisplayUtils.TOP_LEFT = 'TL';
                DisplayUtils.BOTTOM_LEFT = 'BL';
                DisplayUtils.TOP_RIGHT = 'TR';
                DisplayUtils.BOTTOM_RIGHT = 'BR';
                DisplayUtils.CENTER = 'C';
                return DisplayUtils;
            }());
            exports_58("default", DisplayUtils);
        }
    };
});
System.config({
    map: {
        app: 'src/ts/Main'
    }
});
